import React from 'react'
import { AiOutlineCloudUpload,AiOutlineLoading3Quarters, AiOutlineSend } from 'react-icons/ai'
import { HiOutlineBriefcase,HiOutlinePencil,HiOutlineUserCircle,HiOutlineUserGroup,HiPlus } from 'react-icons/hi'
import { HiClock,HiLockClosed,HiOutlineCalendar,HiOutlineChatBubbleBottomCenterText,HiOutlineCog6Tooth,HiTrash } from 'react-icons/hi2'
import { twMerge } from 'tailwind-merge'

const icons = {
    Teamspace: HiOutlineBriefcase,
    Chat: HiOutlineChatBubbleBottomCenterText,
    Team: HiOutlineUserGroup,
    Admin: HiOutlineCog6Tooth,
    Settings: HiOutlineCog6Tooth,
    User: HiOutlineUserCircle,
    Save: HiOutlinePencil,
    Edit: HiOutlinePencil,
    Create: HiPlus,
    Loader: AiOutlineLoading3Quarters,
    Calendar: HiOutlineCalendar,
    Upload: AiOutlineCloudUpload,
    Archive: HiTrash,
    Lock: HiLockClosed,
    Send: AiOutlineSend
}

export const Icon = ({ icon, className, size = 8 }) => {
    let RenderIcon = null
    if (icon) {
        if (icon in icons) {
            RenderIcon = icons[icon]
        } else {
            throw new Error('Torbu Engineer: The icon you are trying to load is not in the icons map in the Icon.js file')
        }
    }
    const classes = twMerge(`w-${String(size)} h-${String(size)}`, className)
    return <RenderIcon className={classes} />
}
export default Icon

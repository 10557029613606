import { Box, Image, Paper, ScrollArea, SimpleGrid, Text, Tooltip } from '@mantine/core'
import { isEmpty, sortBy } from 'lodash'
import 'moment-timezone'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Icon from '../../Helpers/Icon'
import useGQL from '../../Hooks/useGQL'

const MediaDisplay = ({ event }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

    const [files, setFiles] = useState([])

    const getAllFilesGQL = useGQL('getAllFiles')
    const getFilesKey = [getAllFilesGQL, { entityIds: [event.id] }]

    useQuery(getFilesKey, {
        onSettled: data => {
            if (data.getAllFiles?.length) {
                setFiles(sortBy(data.getAllFiles, ['name', 'createdAt']))
            } else {
                setFiles([])
            }
        },
        enabled: !isEmpty(event)
    })

    const corsUrl = 'https://cors.torbu.com/'

    return (
        <Box p={0}>
            <ScrollArea.Autosize
                mah={300}
                type={'always'}
                offsetScrollbars
            >
                {files.length ? (
                    <SimpleGrid cols={6}>
                        {files.map(file => (
                            <Tooltip
                                key={file.id}
                                label={file.name}
                                position={'bottom'}
                                withinPortal
                                withArrow
                            >
                                <Paper
                                    withBorder
                                    bg={'#F6F7F8'}
                                    radius={4}
                                    mb={8}
                                    p={5}
                                    style={{ overflow: 'hidden' }}
                                    mah={125}
                                    component={Link}
                                    to={file.url}
                                    target={'_blank'}
                                >
                                    {file.contentType.includes('image') && (
                                        <Box>
                                            <Image
                                                src={file.url}
                                                alt={file.name}
                                                radius={'md'}
                                                withPlaceholder
                                                mb={16}
                                            />
                                        </Box>
                                    )}

                                    {file.contentType.includes('pdf') && (
                                        <Box
                                            mah={90}
                                            style={{ overflow: 'hidden' }}
                                        >
                                            <Document file={corsUrl + file.url}>
                                                <Page
                                                    pageNumber={1}
                                                    height={90}
                                                />
                                            </Document>
                                        </Box>
                                    )}

                                    {!file.contentType.includes('pdf') && !file.contentType.includes('image') && (
                                        <Box
                                            mah={90}
                                            style={{ overflow: 'hidden' }}
                                            ta={'center'}
                                            py={16}
                                        >
                                            <Icon
                                                icon={'Files'}
                                                size={50}
                                            />
                                        </Box>
                                    )}

                                    <Text
                                        lineClamp={1}
                                        truncate={10}
                                        size={'xs'}
                                    >
                                        {file.name}
                                    </Text>
                                </Paper>
                            </Tooltip>
                        ))}
                    </SimpleGrid>
                ) : (
                    <Text
                        size={'md'}
                        fw={'300'}
                        align={'center'}
                        truncate={10}
                        lineClamp={1}
                    >
                        No media added for this event.
                    </Text>
                )}
            </ScrollArea.Autosize>
        </Box>
    )
}

export default MediaDisplay

import React from 'react'
import { Icon } from './Icon'

export const Header = ({ text, icon, children }) => {
    return (
        <div className="border-bottom flex border border-torbu-gray bg-white dark:border-b-2 dark:border-[#302E35] dark:bg-[#1A1D21] md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
                <div className="p-4">
                    <h2 className="flex items-center gap-2 font-torbu-heading text-2xl font-bold uppercase leading-7 text-torbu-green dark:text-torbu-peach sm:truncate sm:tracking-tight">
                        {icon && (
                            <Icon
                                icon={icon}
                                size={8}
                            />
                        )}
                        <div className="mt-1">{text}</div>
                    </h2>
                </div>
            </div>
            {children && <div className="mx-4 my-4 flex">{children}</div>}
        </div>
    )
}

import { Alert,Box,ColorSwatch,Divider,Group,MultiSelect,Text,TextInput } from '@mantine/core'
import { isNotEmpty,useForm } from '@mantine/form'
import { isEmpty } from 'lodash'
import React,{ useState } from "react"
import { useMutation } from "react-query"
import ButtonCreate from "../../Components/Buttons/ButtonCreate"
import useAccountDirectory from '../../Helpers/AccountDirectory'
import AccountsWithAvatars from "../../Helpers/AccountsWithAvatars"
import Icon from "../../Helpers/Icon"
import StrideLoader from "../../Helpers/StrideLoader"
import useGQL from "../../Hooks/useGQL"
import { useNotify } from "../../Hooks/useNotify"
import graphqlClient from "../../Utils/graphqlClient"

const EventTypeModal = ({ handleNewEventType }) => {
    const [isSaving, setIsSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [colorSwatch, setColorSwatch] = useState('')
    const accountDirectory = useAccountDirectory('event:create:v2')
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            accountIds: [],
            title: '',
        },
        validate: {
            title: isNotEmpty('Title is required'),
            accountIds: isNotEmpty('You must select at least 1 account'),
        },
    })

    const createEventTypesGQL = useGQL('createEventType')
    const createEventType = useMutation(
        (data) => graphqlClient.request(createEventTypesGQL, data),
        {
            onSuccess: (data) => {
                notify.created('Event type')
                setIsSaving(false)
                handleNewEventType(data.createEventType.id)
            },
            onError: () => {
                setIsSaving(false)
                notify.error()
            }
        }
    )

    const saveEventType = (data) => {
        if(
            !isEmpty(colorSwatch) &&
            !isEmpty(data.accountIds) &&
            !isEmpty(data.title)
        ){
            setIsSaving(true)
            data.color = colorSwatch
            createEventType.mutate(data)
        }else{
            setIsSaving(false)
            setErrorMessage('All Fields are required')
        }
    }

    const colorsAllowed = ["#339af0", "#f06595", "#cc5de8", "#51cf66", "#5c7cfa", "#ff922b", "#22b8cf", "#ff6b6b", "#845ef7", "#fcc419"]

    return (
        <>
            <Text mb={15} fw={300} size={'sm'}>
                Event Types allow you to organize your events by name and color on your calendar.
            </Text>

            {!isEmpty(errorMessage) && (
                <Alert icon={<Icon icon={'AlertCircle'} size="1rem" />} title="Form Validation" color="red" mb={12}>
                    {errorMessage}
                </Alert>
            )}

            <form>
                <StrideLoader loading={isSaving} label={'Saving Event Type...'} mb={16}>
                    <Box mb={10}>
                        <MultiSelect
                            icon={<Icon icon={'Users'} size={14} />}
                            label="What accounts can use this event type?"
                            data={accountDirectory}
                            itemComponent={AccountsWithAvatars}
                            withinPortal
                            searchable
                            withAsterisk
                            {...form.getInputProps('accountIds')}
                        />
                    </Box>
                    <Box mb={15}>
                        <TextInput
                            label="Title"
                            withAsterisk
                            {...form.getInputProps('title')}
                        />
                    </Box>

                    <Box mb={15}>
                        <Text size={"sm"}>
                            Event Color:
                        </Text>
                        <Group spacing="md" mt={10}>
                            {colorsAllowed.map(colorOption => (
                                <Box
                                    key={colorOption}
                                    onClick={() => setColorSwatch(colorOption)}
                                    className={colorOption === colorSwatch ? 'selected-color-swatch pointer' : 'pointer'}
                                >
                                    <ColorSwatch color={colorOption} />
                                </Box>
                            ))}
                        </Group>
                    </Box>

                    <Divider my="xl" variant="dotted" />
                </StrideLoader>

                <Group position={'right'}>
                    <ButtonCreate
                        saving={isSaving}
                        disabled={colorSwatch === '' || form.values.title === '' || form.values.accountIds.length === 0}
                        onClick={form.onSubmit(saveEventType)}
                    >
                        Create Event Type
                    </ButtonCreate>
                </Group>
            </form>
        </>
    )
}

export default EventTypeModal

import { Avatar, Tooltip } from 'flowbite-react'
import { isEmpty, isUndefined, startCase } from 'lodash'
import React, { useEffect, useState } from 'react'

export const TorbuAvatar = ({ user, size = 'md', account, rounded = true, ...rest }) => {
    const getInitials = name => {
        let matches = name.match(/\b(\w)/g)
        return matches ? matches.slice(0, 2).join('') : ''
    }
    const buildUser = () => {
        if (!isUndefined(user) && !isEmpty(user.firstName) && !isEmpty(user.lastName)) {
            const name = [startCase(user?.firstName?.trim()), startCase(user?.lastName?.trim())].join(' ').trim()
            return {
                name: name,
                initials: getInitials(name),
                url: !isEmpty(user.avatarFile) ? user.avatarFile.url : null,
                status: null
            }
        }
        return {}
    }
    const buildAccount = () => {
        if (!isUndefined(account) && !isEmpty(account.name)) {
            const name = startCase(account?.name?.trim()).trim()

            return {
                name: name,
                initials: getInitials(name),
                url: !isEmpty(account.avatarFile) ? account.avatarFile.url : null,
                status: null
            }
        }
        return {}
    }

    const buildAvatar = () => {
        if (!isUndefined(account)) {
            return buildAccount()
        }
        if (!isUndefined(user)) {
            return buildUser()
        }

        return {}
    }

    const [avatar, setAvatar] = useState(buildAvatar())

    useEffect(() => {
        if (!isUndefined(user)) {
            setAvatar(buildUser())
        }
    }, [user])

    useEffect(() => {
        if (!isUndefined(account)) {
            setAvatar(buildAccount())
        }
    }, [account])

    return (
        <>
            <Tooltip
                content={avatar.name}
                placement="bottom"
                animation="duration-1000"
            >
                {avatar.url ? (
                    <>
                        <Avatar
                            img={avatar.url}
                            rounded={rounded}
                            size={size}
                            {...rest}
                        />
                    </>
                ) : (
                    <>
                        <div className="border rounded-full border-torbu-gray">
                            <Avatar
                                placeholderInitials={avatar.initials}
                                size={size}
                                {...rest}
                                rounded={rounded}
                            />
                        </div>
                    </>
                )}
            </Tooltip>
        </>
    )
}
export default TorbuAvatar

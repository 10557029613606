import React from 'react'
import { useAVToggle, useHMSActions } from '@100mslive/react-sdk'
import Icon from '../../Helpers/Icon'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'

function Footer() {
    const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle()
    const hmsActions = useHMSActions()

    return (
        <div className="control-bar">
            <ButtonPrimary
                color={'red'}
                mr={24}
                onClick={() => hmsActions.leave()}
                leftIcon={
                    <Icon
                        icon={'Logout'}
                        color="white"
                    />
                }
            >
                Leave Room
            </ButtonPrimary>

            <ButtonPrimary
                onClick={toggleAudio}
                leftIcon={
                    <Icon
                        icon={'Microphone'}
                        color="white"
                    />
                }
            >
                {isLocalAudioEnabled ? 'Mute' : 'Unmute'}
            </ButtonPrimary>

            <ButtonPrimary
                onClick={toggleVideo}
                leftIcon={
                    <Icon
                        icon={'Camera'}
                        color="white"
                    />
                }
            >
                {isLocalVideoEnabled ? 'Hide' : 'Unhide'}
            </ButtonPrimary>
        </div>
    )
}

export default Footer

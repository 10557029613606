import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import StrideHeader from '../../../Helpers/StrideHeader'
import StrideLoader from '../../../Helpers/StrideLoader'
import useAccounts from '../../../Hooks/Models/useAccounts'
import useCurrentAccount from '../../../Hooks/Models/useCurrentAccount'
import AccountOrgChart from '../Accounts/AccountOrgChart'
import AddNewAccount from './AddNewAccount'

export const AccountManager = () => {
    const { currentAccount } = useCurrentAccount()
    const [parentAccount, setParentAccount] = useState({})
    const accountModel = useAccounts()

    return (
        <>
            <StrideHeader
                header={'Team Org Chart'}
                icon={'Affiliate'}
                withBorder
            />

            <StrideLoader loading={accountModel.isLoading}>
                <AccountOrgChart
                    accounts={accountModel.data.map(account => {
                        return {
                            ...account,
                            nodeId: account.id,
                            parentId: account.parent?.id ? account.parent.id : null
                        }
                    })}
                />

                {!isEmpty(parentAccount) && (
                    <AddNewAccount
                        parentAccount={parentAccount}
                        setParentAccount={setParentAccount}
                        accountModel={accountModel}
                    />
                )}
            </StrideLoader>
        </>
    )
}

export default AccountManager

import { Box, Divider, Popover, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import React from 'react'
import Moment from 'react-moment'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import ButtonArchive from '../../../Components/Buttons/ButtonArchive'
import ButtonEdit from '../../../Components/Buttons/ButtonEdit'
import Icon from '../../../Helpers/Icon'
import useGQL from '../../../Hooks/useGQL'
import { useNotify } from '../../../Hooks/useNotify'
import state from '../../../Utils/State'
import graphqlClient from '../../../Utils/graphqlClient'
import queryClient from '../../../Utils/queryClient'

export const ChannelEdit = ({ setDrawer }) => {
    const [selectedChannel, setSelectedChannel] = useRecoilState(state.activeChatChannel)
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            title: selectedChannel.title
        },
        validate: {
            title: isNotEmpty('Channel name is required')
        }
    })

    const updateChannelGQL = useGQL('updateChannel')
    const archiveChannelGQL = useGQL('archiveChannel')

    const updateChannel = useMutation(data => graphqlClient.request(updateChannelGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllChannels'] }).then(() => {
                notify.updated('Channel')
                setDrawer({})
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const save = data => {
        const channelData = {
            id: selectedChannel.id,
            title: data.title
        }
        updateChannel.mutate(channelData)
    }

    const archiveChannelMutation = useMutation(data => graphqlClient.request(archiveChannelGQL, data), {
        onSuccess: () => {
            notify.archived('Channel')
            setSelectedChannel({})
            window.location.reload(false)
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const archiveChannel = () => {
        archiveChannelMutation.mutate({ id: selectedChannel.id })
    }

    return (
        <>
            <Text
                mb={25}
                fw={300}
            >
                Update Channel Details
            </Text>
            <form onSubmit={form.onSubmit(save)}>
                <TextInput
                    label="Channel name"
                    icon={
                        <Icon
                            icon={'Hash'}
                            size={14}
                        />
                    }
                    mb={15}
                    withAsterisk
                    {...form.getInputProps('title')}
                />

                <ButtonEdit
                    type={'submit'}
                    fullWidth
                >
                    Update channel
                </ButtonEdit>
            </form>

            <Box mt={50}>
                <Divider />

                <Text
                    fw={300}
                    size={14}
                    lineClamp={1}
                    my={'sm'}
                >
                    <Text
                        fw={'bold'}
                        span
                    >
                        Created:
                    </Text>{' '}
                    <Moment format={'MMM Do, YYYY @ hh:mm A'}>{selectedChannel.createdAt}</Moment>
                </Text>

                <Divider />

                <Popover
                    width={200}
                    position="bottom"
                    withArrow
                    shadow="md"
                >
                    <Popover.Target>
                        <Text
                            fw={300}
                            size={14}
                            lineClamp={1}
                            my={'sm'}
                        >
                            <Text
                                fw={'bold'}
                                span
                            >
                                Channel ID:
                            </Text>
                            {selectedChannel.id}
                        </Text>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Text
                            fw={300}
                            size={14}
                            my={'sm'}
                        >
                            {selectedChannel.id}
                        </Text>
                    </Popover.Dropdown>
                </Popover>

                <Divider />
                <Box mt={35}>
                    <ButtonArchive
                        fullWidth
                        onConfirm={archiveChannel}
                        modalTitle={'Are you sure you want to archive this channel?'}
                        modalContent={
                            <>
                                <div className='text-sm'>
                                    <p>{'When you archive a channel, it\'s archived for everyone.'}</p>
                                    <p>{'No one will be able to send messages to the channel.'}</p>
                                </div>
                            </>
                        }
                    >Archive Channel</ButtonArchive>
                </Box>
            </Box>
        </>
    )
}
export default ChannelEdit

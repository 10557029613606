import { Box, TextInput } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import Fuse from 'fuse.js'
import { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'

export const StrideTable = ({ data, columns, onClick, searchFilter, ...other }) => {
    const [filter, setFilter] = useState('')

    const fuse = useMemo(() => {
        const fuseOptions = {
            keys: ['user.firstName', 'user.lastName', 'roles.name']
        }
        return new Fuse(data, fuseOptions)
    }, [data])

    const searchFilterInput = useMemo(() => {
        return (
            <Box w={300}>
                <TextInput
                    placeholder={'Search'}
                    onChange={e => setFilter(e.target.value)}
                    value={filter}
                    icon={<IconSearch size="0.9rem" />}
                />
            </Box>
        )
    }, [filter])

    const filteredItems = useMemo(() => {
        if (typeof searchFilter !== 'undefined' && !isEmpty(filter)) {
            const searchResults = fuse.search(filter)
            return searchResults.map(result => result.item)
        }
        return data
    }, [searchFilter, filter, data, fuse])

    const handleRowClick = row => {
        if (typeof onClick !== 'undefined') {
            onClick(row)
        }
    }

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            striped
            highlightOnHover
            pointerOnHover
            persistTableHead
            fixedHeader
            responsive
            onRowClicked={handleRowClick}
            subHeader={typeof searchFilter !== 'undefined'}
            subHeaderComponent={searchFilterInput}
            {...other}
        />
    )
}

export default StrideTable

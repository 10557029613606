import { selectIsConnectedToRoom, useHMSActions, useHMSStore } from '@100mslive/react-sdk'
import { Center, Loader } from '@mantine/core'
import React, { useEffect } from 'react'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import Conference from './Conference'
import Footer from './Footer'
import './styles.css'

export const Huddle = () => {
    const isConnected = useHMSStore(selectIsConnectedToRoom)
    const hmsActions = useHMSActions()
    const { currentUser } = useCurrentUser()

    useEffect(() => {
        hmsActions.join({
            userName: currentUser.fullName,
            metaData: {
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                avatarFile: {
                    url: currentUser.avatarFile?.url
                }
            },
            authToken:
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXJzaW9uIjoyLCJ0eXBlIjoiYXBwIiwiYXBwX2RhdGEiOm51bGwsImFjY2Vzc19rZXkiOiI2NDQ4NmM1Njk1ZjE5NGQ1ZTUwOTcxZTIiLCJyb2xlIjoiYXR0ZW5kZWUiLCJyb29tX2lkIjoiNjQ1MTQ3YzM4YTc5Mzc3NWIwZjJkNDVjIiwidXNlcl9pZCI6IjA1ODhjMTViLWY3MTAtNDhjNi1iN2E1LTNiODExNDRlY2Q4MiIsImV4cCI6MTY4MzEzNDc5MSwianRpIjoiMjQzYTZmMDAtM2E5Yy00ZjA3LWFiYmMtZTRjMDk5OGU3YjE0IiwiaWF0IjoxNjgzMDQ4MzkxLCJpc3MiOiI2NDQ4NmM1Njk1ZjE5NGQ1ZTUwOTcxZTAiLCJuYmYiOjE2ODMwNDgzOTEsInN1YiI6ImFwaSJ9.alhgwVIVOOW-laa9ydRR--cvuckZ4AoOTIPapx70DYw'
        })
    }, [currentUser.name, hmsActions])

    useEffect(() => {
        window.onunload = () => {
            if (isConnected) {
                hmsActions.leave()
            }
        }
    }, [hmsActions, isConnected])

    return (
        <>
            {isConnected ? (
                <>
                    <Conference />
                    {/* <DeviceSettings /> */}
                    <Footer />
                </>
            ) : (
                <Center>
                    <Loader />
                </Center>
            )}
        </>
    )
}

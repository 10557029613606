import moment from 'moment';
import { useRecoilState } from "recoil";
import state from "../Utils/State";
import useEvents from './Models/useEvents';
import { isEmpty } from 'lodash';
import { calculateDateRange, createCalendarEngine, getTimezone } from '../Utils/utils';
import React, { createContext, useContext } from 'react';

const CalendarContext = createContext()
const useCalendarContext = () => useContext(CalendarContext)

const calculateFilter = (options) => {
    const { startDate, endDate, accountIds } = options
    return {
        filter: {
            startDate,
            endDate,
            archived: false,
            accountIds
        }
    }
}

export const CalendarProvider = ({ children }) => {
    const [calendarEngine, setCalendarEngine] = useRecoilState(state.calendarEngine)
    const events = useEvents(calculateFilter(calendarEngine))
    return (
        <CalendarContext.Provider value={{
            events
        }}>
            { children }
        </CalendarContext.Provider>
    )
}

export const useCalendar = () => {
    const today = moment()
    const [calendarEngine, setCalendarEngine] = useRecoilState(state.calendarEngine)

    const validateCalendarOptions = () => {
        if (isEmpty(calendarEngine)) return false
        const mustHave = ['startDate', 'endDate', 'accountIds', 'view', 'date', 'timezone']
        for (const key of mustHave) {
            if (!(key in calendarEngine)) return false
        }
        return true
    }

    if (!validateCalendarOptions()) {
        setCalendarEngine(createCalendarEngine())
        return null
    }

    const { events } = useCalendarContext()

    const setTeams = accountIds => {
        setOption('accountIds', accountIds)
    }

    const updateDateRange = (date, view) => {
        const range = calculateDateRange(date, view)
        setOption('date', date)
        setOption('startDate', range[0])
        setOption('endDate', range[1])
    }

    let upcomingDate = calendarEngine.date
    let upcomingView = calendarEngine.view

    const setView = view => {
        setOption('view', view)
        updateDateRange(upcomingDate, view)
        upcomingView = view
    }

    const setDate = date => {
        updateDateRange(date, upcomingView)
        upcomingDate = date
    }

    const setTimezone = timezone => {
        setOption('timezone', getTimezone(timezone))
    }

    const nextOptions = { ...calendarEngine }
    const setOption = (key, value) => {
        nextOptions[key] = value
        setCalendarEngine({ ...nextOptions })
        events.setFilter(calculateFilter(nextOptions))
    }

    return {
        events: events.data || [],
        isLoading: events.isLoading,
        date: calendarEngine.date,
        startDate: calendarEngine.startDate,
        endDate: calendarEngine.endDate,
        setDate: setDate,
        view: calendarEngine.view,
        setView: setView,
        setTeams: setTeams
    }
};

export default useCalendar

import { useEffect, useState } from 'react'

export const passwordRequirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' }
]

export const getStrength = password => {
    let multiplier = password.length > 5 ? 0 : 1
    passwordRequirements.forEach(requirement => {
        if (!requirement.re.test(password)) {
            multiplier += 1
        }
    })
    return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10)
}

const usePasswordStrength = () => {
    const [password, setPassword] = useState('')
    const [strength, setStrength] = useState(0)

    useEffect(() => {
        const newStrength = getStrength(password)
        setStrength(newStrength)
    }, [password])

    return { password, setPassword, strength }
}

export default usePasswordStrength

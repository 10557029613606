import { Group, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconAlertCircle, IconCheck } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ButtonEdit } from '../../Components/Buttons/ButtonEdit'
import { Well } from '../../Components/Well'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import graphqlClient from '../../Utils/graphqlClient'

export const ChangeUserInfo = () => {
    const { currentUser, setCurrentUser, reset, resetCurrentUser } = useCurrentUser()
    const [isSaving, setIsSaving] = useState(false)
    const notify = useNotify()
    const queryClient = useQueryClient()

    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        validate: {
            firstName: isNotEmpty('First name is required'),
            lastName: isNotEmpty('Last name is required'),
            email: isNotEmpty('Email is required')
        }
    })

    useEffect(() => {
        form.setFieldValue('firstName', currentUser.firstName)
        form.setFieldValue('lastName', currentUser.lastName)
        form.setFieldValue('email', currentUser.email)
        form.setFieldValue('phone', currentUser.phone)
    }, [currentUser])

    const updateUserGQL = useGQL('updateUser')
    const updateUser = useMutation(data => graphqlClient.request(updateUserGQL, data), {
        onSuccess: data => {
            setCurrentUser(data.updateUser)
            notify.updated('Profile')
            setIsSaving(false)
            resetCurrentUser
        },
        onError: error => {
            notify.error()
            console.error(error)
            setIsSaving(false)
        }
    })

    const save = data => {
        const newData = { ...data }
        setIsSaving(true)
        newData.id = currentUser.id
        if (newData.email === currentUser.email) newData.email = undefined
        updateUser.mutate(newData)
    }

    return (
        <>
            <Well>
                <form onSubmit={form.onSubmit(save)}>
                    <Group mb={16}>
                        <TextInput
                            label="First Name"
                            placeholder="First Name"
                            withAsterisk
                            {...form.getInputProps('firstName')}
                        />

                        <TextInput
                            label="Last Name"
                            placeholder="Last Name"
                            withAsterisk
                            {...form.getInputProps('lastName')}
                        />
                    </Group>

                    <Group mb={16}>
                        <TextInput
                            label="Email"
                            placeholder="Email"
                            withAsterisk
                            {...form.getInputProps('email')}
                        />

                        <TextInput
                            label="Phone"
                            placeholder="Phone"
                            {...form.getInputProps('phone')}
                        />
                    </Group>

                    <Group position={'right'}>
                        <ButtonEdit saving={isSaving}>Update Profile</ButtonEdit>
                    </Group>
                </form>
            </Well>
        </>
    )
}
export default ChangeUserInfo

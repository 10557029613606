import { Box, Group, PasswordInput, Popover, Progress } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconAlertCircle, IconCheck } from '@tabler/icons-react'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import ButtonEdit from '../../Components/Buttons/ButtonEdit'
import { Well } from '../../Components/Well'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import usePasswordStrength, { getStrength, passwordRequirements } from '../../Hooks/usePasswordStrength'
import graphqlClient from '../../Utils/graphqlClient'
import PasswordRequirement from '../Authentication/PasswordRequirement'

export const ChangePassword = () => {
    const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate()
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validate: {
            currentPassword: isNotEmpty('Current password is required'),
            newPassword: isNotEmpty('New password is required'),
            confirmPassword: isNotEmpty('Confirm new password is required')
        }
    })

    const updatePasswordGQL = useGQL('updatePassword')
    const updatePassword = useMutation(data => graphqlClient.request(updatePasswordGQL, data), {
        onSuccess: data => {
            notify.updated('Password')
            setIsSaving(false)
            navigate('/logout')
        },
        onError: error => {
            notify.error()
            setIsSaving(false)
        }
    })

    const { password, setPassword, strength } = usePasswordStrength()
    const passwordStrength = getStrength(form?.values?.newPassword)
    const color = passwordStrength === 100 ? '#035157' : passwordStrength > 50 ? '#d6fc49' : '#fc7c57'
    const [popoverOpened, setPopoverOpened] = useState(false)

    const passwordChecks = passwordRequirements.map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(form?.values?.newPassword)}
        />
    ))

    const save = data => {
        setIsSaving(true)
        if (data.newPassword !== data.confirmPassword) {
            setIsSaving(false)
            alert('Passwords do not match!')
        } else if (passwordStrength !== 100) {
            setIsSaving(false)
            notify.passwordReqError()
        } else {
            updatePassword.mutate({ currentPassword: data.currentPassword, newPassword: data.newPassword, authIdentifier: 'web' })
        }
    }

    return (
        <>
            <Well>
                <Popover
                    opened={popoverOpened}
                    position="bottom"
                    width="target"
                    transitionProps={{ transition: 'pop' }}
                >
                    <form onSubmit={form.onSubmit(save)}>
                        <Box mb={16}>
                            <PasswordInput
                                label="Current Password"
                                placeholder="Enter Current Password"
                                withAsterisk
                                {...form.getInputProps('currentPassword')}
                            />
                        </Box>
                        <Popover.Target>
                            <Box
                                mb={16}
                                onFocusCapture={() => setPopoverOpened(true)}
                                onBlurCapture={() => setPopoverOpened(false)}
                            >
                                <PasswordInput
                                    placeholder="Enter New Password"
                                    label="New Password"
                                    description="To ensure the safety of your account, your password must meet the following requirements: it must be at least six characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
                                    withAsterisk
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    {...form.getInputProps('newPassword')}
                                />
                            </Box>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Progress
                                color={color}
                                value={passwordStrength}
                                size={5}
                                mb="sx"
                            />

                            <PasswordRequirement
                                label="Includes at least 6 characters"
                                meets={form?.values?.newPassword.length > 5}
                            />
                            {passwordChecks}
                        </Popover.Dropdown>
                        <Box mb={32}>
                            <PasswordInput
                                placeholder="Enter New Password again to Confirm"
                                label="Confirm New Password"
                                withAsterisk
                                {...form.getInputProps('confirmPassword')}
                            />
                        </Box>

                        <Group position={'right'}>
                            <ButtonEdit saving={isSaving}>Update Password</ButtonEdit>
                        </Group>
                    </form>
                </Popover>
            </Well>
        </>
    )
}
export default ChangePassword

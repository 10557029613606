import React,{ useEffect,useState } from "react";
import { useLocation,useNavigate,useParams } from "react-router";
import StrideDrawer from "../../../Helpers/StrideDrawer";
import EventViewDrawer from "../../Events/EventViewDrawer";

export const CalendarDrawer = () => {
    const eventParams = useParams()
    const urlLocation = useLocation()
    const navigate = useNavigate()
    
    const [eventId, setEventId] = useState(undefined)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [drawerTab, setDrawerTab] = useState(undefined)
    const [drawerSize, setDrawerSize] = useState('50%')

    useEffect(() => {
        if (urlLocation.pathname === '/dashboard/new-event') {
            setDrawerOpen(true)
            setDrawerSize('60%')
            setEventId(undefined)
        }
        if (eventParams.eventId !== undefined) {
            setDrawerOpen(true)
            setDrawerSize('40%')
            setEventId(eventParams.eventId)
        }
        if (eventParams.eventTab !== undefined) {
            setDrawerTab(eventParams.eventTab)
        } else {
            setDrawerTab(undefined)
        }
    }, [urlLocation, eventParams])

    const handleCloseDrawer = () => {
        navigate(`/dashboard`)
        setDrawerOpen(false)
    }

    return (
        <>
            <StrideDrawer
                opened={drawerOpen}
                onClose={handleCloseDrawer}
                title="Event Manager"
                size={drawerSize}
            >
                <EventViewDrawer
                    eventId={eventId}
                    drawerTab={drawerTab}
                    handleCloseDrawer={handleCloseDrawer}
                />
            </StrideDrawer>
        </>
    )
};
export default CalendarDrawer

import React from 'react'
import Icon from '../Icon'

export const ButtonCreate = ({ icon = 'Create', saving, children, fullWidth, ...other }) => {
    const buttonClass = `text-md inline-flex items-center gap-x-1.5 rounded border-2 border-torbu-peach bg-torbu-white px-3 py-1.5 text-torbu-green shadow-sm hover:bg-torbu-peach hover:text-torbu-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-torbu-peach ${
        fullWidth ? 'w-full' : ''
    }`
    return (
        <button
            className={buttonClass}
            {...other}
        >
            {saving ? (
                <>
                    <Icon
                        icon={'Loader'}
                        size={6}
                        className="animate-spin-fast"
                    />
                    Saving
                </>
            ) : (
                <>
                    <Icon
                        icon={icon}
                        size={6}
                    />
                    {children}
                </>
            )}
        </button>
    )
}
export default ButtonCreate

/* eslint-disable */

import { isEmpty,isUndefined } from 'lodash'
import { useCallback,useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import state from '../../Utils/State'
import queryClient from '../../Utils/queryClient'
import useGQL from '../useGQL'
import { useRef } from 'react'

const getSession = () => {
    const data = localStorage.getItem("Stride-Data")
    if (data) {
        return JSON.parse(localStorage.getItem("Stride-Data")).sessionId
    }
    return ""
}

const useCurrentUser = () => {
    const [queryEnabled, setQueryEnabled] = useState(false)
    const [currentUser, setCurrentUser] = useRecoilState(state.currentUser)
    const getCurrentUserGQL = useGQL('getCurrentUser')
    
    const userCallbacks = useRef(new Set())

    const permissions = () => {
        if (!isUndefined(currentUser) && !isUndefined(currentUser.accountUsers) && !isEmpty(currentUser.accountUsers)) {
            return currentUser.accountUsers.map(user => {
                return {
                    accountId: user.account.id,
                    permissions: [...user.permissions.map(perm => perm.code)],
                    roles: user.roles.map(role => role.name)
                }
            })
        }
        return []
    }

    useQuery(['getCurrentUser'], {
        onSuccess: data => {
            if (data?.getCurrentUser) {
                setCurrentUser(data.getCurrentUser)
                setQueryEnabled(true)

                for (const callback of userCallbacks.current) {
                    callback(data.getCurrentUser)
                }
                userCallbacks.current = new Set()
            }
        },
        enabled: getSession() !== null && getSession() !== undefined && getSession() !== "",
    })

    const resetCurrentUser = useCallback(() => {
        queryClient.invalidateQueries('getCurrentUser')
    }, [setQueryEnabled])

    const setUser = (user) => {
        queryClient.setQueryData('currentUser', user)
        setCurrentUser(user)
    }

    const onUserUpdate = (callback) => {
        userCallbacks.current.add(callback)
    }

    return { currentUser, setCurrentUser: setUser, resetCurrentUser, permissions: permissions(), onUserUpdate}
}
export default useCurrentUser

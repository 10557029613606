import { Alert,Box,Group,Text,TextInput } from '@mantine/core'
import { isNotEmpty,useForm } from '@mantine/form'
import React,{ useEffect,useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Icon from '../../../Helpers/Icon'
import { useNotify } from '../../../Hooks/useNotify'
import state from '../../../Utils/State'
import graphqlClient from '../../../Utils/graphqlClient'

const Register = ({ page, setPage, errorMessage, setErrorMessage, sharedData, setSharedData }) => {
    const [sessionId, setSessionId] = useRecoilState(state.sessionId)
    const navigate = useNavigate()
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        },
        validate: {
            firstName: isNotEmpty('First name is required'),
            lastName: isNotEmpty('Last name is required'),
            email: isNotEmpty('Email is required'),
            password: isNotEmpty('Password is required')
        }
    })

    const authenticateWithAPI = data => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Stride-Auth': 'web'
            },
            body: JSON.stringify(data)
        }
        fetch(`${process.env.REACT_APP_STRIDE_API_ENDPOINT}/new-user`, requestOptions)
            .then(response => response.json())
            .then(response => {
                notify.registration()

                graphqlClient.setHeader('Stride-Web-Token', response.session.id)
                setSessionId(response.session.id)
                window.location.reload(false)
            })
            .catch(error => {
                console.error('error', error)
                notify.error()
                // setErrorMessage('There was an internal issue with our servers, try again.')
            })
    }
    useEffect(() => {
        if (sessionId) {
            navigate('/')
        }
    }, [sessionId])

    const onSubmitSubmit = data => {
        authenticateWithAPI(data)
    }

    return (
        <>
            <h1 className="line font-torbu-heading text-3xl tracking-tight text-torbu-green dark:text-white">Welcome to TORBU!</h1>
            <div className="pb-4 text-sm font-light leading-6 text-gray-500 dark:text-gray-300 sm:leading-3">Let&apos;s get your account setup so you can work with your team!</div>

            <div>
                <form onSubmit={form.onSubmit(onSubmitSubmit)}>
                    <TextInput
                        size={'md'}
                        mb={15}
                        label="First Name"
                        withAsterisk
                        icon={
                            <Icon
                                icon={'User'}
                                size={14}
                            />
                        }
                        {...form.getInputProps('firstName')}
                    />

                    <TextInput
                        size={'md'}
                        mb={15}
                        label="Last Name"
                        withAsterisk
                        icon={
                            <Icon
                                icon={'User'}
                                size={14}
                            />
                        }
                        {...form.getInputProps('lastName')}
                    />

                    <TextInput
                        size={'md'}
                        mb={15}
                        label="Email Address"
                        withAsterisk
                        icon={
                            <Icon
                                icon={'At'}
                                size={14}
                            />
                        }
                        {...form.getInputProps('email')}
                    />
                    <TextInput
                        type={'password'}
                        size={'md'}
                        mb={15}
                        label="Password"
                        withAsterisk
                        icon={
                            <Icon
                                icon={'Lock'}
                                size={14}
                            />
                        }
                        {...form.getInputProps('password')}
                    />

                    <div className="mt-8">
                        <button
                            type="submit"
                            className="dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg border-2 border-torbu-peach bg-torbu-white px-5 py-2.5 text-center font-torbu-demi-bold text-sm text-torbu-green hover:bg-torbu-peach hover:text-torbu-white focus:outline-none focus:ring-4 focus:ring-torbu-peach dark:bg-torbu-peach"
                        >
                            Register your account
                        </button>
                    </div>
                </form>

                <div className="mt-8">
                    <button
                        type="button"
                        onClick={e => {
                            e.preventDefault()
                            setErrorMessage({})
                            setPage('login')
                        }}
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </>
    )
}

export default Register

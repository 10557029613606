import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'

const TorbuMenu = ({ button, menuItems, className = '', children }) => {
    return (
        <Menu
            as='div'
            className={twMerge('relative', className)}
        >
            {children}
        </Menu>
    )
}

TorbuMenu.Button = ({ children, ...props }) => (
    <Menu.Button {...props}>
        {children}
    </Menu.Button>
)

TorbuMenu.Items = ({ children, className, ...props }) => (
    <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
    >
        <Menu.Items className={twMerge(
            'absolute z-10 py-1 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            className
        )} {...props}>
            {children}
        </Menu.Items>
    </Transition>
)

TorbuMenu.Item = ({ children, ...props }) => (
    <Menu.Item {...props}>
        {children}
    </Menu.Item>
)

export default TorbuMenu

import { Box, Group, Paper, Text } from '@mantine/core'
import { useNotify } from '../../Hooks/useNotify'
import { IconAlertCircle, IconCheck } from '@tabler/icons-react'
import moment from 'moment'
import React from 'react'
import { useDrag } from 'react-dnd'
import Moment from 'react-moment'
import { useMutation } from 'react-query'
import useGQL from '../../Hooks/useGQL'
import usePermission from '../../Hooks/usePermission'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'

const Segment = ({ segment, event, settings, onSegmentClick, color, canEdit }) => {
    const { hasPermissionAnywhere } = usePermission()
    const notify = useNotify()

    const eventStartTime = moment(event.startTime)
    const segmentStartTime = eventStartTime.add(segment.minutesFromEventStart, 'minutes').toDate()

    const updateSegmentGQL = useGQL('updateSegment')
    const getEventGQL = useGQL('getEvent')

    const updateSegmentMutation = useMutation(data => graphqlClient.request(updateSegmentGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [getEventGQL, { id: event.id }] }).then(() => {
                notify.updated('Topic')
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const handleSegmentUpdate = dropResult => {
        const eventStartTime = moment(event.startTime)
        const segmentStartTime = moment(dropResult.interval.date)

        const seg = {
            id: segment.id,
            title: segment.title,
            assignedUserId: dropResult.user.id,
            duration: segment.duration,
            minutesFromEventStart: parseInt(segmentStartTime.diff(eventStartTime, 'minutes')),
            description: segment.description
        }

        //Set this for the segment that is already rendered. If we dont and immediately drag it will reset to prev state
        segment.title = seg.title
        segment.description = seg.description
        segment.assignedUserId = seg.assignedUserId
        segment.duration = seg.duration
        segment.minutesFromEventStart = seg.minutesFromEventStart

        updateSegmentMutation.mutate(seg)
    }

    let [{ isDragging }, drag] = useDrag(() => ({
        type: 'SegmentContainer',
        item: segment,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (dropResult && dropResult.interval) {
                handleSegmentUpdate(dropResult)
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId()
        })
    }))

    const opacity = isDragging ? 0 : 1

    const calculateHeight = () => {
        const duration = parseInt(segment.duration)
        let height = settings.timeIncrementsHeight

        if (duration > 0) {
            if (duration <= settings.timeIncrements) {
                height = settings.timeIncrementsHeight
            } else {
                height = Math.floor(duration / settings.timeIncrements) * settings.timeIncrementsHeight
            }
        }
        return String(height) + 'px'
    }

    const calculateTop = () => {
        const baseTop = 0
        const minutesFromEventStart = parseInt(segment.minutesFromEventStart)
        let top = baseTop

        if (minutesFromEventStart > 0) {
            top = Math.round(minutesFromEventStart / settings.timeIncrements) * settings.timeIncrementsHeight
        }
        return String(top) + 'px'
    }

    if (!hasPermissionAnywhere('event:create:v2') || canEdit === false) {
        drag = undefined
    }

    const style = {
        outlineStyle: 'dashed',
        outlineWidth: '2px',
        outlineColor: '#e6e7e8',
        cursor: canEdit ? 'move' : 'default'
    }
    return (
        <Paper
            ref={drag}
            style={{
                ...style,
                opacity,
                position: 'absolute',
                top: calculateTop(),
                height: calculateHeight(),
                borderLeft: `1px solid #707070`,
                borderRight: `1px solid #707070`,
                borderBottom: `1px solid #707070`,
                borderTop: `5px solid ${color}`,
                overflow: 'hidden'
            }}
            w={'100%'}
            withBorder
            shadow
            bg={'#FFFFF'}
            radius={'md'}
            onClick={onSegmentClick}
            p={5}
        >
            <Group position={'apart'}>
                <Text size="md">{segment.title}</Text>
                <Text
                    size="sm"
                    color={'dimmed'}
                >
                    <Moment format={'hh:mm a'}>{segmentStartTime}</Moment>
                </Text>
            </Group>
            <Box>
                <Text
                    size="sm"
                    color={'dimmed'}
                >
                    <div dangerouslySetInnerHTML={{ __html: segment.description }} />
                </Text>
            </Box>
        </Paper>
    )
}
export default Segment

import { find,isEmpty,isUndefined } from 'lodash'
import moment from 'moment'
import React,{ useEffect,useMemo,useState } from 'react'
import { useQuery } from 'react-query'
import { useParams,useSearchParams } from 'react-router-dom'
import ApplicationTwoColumns from '../../Helpers/ApplicationTwoColumns'
import StrideLoader from '../../Helpers/StrideLoader'
import useGQL from "../../Hooks/useGQL"
import AgendaApplication from '../EventAgenda/AgendaApplication'
import EventForm from '../EventApplication/EventForm'
import EventMedia from './EventMedia'
import EventNavigation from './EventNavigation'

export const EventApplication = () => {
    const { view, eventId } = useParams()
    const [event, setEvent] = useState({})
    const [searchParams] = useSearchParams()
    

    useEffect(() => {
        if(isUndefined(eventId)){
            setEvent({})
        }
    }, [eventId])

    useEffect(() => {
        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            setEvent({
                startTime: moment.unix(searchParams.get('startDate')),
                endTime: moment.unix(searchParams.get('endDate'))
            })
        }
    }, [searchParams, setEvent])


    const getEventGQL = useGQL('getEvent')
    const query = useQuery([getEventGQL, {id: eventId}], {
        onSettled: (data) => {
            if(!isEmpty(data) && !isEmpty(data.getEvent)){
                setEvent(data.getEvent)
            }else{
                setEvent({})
            }
        },
        enabled: !isUndefined(eventId)
    })

    const availableViews = useMemo(() => {
        return [
            {
                view: 'new',
                component: <EventForm event={event} />
            },
            {
                view: 'edit',
                component: <EventForm event={event} />
            },
            {
                view: 'agenda',
                component: <AgendaApplication event={event} />
            },
            {
                view: 'media',
                component: <EventMedia event={event} />
            }
        ]
    }, [event])

    return (
        <ApplicationTwoColumns
            key={view}
            leftColumn={(
                <>
                    <StrideLoader loading={query.isLoading}>
                        <EventNavigation event={event}/>
                    </StrideLoader>
                </>
            )}
            rightColumn={(
                <>
                    <StrideLoader loading={query.isLoading}>
                        {find(availableViews, availableView => availableView.view === view).component}
                    </StrideLoader>
                </>
            )}
        />
    )
}

export default EventApplication

import React from 'react'
import { Well } from '../../Components/Well'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import AvatarUploader from '../Avatar/AvatarUploader'

export const ChangeAvatar = () => {
    const { currentUser } = useCurrentUser()

    return (
        <>
            <Well>
                <AvatarUploader user={currentUser} />
            </Well>
        </>
    )
}
export default ChangeAvatar

import { Badge,Group,Modal,Paper,Text } from '@mantine/core'
import React,{ useState } from 'react'
import Moment from 'react-moment'
import ButtonCreate from '../../../Components/Buttons/ButtonCreate'
import ButtonPrimary from '../../../Components/Buttons/ButtonPrimary'
import Icon from '../../../Helpers/Icon'
import StrideAvatar from '../../../Helpers/StrideAvatar'
import StrideLoader from '../../../Helpers/StrideLoader'
import StrideTable from '../../../Helpers/StrideTable'
import useAccounts from '../../../Hooks/Models/useAccounts'
import useCurrentAccount from '../../../Hooks/Models/useCurrentAccount'
import AddAccount from './AddAccount'

export const AdminAccounts = () => {
    const { setCurrentAccount } = useCurrentAccount()

    const [modalOpen, setModalOpen] = useState(false)
    const accountModel = useAccounts({ filter: { primaryAccounts: true, admin: true } })

    const columns = [
        {
            id: 'account',
            name: 'Account',
            sortable: true,
            selector: row => row.name,
            cell: row => (
                <Group>
                    <StrideAvatar account={row} />
                    <Text>{row.name}</Text>
                </Group>
            )
        },
        {
            id: 'trialExpiresAt',
            name: 'Trial Expires on',
            sortable: true,
            cell: row => {
                if (row.isTrialExpired === true) {
                    return (
                        <Badge
                            color="red"
                            variant="filled"
                        >
                            Expired
                        </Badge>
                    )
                } else if (row.trialExpiresAt) {
                    return (
                        <Badge
                            color="green"
                            variant="filled"
                        >
                            <Text color={'dark'}>
                                <Moment format="MMM DD, YYYY">{row.trialExpiresAt}</Moment>
                            </Text>
                        </Badge>
                    )
                } else {
                    return ''
                }
            },
            selector: row => row.trialExpiresAt,
            width: '200px'
        },
        {
            id: 'memberCount',
            name: 'Users',
            sortable: true,
            cell: row => row.memberCount,
            selector: row => row.memberCount,
            width: '100px'
        },
        {
            id: 'primary',
            name: '',
            button: true,
            cell: row => (
                <>
                    <ButtonPrimary
                        onClick={() => {
                            setCurrentAccount(row)
                            location.replace('/')
                        }}
                        icon={'Lock'}
                    >
                        Switch to account
                    </ButtonPrimary>
                </>
            ),
            width: '200px'
        }
    ]

    return (
        <>
            <Group
                position={'right'}
                mb={16}
            >
                <ButtonCreate onClick={() => setModalOpen(true)}>Add New Account</ButtonCreate>
            </Group>

            <StrideLoader
                loading={accountModel.isLoading}
                label={'Loading Accounts...'}
            >
                <Paper withBorder>
                    <StrideTable
                        columns={columns}
                        data={accountModel.data}
                        pagination
                        searchFilter={(data, filter) => {
                            return data.filter(account => {
                                return account.id.toLowerCase().includes(filter) || account.name.toLowerCase().includes(filter)
                            })
                        }}
                    />
                </Paper>
            </StrideLoader>

            <Modal
                opened={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Add New Account"
            >
                <AddAccount setModalOpen={setModalOpen} />
            </Modal>
        </>
    )
}
export default AdminAccounts

import { Alert, Box, Checkbox, Divider, Drawer, Grid, Group, Loader, Paper, ScrollArea, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import ButtonArchive from '../../Components/Buttons/ButtonArchive'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Can from '../../Helpers/Can'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import usePermission from '../../Hooks/usePermission'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'

export const RolesDrawer = ({ roleDrawer, setRoleDrawer, permissions, account }) => {
    const { hasPermissionAnywhere } = usePermission()
    const notify = useNotify()

    const [role, setRole] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [isSavingPermission, setIsSavingPermission] = useState('')

    const form = useForm({
        initialValues: {
            name: '',
            cascades: false
        },
        validate: {
            name: isNotEmpty('Name is required')
        }
    })

    useEffect(() => {
        if (!isEmpty(roleDrawer) && (roleDrawer.type === 'edit' || roleDrawer.type === 'clone')) {
            form.setFieldValue('name', roleDrawer.role.name)
            form.setFieldValue('cascades', roleDrawer.role.cascades)
            setRole(roleDrawer.role)
        } else {
            form.setFieldValue('name', '')
            form.setFieldValue('cascades', false)
            setRole({})
        }
    }, [roleDrawer])

    const createRoleGQL = useGQL('createRole')
    const updateRoleGQL = useGQL('updateRole')
    const archiveRoleGQL = useGQL('archiveRole')
    const toggleRolePermissionGQL = useGQL('toggleRolePermission')

    const createRole = useMutation(data => graphqlClient.request(createRoleGQL, data), {
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: ['getAllRolesV2'] }).then(() => {
                notify.created('Role')
                form.reset()
                setIsSaving(false)
                setRoleDrawer({ type: 'edit', role: data.createRole })
                setRole(data.createRole)
            })
        },
        onError: error => {
            console.error(error)
            setIsSaving(false)
            notify.error()
        }
    })

    const updateRole = useMutation(data => graphqlClient.request(updateRoleGQL, data), {
        onSuccess: data => {
            queryClient.invalidateQueries({ queryKey: ['getAllRolesV2'] }).then(() => {
                notify.updated('Role')
                form.reset()
                setIsSaving(false)
                setRoleDrawer({ type: 'edit', role: data.updateRole })
                setRole(data.updateRole)
            })
        },
        onError: error => {
            console.error(error)
            setIsSaving(false)
            notify.error()
        }
    })

    const archiveRoleMutation = useMutation(data => graphqlClient.request(archiveRoleGQL, data), {
        onSuccess: (data) => {
            const isSuccess = data.archiveRole
            if (isSuccess) {
                notify.archived('Role')
                queryClient.invalidateQueries({ queryKey: ['getAllRolesV2'] }).then(() => {
                    form.reset()
                    setRoleDrawer({})
                })
            } else {
                notify.archiveRoleError()
            }  
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const toggleRolePermission = useMutation(data => graphqlClient.request(toggleRolePermissionGQL, data), {
        onSuccess: data => {
            notify.updated('Permission')
            queryClient.invalidateQueries({ queryKey: ['getAllRolesV2'] }).then(() => {
                setRole(data.toggleRolePermission)
                setIsSavingPermission('')
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const saveRole = data => {
        setIsSaving(true)

        if (roleDrawer.type === 'edit') {
            data.id = role.id
            updateRole.mutate(data)
        } else if (roleDrawer.type === 'clone') {
            data.cloneRoleId = role.id
            data.accountId = account.id
            createRole.mutate(data)
        } else {
            data.accountId = account.id
            createRole.mutate(data)
        }
    }

    const handleCheckboxChange = permission => {
        setIsSavingPermission(permission.id)
        toggleRolePermission.mutate({
            roleId: role.id,
            permissionId: permission.id
        })
    }

    return (
        <>
            <Drawer
                opened={Object.keys(roleDrawer).length}
                onClose={() => setRoleDrawer({})}
                title={'Role Management'}
                padding="md"
                position="right"
                size={'450px'}
            >
                {!isEmpty(roleDrawer) && roleDrawer.type === 'clone' && (
                    <Alert
                        title="ATTENTION"
                        color="red"
                        mb={25}
                    >
                        By creating this role it will copy all of the permissions set on the role{' '}
                        <Text
                            fw={'bold'}
                            underline
                            span
                        >
                            {roleDrawer.role.name}
                        </Text>
                        .
                    </Alert>
                )}
                <Divider mb={16} />

                <Text
                    color={'#3b3b3b'}
                    fw={'bold'}
                    size={'md'}
                    mb={16}
                >
                    Role
                </Text>

                <Paper
                    withBorder
                    bg={'#f1f1f1'}
                    p={'sm'}
                >
                    <form onSubmit={form.onSubmit(saveRole)}>
                        <TextInput
                            label={`Role name`}
                            placeholder={'What would you like to call this role?'}
                            {...form.getInputProps('name')}
                        />

                        <Checkbox
                            className="mt-4"
                            label={`Cascade to all teams below`}
                            checked={form.values.cascades}
                            onChange={event => form.setFieldValue('cascades', event.target.checked)}
                        />

                        <Group
                            mt="xl"
                            position={'right'}
                        >
                            <ButtonCreate
                                type="submit"
                                saving={isSaving}
                            >
                                Save Role Name
                            </ButtonCreate>
                        </Group>
                    </form>
                </Paper>

                <Divider my={16} />

                <Text
                    color={'#3b3b3b'}
                    fw={'bold'}
                    size={'md'}
                >
                    Permissions
                </Text>

                <ScrollArea.Autosize
                    mah={350}
                    type={'always'}
                    offsetScrollbars
                >
                    <Box>
                        {isEmpty(role) ? (
                            <>You must first create the role above to be able to select the permissions available.</>
                        ) : (
                            <>
                                {permissions.map(permission => (
                                    <>
                                        <Box
                                            key={permission.id}
                                            style={{ borderBottom: '1px solid #e6e7e8' }}
                                            mb={0}
                                            py={'sm'}
                                            bg={'#f1f1f1'}
                                        >
                                            <Grid align>
                                                <Grid.Col span={12}>
                                                    <Box pl={'sm'}>
                                                        <Text
                                                            color={'#3b3b3b'}
                                                            fw={'bold'}
                                                            size={'xs'}
                                                        >
                                                            {permission.name}
                                                        </Text>
                                                        <Text
                                                            color={'#777777'}
                                                            fw={300}
                                                            size={'xs'}
                                                        >
                                                            {permission.description}
                                                        </Text>
                                                    </Box>
                                                </Grid.Col>
                                            </Grid>
                                        </Box>

                                        {permission.permissions.map(permission => (
                                            <Box
                                                key={permission.id}
                                                style={{ borderBottom: '1px solid #e6e7e8' }}
                                                py={'sm'}
                                            >
                                                <Grid align>
                                                    <Grid.Col span={10}>
                                                        <Box pl={'sm'}>
                                                            <Text
                                                                color={'#3b3b3b'}
                                                                fw={'bold'}
                                                                size={'xs'}
                                                            >
                                                                {permission.name}
                                                            </Text>
                                                            <Text
                                                                color={'#777777'}
                                                                fw={300}
                                                                size={'xs'}
                                                            >
                                                                {permission.description}
                                                            </Text>
                                                        </Box>
                                                    </Grid.Col>
                                                    <Grid.Col span={2}>
                                                        {isSavingPermission === permission.id ? (
                                                            <>
                                                                <Loader />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Checkbox
                                                                    mt={8}
                                                                    size="sm"
                                                                    radius="xs"
                                                                    defaultChecked={find(role.permissions, rolePermissions => rolePermissions.id === permission.id)}
                                                                    style={{ cursor: 'pointer' }}
                                                                    disabled={!hasPermissionAnywhere('team:manage')}
                                                                    onChange={() => {
                                                                        handleCheckboxChange(permission)
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Grid.Col>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </>
                                ))}
                            </>
                        )}
                    </Box>
                </ScrollArea.Autosize>

                {role?.id && (
                    <>
                        <Can manage={'team'}>
                            <Divider my={16} />

                            <Box>
                                <Text
                                    color={'#3b3b3b'}
                                    fw={'bold'}
                                    size={'md'}
                                >
                                    Clone this role
                                </Text>
                                <Text
                                    color={'#777777'}
                                    fw={300}
                                    size={'sm'}
                                >
                                    Cloning this role will copy all permissions to a new role that you can change the name of
                                </Text>
                            </Box>
                            <Group
                                mt="xl"
                                position={'right'}
                            >
                                <ButtonPrimary
                                    onClick={() => setRoleDrawer({ type: 'clone', role: role })}
                                    fullWidth
                                >
                                    Clone this role
                                </ButtonPrimary>
                            </Group>
                        </Can>

                        <Can manage={'team'}>
                            <Divider my={16} />

                            <Box>
                                <Text
                                    color={'#3b3b3b'}
                                    fw={'bold'}
                                    size={'md'}
                                >
                                    Archive this role?
                                </Text>
                                <Text
                                    color={'#777777'}
                                    fw={300}
                                    size={'sm'}
                                >
                                    All users must be unassigned from this role and assigned to at least one other role before this role can be archived.
                                </Text>
                            </Box>
                            <Group
                                mt="xl"
                                position={'right'}
                            >
                                <ButtonArchive
                                    modalTitle={'Are you sure you want to archive this role?'}
                                    modalContent={<p className={'text-sm'}>{'No users can be associated with this role to be able to archive it.'}</p>}
                                    onConfirm={() => archiveRoleMutation.mutate({ id: roleDrawer.role.id })}
                                    fullWidth
                                >
                                    Archive Role
                                </ButtonArchive>
                            </Group>
                        </Can>
                        <Divider my={16} />
                    </>
                )}
            </Drawer>
        </>
    )
}
export default RolesDrawer

/* eslint-disable */
import { Box } from '@mantine/core'
import dagre from 'dagre'
import React, { useCallback, useEffect } from 'react'
import ReactFlow, { Background, Controls, MiniMap, addEdge, useEdgesState, useNodesState } from 'reactflow'
import PrimaryAccountNode from './Nodes/PrimaryAccountNode'
import TeamNode from './Nodes/TeamNode'

import { useTheme } from '@emotion/react'
import 'reactflow/dist/style.css'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 250
const nodeHeight = 150

const getLayoutedElements = (nodes, edges) => {
    dagreGraph.setGraph({ rankdir: 'TB' })

    nodes.forEach(node => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
    })

    edges.forEach(edge => {
        dagreGraph.setEdge(edge.source, edge.target)
    })

    dagre.layout(dagreGraph)

    nodes.forEach(node => {
        const nodeWithPosition = dagreGraph.node(node.id)
        node.targetPosition = 'top'
        node.sourcePosition = 'bottom'

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2
        }

        return node
    })

    return { nodes, edges }
}

const nodeTypes = {
    PrimaryAccountNode: PrimaryAccountNode,
    TeamNode: TeamNode
}

const AccountOrgChart = ({ accounts }) => {
    const theme = useTheme()

    const [nodes, setNodes] = useNodesState([])
    const [edges, setEdges] = useEdgesState([])

    useEffect(() => {
        const teamNodes = []
        const teamEdges = []

        accounts.forEach(account => {
            if (account.isPrimaryAccount) {
                teamNodes.push({ id: account.id, position: { x: 0, y: 0 }, data: account, type: 'PrimaryAccountNode' })
            } else {
                teamNodes.push({ id: account.id, position: { x: 0, y: 0 }, data: account, type: 'TeamNode' })
                teamEdges.push({ id: `edge-${account.parent.id}-${account.id}`, source: account.parent.id, target: account.id })
            }

            // if (account.children.length === 0) {
            //     teamNodes.push({ id: `new-account-${account.id}`, position: { x: 0, y: 0 }, data: account, type: 'AddNewTeamNode' })
            //     teamEdges.push({ id: `edge-${account.id}-${account.id}`, source: account.id, target: `new-account-${account.id}` })
            // }
        })

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(teamNodes, teamEdges)

        setNodes(layoutedNodes)
        setEdges(layoutedEdges)
    }, [accounts, dagreGraph])

    const onConnect = useCallback(params => setEdges(eds => addEdge({ ...params }, eds)), [])

    const edgeOptions = {
        animated: true,
        type: 'smoothstep',
        style: {
            stroke: theme.colors.torbu[3],
            strokeWidth: 3
        }
    }

    const nodeColor = node => {
        const colorsAllowed = ['#FC7C57', '#FC7C57', '#035157', '#51cf66', '#5c7cfa', '#ff922b', '#22b8cf', '#ff6b6b', '#845ef7', '#fcc419', '#339af0', '#f06595', '#cc5de8', '#51cf66', '#5c7cfa', '#ff922b', '#22b8cf', '#ff6b6b', '#845ef7', '#fcc419']
        return colorsAllowed[node.data.generationLevel - 1]
    }

    return (
        <>
            <Box>
                <div style={{ width: 'calc(100vw - 350px)', height: 'calc(100vh - 160px)' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        defaultEdgeOptions={edgeOptions}
                        fitView
                        proOptions={{ hideAttribution: true }}
                        style={{
                            backgroundColor: '#F6F7F8'
                        }}
                    >
                        <Controls
                            position={'top-left'}
                            showInteractive={false}
                        />
                        <MiniMap
                            nodeColor={nodeColor}
                            style={{ border: '1px solid #23272E', borderRadius: '8px' }}
                            nodeBorderRadius={20}
                            ariaLabel=""
                            zoomable
                            pannable
                        />
                        <Background
                            variant="dots"
                            gap={12}
                            size={1}
                        />
                    </ReactFlow>
                </div>
            </Box>
        </>
    )
}
export default AccountOrgChart

import { Box, Group, Text } from "@mantine/core";
import { IconError404 } from "@tabler/icons-react";
import React from "react";
import { Header } from "../../../Components/Header";
import ApplicationTwoColumns from "../../../Helpers/ApplicationTwoColumns";
import SiteWrapper from "../../../Theme/Components/SiteWrapper";
import StrideHeader from "../../../Helpers/StrideHeader";
import ButtonLink from "../../../Components/Buttons/ButtonLink";

const getMessageFromCode = (code) => {
    switch (code) {
    case "404":
        return "Sorry, we can't find that page!";
    case "403":
        return "You do not have permission to access this page!";
    case "500":
        return "Internal server error";
    default:
        return "An error occured!";
    }
};

const ErrorPage = ({ code }) => {
    const message = getMessageFromCode(code);

    return (
        <SiteWrapper>
            <Header text={"Access Denied"} icon={"Lock"}></Header>
            <ApplicationTwoColumns
                rightColumn={
                    <>
                        <StrideHeader header={message} withBorder></StrideHeader>
                        <Text style={{ paddingBottom: "12px" }}>
                            {"Error Code: " + code}
                        </Text>

                        <ButtonLink to="/">Return to Dashboard</ButtonLink>
                    </>
                }
            />
        </SiteWrapper>
    );
};

export default ErrorPage;

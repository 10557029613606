import { useMutation, useQueryClient } from "react-query"
import useGQL from "../useGQL"
import graphqlClient from "../../Utils/graphqlClient"

const useBothKeys = (key) => {
    return [key, useGQL(key)]
}
const invalidateKeys = (queryClient, keys) => {
    for (const key of keys) {
        queryClient.invalidateQueries({
            queryKey: key
        })
    }
}

const useEventMutation = ({
    onSuccess,
    onError,
    type = 'update'
} = {}) => {
    const getEventKeys = useBothKeys('getEvent')
    const getAllEventsKeys = useBothKeys('getAllEventsKeys')
    const getAllCallerEventsKeys = useBothKeys('getAllCallerEvents')
    const getAllSegmentTemplatesKeys = useBothKeys('getAllSegmentTemplates')

    const queryClient = useQueryClient()
    const gql = useGQL({
        update: 'updateEvent',
        create: 'createEvent',
        archive: 'archiveEvent',
        updateSegment: 'updateSegment',
        createSegment: 'createSegment',
        archiveSegment: 'archiveSegment'
    }[type])
    const mutation = useMutation(data => {
        return graphqlClient.request(gql, data)
    }, {
        onSuccess(...args) {
            // in the future could improve this to be highly specific about what queries are invalidated
            // by looking at the update data
            queryClient.invalidateQueries({
                queryKey: ['getEvent']
            })
            queryClient.invalidateQueries({
                queryKey: ['getAllEvents']
            })
            queryClient.invalidateQueries({
                queryKey: ['getAllCallerEvents']
            })
            invalidateKeys(queryClient, [...getEventKeys, ...getAllEventsKeys, ...getAllCallerEventsKeys])
            if (['updateSegment', 'createSegment'].includes(type)) {
                invalidateKeys(queryClient, getAllSegmentTemplatesKeys)
            }
            onSuccess?.(...args)
        },
        onError
    })
    return mutation
}

export default useEventMutation

import { Box, Divider, Group, Title } from '@mantine/core'
import { isUndefined } from 'lodash'
import React from 'react'
import Icon from './Icon'

export const StrideHeader = ({ icon, header, crumbs, withBorder }) => {
    let headerContent = <Title order={1}>{header}</Title>

    if (!isUndefined(crumbs) && crumbs.length) {
        headerContent = (
            <>
                <Group spacing={'sm'}>
                    {headerContent}
                    {crumbs.map(crumb => (
                        <Group key={crumb.title + String(Math.random())}>
                            <Divider
                                h={18}
                                mt={6}
                                size={'sm'}
                                orientation="vertical"
                            />
                            <Title order={1}>{crumb.title}</Title>
                        </Group>
                    ))}
                </Group>
            </>
        )
    }

    return (
        <>
            {!isUndefined(icon) ? (
                <>
                    <Group spacing={8}>
                        <Box>
                            <Icon icon={icon} />
                        </Box>
                        <Box>{headerContent}</Box>
                    </Group>
                </>
            ) : (
                <>{headerContent}</>
            )}

            {!isUndefined(withBorder) && <Divider my={'sm'} />}
        </>
    )
}

export default StrideHeader

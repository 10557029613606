import React, { useEffect, useRef } from 'react'
import TorbuMenu from '../../Components/TorbuMenu'
import { HiBell } from 'react-icons/hi2'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import state from '../../Utils/State'
import Icon from '../../Components/Icon'
import usePusher from '../../Hooks/usePusher'

const NotificationMenu = () => {
    const navigate = useNavigate()
    const [selectedChannel, setSelectedChannel] = useRecoilState(state.activeChatChannel)

    const notificationActions = {
        openChannel(channel) {
            setSelectedChannel(channel)
            navigate('/chat')
        },
        openEvent(event) {
            navigate(`/dashboard/${event.id}/view`)
        }
    }

    const [notifications, dispatchNotification] = useRecoilState(state.notifications)

    const { currentUser } = useCurrentUser()

    const channelQuery = useQuery(['getAllChannels'])

    usePusher(subscribePusherEvent => {
        if (channelQuery.data) for (const channel of channelQuery.data.getAllChannels) {
            const channelName = `private-${channel.id}`
            subscribePusherEvent(channelName, 'new-message', message => {
                const div = document.createElement('div')
                div.innerHTML = message.body
                const notification = {
                    key: message.id,
                    title: `New message in #${channel.title}`,
                    body: `${message.user.firstName}: ${div.innerText}`,
                    action: ['openChannel', [channel]]
                }
                dispatchNotification({
                    add: notification
                })
            })
        }
    }, [channelQuery.data])

    return (
        <TorbuMenu>
            <TorbuMenu.Button className='p-1 text-gray-400 bg-torbu-silver rounded-full hover:text-gray-500\
            focus:outline-none ui-open:ring-2 ui-open:ring-torbu-peach ui-open:ring-offset-2'>
                <span className='sr-only'>View notifications</span>
                <HiBell
                    className='w-6 h-6'
                    aria-hidden='true'
                />
            </TorbuMenu.Button>
            <TorbuMenu.Items className={'w-96 right-0 p-4 flex flex-col gap-4'}>
                {notifications.map(n => {
                    return (
                        <TorbuMenu.Item as={'div'} key={n.key} className={'h-12 hover:cursor-pointer grid grid-rows-[1fr_1fr] grid-cols-[1fr_auto] items-center'} onClick={() => {
                            dispatchNotification({
                                delete: n
                            })
                            notificationActions[n.action[0]](...n.action[1])
                        }}>
                            <div className={'row-start-1 col-start-1 font-torbu-heading'}>{n.title}</div>
                            <div className={'row-start-2 col-start-1 font-torbu-content'}>{n.body}</div>
                            <div className={'row-start-1 col-start-2 row-span-2 bg-torbu-orange w-[24px] h-[24px]'} onClick={e => {
                                e.stopPropagation()
                                dispatchNotification({
                                    delete: n
                                })
                            }}>
                                <Icon icon={'Archive'} className={'w-full h-full text-torbu-peach'} />
                            </div>
                        </TorbuMenu.Item>
                    )
                })}
            </TorbuMenu.Items>
        </TorbuMenu>
    )
}

export default NotificationMenu

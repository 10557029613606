import { Box, Divider, Group, Text, Title } from '@mantine/core'
import { isEmpty } from 'lodash'
import React, { useRef, useState } from 'react'
import Moment from 'react-moment'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import ButtonArchive from '../../Components/Buttons/ButtonArchive'
import ButtonLink from '../../Components/Buttons/ButtonLink'
import Can from '../../Helpers/Can'
import StrideLoader from '../../Helpers/StrideLoader'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import EventView from './EventViewTab'
import TorbuSwitch from '../../Components/TorbuSwitch'
import useEventMutation from '../../Hooks/Models/useEventMutation'
import usePermission from "../../Hooks/usePermission"

const ArchiveEventsContent = ({ onArchiveReccurencesSwitchChange, showArchiveReccurencesSwitch }) => {
    const [archiveRecurrences, setArchiveRecurrences] = useState(false)
    return (
        <>
            {(showArchiveReccurencesSwitch &&
                <div className={'flex justify-end my-3'}>
                    <TorbuSwitch
                        checked={archiveRecurrences}
                        onChange={checked => {
                            setArchiveRecurrences(checked)
                            onArchiveReccurencesSwitchChange?.(checked)
                        }}
                        label={'Archive recurring events'}
                    />
                </div>
            )}
        </>
    )
}

const EventViewDrawer = ({ eventId }) => {
    const navigate = useNavigate()
    const [event, setEvent] = useState({})
    const notify = useNotify()
    const { hasEventEdit } = usePermission()

    const getEventGQL = useGQL('getEvent')

    const eventQuery = useQuery([getEventGQL, { id: eventId }], {
        onSettled: data => {
            if (!isEmpty(data) && !isEmpty(data.getEvent)) {
                setEvent(data.getEvent)
            } else {
                setEvent({})
            }
        },
        enabled: !isEmpty(eventId)
    })

    const archiveEventMutation = useEventMutation({
        onSuccess: () => {
            notify.archived('Event')
            window.location.href = '/'
        },
        onError: error => {
            console.error(error)
            notify.error()
        },
        type: 'archive'
    })

    let archiveRecurrences = useRef(false);

    return (
        <>
            <StrideLoader loading={eventQuery.isLoading}>
                <Can create={'event'}>
                    {hasEventEdit(event) && (
                        <Group position={'right'}>
                            <ButtonLink
                                to={`/events/edit/${eventId}`}
                                icon={'Edit'}
                            >
                                Edit Event
                            </ButtonLink>
                        </Group>
                    )}
                </Can>
                <Box>
                    <Text
                        size="sm"
                        fw={'300'}
                    >
                        {event.eventType?.title || 'Event'}
                    </Text>
                    <Text
                        size="xl"
                        fw={'300'}
                    >
                        {event.title}
                    </Text>

                    <Text fw={300}>
                        <Moment format={'dddd MMM Do, h:mma-'}>{event.startTime}</Moment>
                        <Moment format={'h:mma'}>{event.endTime}</Moment>
                    </Text>
                </Box>

                <EventView
                    eventId={eventId}
                    event={event}
                />

                <Can create={'event'}>
                    {hasEventEdit(event) && (
                        <>
                            <Divider my={16} />
                            <Box>
                                <Title order={5}>Archive Event</Title>
                                <Text
                                    fw={300}
                                    size={'sm'}
                                >
                                    Remove this event for your accounts calendar.
                                </Text>
                            </Box>
                            <Group
                                mt="xl"
                                position={'right'}
                            >
                                <ButtonArchive
                                    onConfirm={() => {
                                        archiveEventMutation.mutate({ id: eventId, archiveRecurrences: archiveRecurrences.current })
                                    }}
                                    modalTitle={'Are you sure you want to archive this event?'}
                                    modalContent={
                                        <ArchiveEventsContent
                                            onArchiveReccurencesSwitchChange={checked => {
                                                archiveRecurrences.current = checked
                                            }}
                                            showArchiveReccurencesSwitch={event.eventRecurrenceInfo && !isEmpty(event.eventRecurrenceInfo)}
                                        />
                                    }
                                >Archive Event</ButtonArchive>
                            </Group>
                        </>
                    )}
                </Can>
            </StrideLoader>
        </>
    )
}
export default EventViewDrawer

/* eslint-disable */

import { Tabs } from '@mantine/core'
import { IconAffiliate, IconShieldLock, IconUsers } from '@tabler/icons-react'
import { isUndefined } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Can from '../../../Helpers/Can'
import StrideHeader from '../../../Helpers/StrideHeader'
import StrideLoader from '../../../Helpers/StrideLoader'
import state from '../../../Utils/State'
import EditAccount from '../../AccountSettings/Accounts/EditAccount'
import RolesTable from '../../Roles/RolesTable'
import UserManager from '../../UserManager/UserManager'

export const AccountView = () => {
    const { id } = useParams()
    const [account, setAccount] = useState({})
    const [accountSettingsTab, setAccountSettingsTab] = useRecoilState(state.accountSettingsTab)

    const accountQuery = useQuery(['getAccount', { id: id }], {
        onSettled: data => {
            setAccount(data.getAccount)
        },
        enabled: !isUndefined(id)
    })

    return (
        <>
            <StrideLoader loading={accountQuery.isLoading}>
                <StrideHeader
                    header={'Team Management'}
                    crumbs={[
                        {
                            title: account.name
                        }
                    ]}
                    icon={'Affiliate'}
                    withBorder
                />

                {account && account.id && (
                    <>
                        <Tabs
                            value={accountSettingsTab}
                            onTabChange={setAccountSettingsTab}
                            mt={16}
                        >
                            <Tabs.List>
                                <Can manage={'team'}>
                                    <Tabs.Tab data-intercom-target="Team Tab"
                                        value="account"
                                        icon={<IconAffiliate size="0.8rem" />}
                                    >
                                        Team
                                    </Tabs.Tab>
                                </Can>
                                <Can manage={'team'}>
                                    <Tabs.Tab data-intercom-target="Users Tab"
                                        value="users"
                                        icon={<IconUsers size="0.8rem" />}
                                    >
                                        Users
                                    </Tabs.Tab>
                                </Can>
                                <Can manage={'team'}>
                                    <Tabs.Tab data-intercom-target="Roles and Permissions Tab"
                                        value="roles"
                                        icon={<IconShieldLock size="0.8rem" />}
                                    >
                                        Roles and Permissions
                                    </Tabs.Tab>
                                </Can>
                            </Tabs.List>

                            <Can manage={'team'}>
                                <Tabs.Panel
                                    value="account"
                                    pt="xs"
                                >
                                    <EditAccount account={account} />
                                </Tabs.Panel>
                            </Can>

                            <Can manage={'team'}>
                                <Tabs.Panel
                                    value="users"
                                    pt="xs"
                                >
                                    <UserManager account={account} />
                                </Tabs.Panel>
                            </Can>

                            <Can manage={'team'}>
                                <Tabs.Panel
                                    value="roles"
                                    pt="xs"
                                >
                                    <RolesTable account={account} />
                                </Tabs.Panel>
                            </Can>
                        </Tabs>
                    </>
                )}
            </StrideLoader>
        </>
    )
}
export default AccountView

import { Divider,Group,TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { isNotEmpty,useForm } from '@mantine/form'
import { useNotify } from '../../../Hooks/useNotify'
import React,{ useState } from 'react'
import { useMutation } from 'react-query'
import ButtonEdit from '../../../Components/Buttons/ButtonEdit'
import Icon from '../../../Helpers/Icon'
import useGQL from '../../../Hooks/useGQL'
import graphqlClient from '../../../Utils/graphqlClient'
import queryClient from '../../../Utils/queryClient'

export const AddAccount = ({ setModalOpen }) => {
    const [isSaving, setIsSaving] = useState(false)
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            name: '',
            trialExpiresAt: new Date()
        },
        validate: {
            name: isNotEmpty('Name is required')
        }
    })

    const createAccountGQL = useGQL('createAccount')
    const getAllAccountsGQL = useGQL('getAllAccounts')
    const createAccount = useMutation(data => graphqlClient.request(createAccountGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [getAllAccountsGQL] }).then(() => {
                notify.created('Account')
                setIsSaving(false)
                setModalOpen(false)
            })
        },
        onError: error => {
            setIsSaving(false)
            console.error(error)
            notify.error()
        }
    })

    const save = data => {
        setIsSaving(true)
        createAccount.mutate(data)
    }

    return (
        <>
            <form onSubmit={form.onSubmit(save)}>
                <TextInput
                    label="Account Name"
                    placeholder="What is the name of this primary account?"
                    withAsterisk
                    {...form.getInputProps('name')}
                />
                <DatePickerInput
                    label="Trial Expiration Date"
                    placeholder="What date should this accounts trial expire?"
                    popoverProps={{ withinPortal: true }}
                    {...form.getInputProps('trialExpiresAt')}
                />

                <Divider my={16} />

                <Group
                    position={'right'}
                    mt={15}
                >
                    <ButtonEdit saving={isSaving}>Save Account</ButtonEdit>
                </Group>
            </form>
        </>
    )
}

export default AddAccount

import { useEffect, useState } from "react"
import useAccountSubscription from "./useAccountSubscription"
import { useMutation, useQueryClient } from "react-query"
import graphqlClient from "../../Utils/graphqlClient"
import useGQL from "../useGQL"
import useCurrentAccount from "./useCurrentAccount"

const useCards = () => {
    const { currentAccount } = useCurrentAccount()

    const queryClient = useQueryClient()
    const setAsDefaultGQL = useGQL('setDefaultPaymentMethod')
    const setAsDefaultMutation = useMutation((data) => graphqlClient.request(setAsDefaultGQL, data), {
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['getSubscription']
            })
        }
    })
    const deleteGQL = useGQL('deletePaymentMethod')
    const deleteMutation = useMutation((data) => graphqlClient.request(deleteGQL, data), {
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['getSubscription']
            })
        }
    })

    const [cards, setCards] = useState([])
    const subscription = useAccountSubscription()

    useEffect(() => {
        if (!subscription) setCards([])
        else {
            const cards = []
            for (const card of subscription.paymentMethods) {
                const expirationDate = new Date()
                expirationDate.setMonth(card.expirationMonth)
                expirationDate.setFullYear(card.expirationYear)
                cards.push({
                    id: card.stripePaymentMethodId,
                    type: card.type,
                    billingName: card.billingInfo.name,
                    lastFour: card.lastFour,
                    expires: expirationDate,
                    default: card.default,
                    setAsDefault() {
                        setAsDefaultMutation.mutate({
                            accountId: currentAccount.id,
                            stripePaymentMethodId: this.id
                        })
                        const oldDefaultCardIndex = cards.findIndex(e => e.default)
                        const oldDefaultCard = { ...cards[oldDefaultCardIndex] }
                        oldDefaultCard.default = false
                        const newDefaultCardIndex = cards.findIndex(e => e.id === this.id)
                        const newDefaultCard = { ...this }
                        newDefaultCard.default = true
                        const newCards = [...cards]
                        newCards[oldDefaultCardIndex] = oldDefaultCard
                        newCards[newDefaultCardIndex] = newDefaultCard
                        setCards(newCards)
                    },
                    delete() {
                        deleteMutation.mutate({
                            accountId: currentAccount.id,
                            stripePaymentMethodId: this.id
                        })
                        const newCards = [...cards]
                        newCards.splice(cards.findIndex(e => e.id === this.id), 1)
                        setCards(newCards)
                    }
                })
            }
            setCards(cards)
        }
    }, [subscription])

    return cards
}

export default useCards

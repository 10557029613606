import React from "react";
import { HiXCircle } from "react-icons/hi";

export const ErrorHandler = ({ errors }) => {
    if (!Object.keys(errors).length || !errors.errors.length) {
        return null
    }

    return (
        <>
            <div className="p-4 mb-8 bg-red-200 border border-red-400 rounded-md">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <HiXCircle
                            className="w-5 h-5 text-red-600"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{errors.title ? <>{errors.title}</> : <>There were {errors.errors.length} errors with your submission</>}</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul
                                role="list"
                                className="pl-5 space-y-1 list-disc font-torbu-content"
                            >
                                {errors.errors.map(error => (
                                    <li key={String(error)}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

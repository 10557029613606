import { Box, Group, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import ButtonCreate from '../../../Components/Buttons/ButtonCreate'
import Icon from '../../../Helpers/Icon'
import StrideLoader from '../../../Helpers/StrideLoader'
import useAccounts from '../../../Hooks/Models/useAccounts'
import useGQL from '../../../Hooks/useGQL'
import { useNotify } from '../../../Hooks/useNotify'
import usePermission from '../../../Hooks/usePermission'
import graphqlClient from '../../../Utils/graphqlClient'
import useCurrentUser from '../../../Hooks/Models/useCurrentUser'

const AddNewAccount = ({ parentAccountId, handleModalClose }) => {
    const { hasPermissionAnywhere } = usePermission()
    const { currentUser, resetCurrentUser, onUserUpdate } = useCurrentUser()
    const accountModel = useAccounts()
    const navigate = useNavigate()
    const notify = useNotify()
    const form = useForm({
        initialValues: {
            name: ''
        },
        validate: {
            name: isNotEmpty('Name is required')
        }
    })

    useEffect(() => {
        if (parentAccountId) form.setFieldValue('parentAccountId', parentAccountId)
    }, [parentAccountId])

    const [isSaving, setIsSaving] = useState(false)

    
    const createAccountGQL = useGQL('createAccount')
    const createAccountMutation = useMutation(data => graphqlClient.request(createAccountGQL, data), {
        onSuccess: data => {
            accountModel.invalidate().then(() => {
                notify.created('Team')
                onUserUpdate((user) => {navigate(`/account-manager/accounts/${data.createAccount.id}`)})
                resetCurrentUser()
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const addNewAccount = data => {
        setIsSaving(true)
        if (hasPermissionAnywhere('team:manage')) {
            createAccountMutation.mutate(data)
        }
    }

    return (
        <>
            <StrideLoader
                loading={isSaving}
                label={'Creating new team...'}
            >
                <form onSubmit={form.onSubmit(addNewAccount)}>
                    <Box my={16}>
                        <TextInput
                            label={'Team name'}
                            placeholder={'What would you like to call the new team?'}
                            required
                            {...form.getInputProps('name')}
                        />
                    </Box>

                    <Group position={'right'}>
                        <ButtonCreate type="submit">Create new team</ButtonCreate>
                    </Group>
                </form>
            </StrideLoader>
        </>
    )
}

export default AddNewAccount

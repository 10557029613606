/* eslint-disable */

import { Box,Divider,Group,Image,Paper,Popover,Text,Tooltip } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import EmojiPicker,{ Emoji } from 'emoji-picker-react'
import { find,isEmpty } from 'lodash'
import React,{ useState } from 'react'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Icon from '../../Helpers/Icon'
import TorbuEditor from '../../Helpers/TorbuEditor'

const fileTypeMapping = [
    {
        fileType: 'image/png',
        type: 'image',
        name: 'Image',
        icon: 'Photo'
    },
    {
        fileType: 'image/gif',
        type: 'image',
        name: 'Image',
        icon: 'Photo'
    },
    {
        fileType: 'image/jpeg',
        type: 'image',
        name: 'Image',
        icon: 'Photo'
    },
    {
        fileType: 'image/svg+xml',
        type: 'image',
        name: 'Image',
        icon: 'Photo'
    },
    {
        fileType: 'image/webp',
        type: 'image',
        name: 'Image',
        icon: 'Photo'
    },
    {
        fileType: 'image/avif',
        type: 'image',
        name: 'Image',
        icon: 'Photo'
    },
    {
        fileType: 'video/mp4',
        type: 'video',
        name: 'Video',
        icon: 'Movie'
    },
    {
        fileType: 'application/zip',
        type: 'file',
        name: 'Zip',
        icon: 'FileZip'
    },
    {
        fileType: 'text/csv',
        type: 'csv',
        name: 'CSV',
        icon: 'FileCode2'
    },
    {
        fileType: 'application/pdf',
        type: 'pdf',
        name: 'PDF',
        icon: 'FileText'
    },

    {
        fileType: 'application/msword',
        type: 'doc',
        name: 'Word',
        icon: 'FileText'
    },

    {
        fileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        type: 'doc',
        name: 'Document',
        icon: 'FileText'
    },

    {
        fileType: 'application/vnd.ms-excel',
        type: 'excel',
        name: 'Excel',
        icon: 'Table'
    },
    {
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        type: 'excel',
        name: 'Spreadsheet',
        icon: 'Table'
    },
    {
        fileType: 'application/vnd.ms-powerpoint',
        type: 'ppt',
        name: 'Powerpoint',
        icon: 'Presentation'
    },
    {
        fileType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        type: 'ppt',
        name: 'Powerpoint',
        icon: 'Presentation'
    }
]

export const ChatEditor = ({ editor, onSave, isEditing }) => {
    if (!editor) {
        return <></>
    }

    const [emojiMenu, setEmojiMenu] = useState(false)
    const [files, setFiles] = useState([])

    const handleEmoji = emojiData => {
        editor?.editor?.commands?.setImage({ src: emojiData.getImageUrl() })
        setEmojiMenu(false)
    }

    const handleFileUpload = files => {
        files.forEach(file => {
            const fileData = {
                key: file.lastModified,
                fileType: file.type,
                name: file.name,
                url: '',
                file: file
            }

            if (file.type.includes('image')) {
                fileData.url = URL.createObjectURL(file)
            }

            setFiles(oldFiles => [...oldFiles, fileData])
        })
    }

    const removeFile = index => {
        setFiles(oldFiles => oldFiles.filter((_, idx) => idx !== index))
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter' && e.shiftKey && !(isEmpty(editor.contents) && files.length === 0)) {
            onSave(false)
        }
    }

    return (
        <>
            <Dropzone.FullScreen
                active={false}
                onDrop={handleFileUpload}
                onReject={files => console.log('rejected files', files)}
                maxSize={15728640}
            >
                <Group
                    position="center"
                    spacing="xl"
                    style={{ minHeight: 220, pointerEvents: 'none' }}
                >
                    <Dropzone.Accept>
                        <Icon
                            icon={'Upload'}
                            size={50}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <Icon
                            icon={'X'}
                            size={50}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <Icon
                            icon={'FileText'}
                            size={50}
                            stroke={1.5}
                        />
                    </Dropzone.Idle>

                    <Box>
                        <Text
                            size="xl"
                            inline
                        >
                            Drag media here or click to select files
                        </Text>
                        <Text
                            size="sm"
                            color="dimmed"
                            inline
                            mt={7}
                        >
                            Attach as many files as you like, each file should not exceed 5mb
                        </Text>
                    </Box>
                </Group>
            </Dropzone.FullScreen>

            <Box onKeyUp={handleKeyPress}>
                <TorbuEditor editor={editor}>
                    {files.length > 0 && (
                        <>
                            <Box px={8}>
                                <Group>
                                    {files.map((file, index) => {
                                        const fileMap = find(fileTypeMapping, fileMap => fileMap.fileType === file.fileType)
                                        return (
                                            <Box
                                                component={'span'}
                                                key={file.key}
                                                style={{ position: 'relative' }}
                                                className={'chat-message-file pointer'}
                                            >
                                                <Tooltip
                                                    label={'Remove File'}
                                                    position={'top'}
                                                    withArrow
                                                    withinPortal
                                                >
                                                    <Box>
                                                        <ButtonPrimary
                                                            style={{ position: 'absolute', top: '-10px', right: '-10px', zIndex: '1', display: 'none' }}
                                                            onClick={() => removeFile(index)}
                                                            className={'chat-message-file-remove'}
                                                        >
                                                            <Icon
                                                                icon={'X'}
                                                                color={'white'}
                                                                size={18}
                                                            />
                                                        </ButtonPrimary>
                                                    </Box>
                                                </Tooltip>

                                                {file.url ? (
                                                    <>
                                                        <Tooltip
                                                            label={file.name}
                                                            position={'top'}
                                                            withArrow
                                                            withinPortal
                                                        >
                                                            <Image
                                                                width={65}
                                                                height={65}
                                                                fit="contain"
                                                                src={file.url}
                                                                imageProps={{ onLoad: () => URL.revokeObjectURL(file.url) }}
                                                                radius={8}
                                                                style={{ border: '1px solid #999999', borderRadius: '8px' }}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            label={file.name}
                                                            position={'top'}
                                                            withArrow
                                                            withinPortal
                                                        >
                                                            <Paper
                                                                h={65}
                                                                w={180}
                                                                withBorder
                                                                bg={'#F6F7F8'}
                                                                p={8}
                                                                style={{ overflow: 'hidden' }}
                                                            >
                                                                <Group
                                                                    noWrap
                                                                    spacing={4}
                                                                    align
                                                                >
                                                                    <Box>
                                                                        <Icon
                                                                            icon={fileMap.icon}
                                                                            size={50}
                                                                        />
                                                                    </Box>
                                                                    <Box w={95}>
                                                                        <Text
                                                                            truncate
                                                                            lineClamp={1}
                                                                        >
                                                                            {file.name}
                                                                        </Text>
                                                                        <Text
                                                                            size={'xs'}
                                                                            truncate
                                                                            lineClamp={1}
                                                                        >
                                                                            {fileMap.name}
                                                                        </Text>
                                                                    </Box>
                                                                </Group>
                                                            </Paper>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </Box>
                                        )
                                    })}
                                </Group>
                            </Box>
                        </>
                    )}

                    <Divider my={8} />
                    <Group position="apart">
                        <Box
                            className={'pointer'}
                            ml={8}
                            mt={-8}
                        >
                            <Popover
                                opened={emojiMenu}
                                onChange={setEmojiMenu}
                                position="top"
                                withArrow
                                radius={8}
                            >
                                <Popover.Target>
                                    <Box onClick={() => setEmojiMenu(true)}>
                                        <Emoji
                                            unified={'1f600'}
                                            size={32}
                                        />
                                    </Box>
                                </Popover.Target>
                                <Popover.Dropdown
                                    m={0}
                                    p={0}
                                >
                                    <EmojiPicker
                                        lazyLoadEmojis={true}
                                        onEmojiClick={handleEmoji}
                                    />
                                </Popover.Dropdown>
                            </Popover>
                        </Box>
                        <Box
                            mb={8}
                            mr={8}
                        >
                            <Group position="right">
                                {isEditing ? (
                                    <>
                                        <ButtonPrimary
                                            disabled={isEmpty(editor.contents)}
                                            onClick={() => onSave(true)}
                                        >
                                            Cancel Editing
                                        </ButtonPrimary>

                                        <ButtonPrimary
                                            disabled={isEmpty(editor.contents)}
                                            onClick={() => onSave(false)}
                                        >
                                            Update Message
                                        </ButtonPrimary>
                                    </>
                                ) : (
                                    <>
                                        <ButtonPrimary
                                            disabled={isEmpty(editor.contents) && files.length === 0}
                                            onClick={() => onSave(false)}
                                            icon={'Send'}
                                        >
                                            Send Message
                                        </ButtonPrimary>
                                    </>
                                )}
                            </Group>
                        </Box>
                    </Group>
                </TorbuEditor>
            </Box>
        </>
    )
}
export default ChatEditor

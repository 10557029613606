/* eslint-disable */
import {sortBy} from "lodash";
import queryClient from "../../Utils/queryClient"
import useGetQuery from "../useGetQuery"

const useAccounts = (variables={}) => {
    const query = useGetQuery(
        'getAllAccounts',
        variables,
        'id',
        (data) => {
            return sortBy(data, account => account.name.toLowerCase(), ['name'])
        },
        {}
    )

    const invalidate = () => {
        return queryClient.invalidateQueries({ queryKey: ['getAllAccounts', variables]})
    }

    return {
        data: query.data || [],
        invalidate: invalidate,
        isLoading: query.isLoading,
        isSuccess: query.isSuccess
    }
}
export default useAccounts
import { ActionIcon, Badge, Box, CopyButton, Divider, Group, HoverCard, Paper, ScrollArea, Text, Tooltip } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { filter, isEmpty, sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Can from "../../Helpers/Can"
import Icon from "../../Helpers/Icon"
import StrideAvatar from "../../Helpers/StrideAvatar"
import StrideLoader from '../../Helpers/StrideLoader'
import StrideTable from '../../Helpers/StrideTable'
import usePermission from "../../Hooks/usePermission"
import RolesDrawer from './RoleDrawer'

export const RolesTable = ({ account }) => {
    const { hasPermissionAnywhere } = usePermission()

    const [roleDrawer, setRoleDrawer] = useState({})
    const [permissions, setPermissions] = useState([])
    const [roles, setRoles] = useState([])

    const permissionsQuery = useQuery(['getAllPermissionsV2'], {
        onSettled: (data) => {
            if (data.getAllPermissionsV2?.length) {
                let permissionCategories = []
                let permissionsTree = []

            
                data.getAllPermissionsV2.forEach((permission) => {
                    const category = permission.category;
                    if (!permissionCategories.includes(category)) {
                        permissionCategories.push(category);
                    }
                });

                permissionCategories.forEach((category) => {
                    permissionsTree.push({
                        name: category,
                        permissions: [],
                    })
                })

                permissionsTree.forEach((permission) => {
                    permission.permissions = sortBy(filter(data.getAllPermissionsV2, availablePermission => availablePermission.category === permission.name), "name")
                })

                setPermissions(permissionsTree)
            }
        }
    })

    const rolesQuery = useQuery(['getAllRolesV2', { accountId: account.id }], {
        onSettled: data => {
            if (data.getAllRolesV2?.length) {
                setRoles(sortBy(data.getAllRolesV2, 'name'))
            }
        },
        enabled: !isEmpty(account)
    })


    const columns = useMemo(() => {
        return [
            {
                id: 'name',
                name: 'Role',
                selector: row => row.name,
                sortable: true,
                cell: row => row.name
            },
            {
                id: 'users',
                name: 'Users',
                cell: row => (
                    <>
                        {row.accountUsers.length ? (
                            <>
                                <HoverCard
                                    withinPortal
                                    width={280}
                                    shadow="md"
                                    position={'bottom'}
                                >
                                    <HoverCard.Target>
                                        <Box>
                                            <Group spacing={6}>
                                                <Text td="underline">{row.accountUsers.length} user(s) assigned</Text>
                                                <IconSearch
                                                    mt={10}
                                                    size={'.9rem'}
                                                />
                                            </Group>
                                        </Box>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Box>
                                            <Text size="sm">Users assigned to this role:</Text>
                                            <Divider my={'sm'} />
                                            <ScrollArea.Autosize mah={250}>
                                                {sortBy(row.accountUsers, user => user.user.firstName.toLowerCase(), ['firstName', 'lastName'])
                                                    .slice(0, Math.min(24, row.accountUsers.length))
                                                    .map(user => (
                                                        <Group
                                                            key={user.user.id}
                                                            spacing={6}
                                                            mb={'xs'}
                                                        >
                                                            <StrideAvatar
                                                                user={user.user}
                                                                size={28}
                                                            />
                                                            <Text size="xs">
                                                                {user.user.firstName} {user.user.lastName}
                                                            </Text>
                                                        </Group>
                                                    ))}
                                            </ScrollArea.Autosize>
                                            {row.accountUsers.length > 25 && (
                                                <>
                                                    <Divider my={'xs'} />
                                                    <Text size="xs">+{row.accountUsers.length - 25} other users</Text>
                                                </>
                                            )}
                                        </Box>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </>
                        ) : (
                            <>No users assigned</>
                        )}
                    </>
                )
            },
            {
                id: 'permissions',
                name: 'Permissions',
                cell: row => (
                    <>
                        {row.permissions.length ? (
                            <>
                                <HoverCard
                                    withinPortal
                                    width={280}
                                    shadow="md"
                                    position={'bottom'}
                                >
                                    <HoverCard.Target>
                                        <Box>
                                            <Group spacing={6}>
                                                <Text td="underline">{row.permissions.length} permission(s) assigned</Text>
                                                <IconSearch
                                                    mt={10}
                                                    size={'.9rem'}
                                                />
                                            </Group>
                                        </Box>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Box>
                                            <Text size="sm">Permissions assigned to this role:</Text>
                                            <Divider my={'sm'} />
                                            <ScrollArea.Autosize mah={250}>
                                                {sortBy(row.permissions, permission => permission.name.toLowerCase(), ['name']).map(permission => (
                                                    <Text
                                                        key={permission.id}
                                                        size="xs"
                                                        mb={4}
                                                    >
                                                        {permission.name}
                                                    </Text>
                                                ))}
                                            </ScrollArea.Autosize>
                                        </Box>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </>
                        ) : (
                            <>No permissions assigned</>
                        )}
                    </>
                )
            },
            {
                id: 'code',
                name: 'Invite Code(s)',
                cell: row => (
                    <>
                        {row.inviteCodes.map(inviteCode => (
                            <Box key={inviteCode.code + row.id}>
                                <HoverCard
                                    width={280}
                                    shadow="md"
                                    position={'bottom'}
                                    withinPortal
                                >
                                    <HoverCard.Target>
                                        <Badge
                                            variant="filled"
                                            mr={8}
                                        >
                                            <Text>{inviteCode.code}</Text>
                                        </Badge>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Text size={'sm'}>Torbu users have the ability to self register with an invite code.</Text>
                                        <Text
                                            size={'sm'}
                                            mt={'xs'}
                                        >
                                            Give this invite code to the user with instructions to go to https://app.torbu.com/register where they will be able to enter this code.
                                        </Text>
                                        <Paper
                                            withBorder
                                            p={'md'}
                                            bg={'#F6F7F8'}
                                            mt={16}
                                        >
                                            <Group position={'center'}>
                                                <Text size={'xl'}>{inviteCode.code}</Text>
                                                <CopyButton value={inviteCode.code}>
                                                    {({ copied, copy }) => (
                                                        <Tooltip
                                                            label={copied ? 'Copied' : 'Copy Code'}
                                                            withArrow
                                                            position="right"
                                                        >
                                                            <ActionIcon
                                                                size={'xl'}
                                                                color={copied ? 'teal' : 'gray'}
                                                                onClick={copy}
                                                            >
                                                                {copied ? (
                                                                    <Icon
                                                                        icon={'Check'}
                                                                        size="2rem"
                                                                    />
                                                                ) : (
                                                                    <Icon
                                                                        icon={'Copy'}
                                                                        size="2rem"
                                                                    />
                                                                )}
                                                            </ActionIcon>
                                                        </Tooltip>
                                                    )}
                                                </CopyButton>
                                            </Group>
                                        </Paper>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </Box>
                        ))}
                    </>
                )
            },
            {
                id: 'manage',
                name: '',
                cell: row => {
                    return (
                        <>
                            {row.isParentRole && (
                                <Can manage={'team'}>
                                    <ButtonPrimary data-intercom-target="Manage Role Button" onClick={() => handleRowClick(row)}>Manage</ButtonPrimary>
                                </Can>
                            )}
                        </>
                    )
                },
                button: true
            }
        ]
    }, [])

    const handleRowClick = (row) => {
        if (hasPermissionAnywhere('team:manage')) {
            setRoleDrawer({ type: 'edit', role: row })
        }
    }

    return (
        <>
            <StrideLoader loading={permissionsQuery.isLoading || rolesQuery.isLoading}>
                <Box>
                    <Group
                        position="apart"
                        mb={25}
                    >
                        <Box>
                            <Text
                                color={'#3b3b3b'}
                                fw={'bold'}
                            >
                                Roles and Permissions
                            </Text>
                            <Text
                                color={'#777777'}
                                fw={300}
                                size={'xs'}
                                data-intercom-target="Roles and Permissions Description Text"
                            >
                                Manage your account access around the Torbu Platform.
                            </Text>
                        </Box>
                        <Can manage={'team'}>
                            <ButtonCreate data-intercom-target="Add Role Button" onClick={() => setRoleDrawer({ type: 'new', role: {} })}>Add Role</ButtonCreate>
                        </Can>
                    </Group>

                    <Paper withBorder>
                        <StrideTable
                            columns={columns}
                            data={roles}
                            onClick={handleRowClick}
                            searchFilter={(data, filter) => {
                                return data.filter(role => {
                                    return role.name.toLowerCase().includes(filter)
                                })
                            }}
                        />
                    </Paper>
                </Box>

                <RolesDrawer
                    roleDrawer={roleDrawer}
                    setRoleDrawer={setRoleDrawer}
                    permissions={permissions}
                    account={account}
                />
            </StrideLoader>
        </>
    )
}
export default RolesTable


import { Box, Grid, Group, Loader, Paper, Table, Text } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, MS_WORD_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone'
import { isEmpty, sortBy, uniqBy } from 'lodash'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Icon from '../../Helpers/Icon'
import StrideHeader from '../../Helpers/StrideHeader'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import graphqlClientUpload from '../../Utils/graphqlClientUpload'
import queryClient from '../../Utils/queryClient'

export const EventMedia = ({ event }) => {
    const [files, setFiles] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const notify = useNotify()

    const uploadFilesGQL = useGQL('uploadFiles')

    const getFilesKey = ['getAllFiles', { entityIds: [event.id] }]

    useQuery(getFilesKey, {
        onSettled: data => {
            if (data.getAllFiles?.length) {
                setFiles(uniqBy(sortBy(data.getAllFiles, ['name', 'id']), 'id'))
            } else {
                setFiles([])
            }
        },
        enabled: !isEmpty(event)
    })

    const uploadFilesMutation = useMutation(data => graphqlClientUpload.request(uploadFilesGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: getFilesKey }).then(() => {
                notify.uploaded('Files')
                setIsUploading(false)
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
            setIsUploading(false)
        }
    })

    const handleFileUpload = files => {
        setIsUploading(true)
        const uploads = {
            entityId: event.id,
            files: files
        }
        uploadFilesMutation.mutate(uploads)
    }

    const handleFileUploadErrors = files => {
        setIsUploading(false)
        console.log('rejected files', files)
    }

    return (
        <>
            <StrideHeader
                header={'Event Manager'}
                crumbs={[
                    {
                        title: 'Media'
                    }
                ]}
                icon={'Calendar'}
                withBorder
            />
            <Grid>
                <Grid.Col span={6}>
                    <Box>
                        <Paper
                            withBorder
                            p={'md'}
                            bg={'#F6F7F8'}
                        >
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Media</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.length ? (
                                        <>
                                            {files.map(file => (
                                                <tr key={file.id}>
                                                    <td>
                                                        <Link
                                                            to={file.url}
                                                            target={'_blank'}
                                                        >
                                                            {file.name}
                                                        </Link>
                                                    </td>
                                                    <td>{file.contentType}</td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={2}
                                                align={'center'}
                                            >
                                                No media added for this event.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Paper>
                    </Box>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Paper
                        withBorder
                        p={'md'}
                        bg={'#F6F7F8'}
                    >
                        {isUploading ? (
                            <>
                                <Box
                                    ta={'center'}
                                    py={100}
                                >
                                    <Loader
                                        variant="bars"
                                        size={30}
                                    />
                                    <Text size={'xl'}>Uploading Files...</Text>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Dropzone
                                    onDrop={handleFileUpload}
                                    onReject={handleFileUploadErrors}
                                    maxSize={30 * 1024 ** 2}
                                    accept={[IMAGE_MIME_TYPE, PDF_MIME_TYPE, MS_WORD_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, 'text/csv', 'video/mp4']}
                                >
                                    <Group
                                        position="center"
                                        spacing="xl"
                                        style={{ minHeight: 220, pointerEvents: 'none' }}
                                    >
                                        <Dropzone.Accept>
                                            <Icon
                                                icon={'Upload'}
                                                size={50}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <Icon
                                                icon={'X'}
                                                size={50}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <Icon
                                                icon={'FileText'}
                                                size={50}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Idle>

                                        <Box>
                                            <Text
                                                size="xl"
                                                inline
                                            >
                                                Drag media here or click to select files
                                            </Text>
                                            <Text
                                                size="sm"
                                                color="dimmed"
                                                inline
                                                mt={7}
                                            >
                                                Attach as many files as you like, each file should not exceed 30mb
                                            </Text>

                                            <Text
                                                size="sm"
                                                color="dimmed"
                                                inline
                                                mt={32}
                                                fw={'bold'}
                                                className="david-test"
                                            >
                                                Allowed file types:
                                            </Text>

                                            <Text
                                                size="sm"
                                                color="dimmed"
                                                inline
                                                mt={7}
                                                className="david-test"
                                            >
                                                Images: PNG, GIF, JPEG, JPG, SVG, WEBP, AVIF
                                            </Text>

                                            <Text
                                                size="sm"
                                                color="dimmed"
                                                inline
                                                mt={7}
                                            >
                                                Other Files: CSV, PDF, DOC, DOCX, PPT, PPTX, MP4
                                            </Text>
                                        </Box>
                                    </Group>
                                </Dropzone>
                            </>
                        )}
                    </Paper>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default EventMedia

import * as Icons from '@tabler/icons-react'
import React, { useMemo } from 'react'
import resolvedTailwindConfig from '../Utils/resolvedTailwindConfig'

export const Icon = ({ icon, themeColor, ...other }) => {
    if (icon === null || icon === undefined) {
        return null
    }

    if (!icon.startsWith('Icon')) {
        icon = `Icon${icon}`
    }

    const IconComponent = useMemo(() => {
        return Icons[icon]
    }, [icon])

    if (IconComponent === null || IconComponent === undefined) {
        return null
    }

    const color = resolvedTailwindConfig.theme.colors[themeColor]

    return (
        <IconComponent
            size={24}
            stroke={1.5}
            color={color}
            {...other}
        />
    )
}

export default Icon

import { Alert,Box,Group,Loader,PasswordInput,Text,TextInput,Title } from '@mantine/core'
import { GoogleLogin,useGoogleLogin,useGoogleOneTapLogin } from '@react-oauth/google'
import { IconAlertCircle,IconBrandGoogle } from '@tabler/icons-react'
import React,{ useEffect,useState } from 'react'
import { useForm } from 'react-hook-form'
import { FcGoogle } from "react-icons/fc"
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Icon from '../../../Helpers/Icon'
import useCurrentAccount from '../../../Hooks/Models/useCurrentAccount'
import useCurrentUser from '../../../Hooks/Models/useCurrentUser'
import state from '../../../Utils/State'
import graphqlClient from '../../../Utils/graphqlClient'


const Login = ({ page, setPage, errorMessage, setErrorMessage, sharedData, setSharedData }) => {
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: '',
            password: ''
        }
    })

    const [loading, setLoading] = useState(false)
    const [sessionId, setSessionId] = useRecoilState(state.sessionId)
    const { setCurrentUser } = useCurrentUser()
    const { setCurrentAccount } = useCurrentAccount()

    useQuery(['getCurrentUser'], {
        onSuccess: data => {
            if (data?.getCurrentUser && data.getCurrentUser.id) {
                setCurrentUser(data.getCurrentUser)
                setCurrentAccount({})
                window.location.reload(false)
            } else {
                console.log(data)
                setLoading(false)
                setSessionId(null)
                setErrorMessage({ errors: ['There was an issue attempting to sign in'] })
            }

        },
        onError: error => {
            setLoading(false)
            setSessionId(null)
            setErrorMessage({ errors: ['There was an issue attempting to sign in'] })
            console.log(error)
        },
        enabled: !!sessionId
    })

    const authenticateWithAPI = data => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Stride-Auth': 'web'
            },
            body: JSON.stringify(data)
        }

        fetch(`${process.env.REACT_APP_STRIDE_API_ENDPOINT}/sign-in`, requestOptions)
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setLoading(false)
                    setErrorMessage({ errors: [response.error.message] })
                } else {
                    setErrorMessage({})
                    if (response && response.session && response.session.id) {
                        graphqlClient.setHeader('Stride-Web-Token', response.session.id)
                        setSessionId(response.session.id)
                    }
                }
            })
            .catch(error => {
                setLoading(false)
                setErrorMessage({ errors: ['We could not locate an account with your credentials. Please try again.'] })
            })
    }

    const onLoginSubmit = data => {
        setErrorMessage({})
        setLoading(true)

        if (data && data.email.length && data.password.length) {
            setErrorMessage({})
            authenticateWithAPI({
                email: data.email?.trim(),
                password: data.password?.trim()
            })
        } else {
            setErrorMessage({ errors: ['Email is required', 'Password is required'] })
        }
    }

    const googleSuccess = async (data) => {
        setLoading(true)
        if (data && data.credential) {
            setErrorMessage({})
            authenticateWithAPI({
                ssoVendor: 'GOOGLE',
                ssoToken: data.credential
            })
        } else {
            setLoading(false)
            setErrorMessage({ title: 'There was an issue attempting to login with Google. Please try again.', errors: ['Invalid Login']})
        }
    }

    const googleError = (error) => {
        setLoading(false)
        setErrorMessage({})
    }

    return (
        <>
            <h1 className="text-4xl tracking-tight font-torbu-heading text-torbu-green ">Welcome</h1>
            <div className="pb-4 text-sm font-light text-gray-500 sm:text-md ">
                Sign into your account or{' '}
                <a
                    href="#"
                    onClick={() => setPage('registerSelect')}
                    className="font-medium text-torbu-peach hover:underline"
                >
                    Create an Account
                </a>
            </div>

            {loading ? (
                <>
                    <div className="flex flex-col w-full p-8 text-center border rounded-lg h-15 border-torbu-gray bg-torbu-white">
                        <div className="mx-auto mb-4">
                            <Icon
                                icon={'Loader'}
                                size={40}
                                className="animate-spin-slow"
                            />
                        </div>
                        <div>Attempting to log you in...</div>
                    </div>
                </>
            ) : (
                <>
                    <form
                        className="space-y-6"
                        onSubmit={handleSubmit(onLoginSubmit)}
                    >
                        <div className="grid gap-6 sm:grid-cols-2">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900   focus:outline-torbu-green  sm:text-sm"
                                    placeholder="name@company.com"
                                    {...register('email', { required: true })}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="••••••••"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:outline-torbu-green sm:text-sm"
                                    {...register('password', { required: true })}
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg border-2 border-torbu-peach bg-torbu-white px-5 py-2.5 text-center font-torbu-demi-bold text-sm text-torbu-green hover:bg-torbu-peach hover:text-torbu-white focus:outline-none focus:ring-4 focus:ring-torbu-peach dark:bg-torbu-peach"
                        >
                            Sign in to your account
                        </button>
                    </form>

                    <div className="flex items-center">
                        <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
                        <div className="px-5 text-center text-gray-500 dark:text-gray-400">or</div>
                        <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>

                    <div className="h-9 w-full overflow-hidden rounded-lg border border-torbu-green bg-torbu-white sm:w-[402px]">
                        <div className="hidden sm:block">
                            <GoogleLogin
                                useOneTap={false}
                                type="standard"
                                theme="outline"
                                size="medium"
                                text="signin_with"
                                width={400}
                                auto_select={false}
                                onSuccess={googleSuccess}
                                onError={googleError}
                                className="w-[400px] rounded-lg"
                            />
                        </div>
                        <div className="bg-torbu-white sm:hidden">
                            <GoogleLogin
                                ux_mode="redirect"
                                useOneTap={false}
                                type="standard"
                                theme="outline"
                                size="medium"
                                text="signin_with"
                                auto_select={false}
                                onSuccess={googleSuccess}
                                onError={googleError}
                                className="w-full rounded-lg"
                            />
                        </div>
                    </div>
                </>
            )}

            <div className="pt-4">
                <div className="flex items-center justify-between gap-8">
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Don&apos;t have an account?{' '}
                        <a
                            href="#"
                            onClick={() => {
                                setErrorMessage({})
                                setPage('registerSelect')
                            }}
                            className="dark:text-primary-500 text-torbu-peach hover:underline"
                        >
                            Sign up
                        </a>
                    </p>

                    <a
                        href="#"
                        onClick={() => {
                            setErrorMessage({})
                            setPage('forgot')
                        }}
                        className="text-sm text-right text-primary-600 dark:text-primary-500 hover:underline"
                    >
                        Forgot password?
                    </a>
                </div>
            </div>
        </>
    )
}

export default Login

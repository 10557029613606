import React from 'react'
import { RichTextEditor } from '@mantine/tiptap'
import {ScrollArea} from '@mantine/core'
import {isUndefined} from 'lodash'
import {Box} from '@mantine/core'

export const TorbuEditor = ({editor, readOnly, children}) => {
    if(!editor){
        return <></>
    }

    if(!isUndefined(readOnly)){
        return (
            <RichTextEditor className={'readonly-message'} editor={editor.editor} withCodeHighlightStyles withTypographyStyles>
                <RichTextEditor.Content />
            </RichTextEditor>
        )
    }

    return (
        <Box bg={'white'}>
            <RichTextEditor editor={editor.editor} withCodeHighlightStyles withTypographyStyles>
                <RichTextEditor.Toolbar>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.Underline />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.OrderedList />
                        <RichTextEditor.BulletList />
                        <RichTextEditor.Blockquote />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.CodeBlock />
                    </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <ScrollArea.Autosize mah={600} >
                    <RichTextEditor.Content />
                </ScrollArea.Autosize>

                {children}
            </RichTextEditor>
        </Box>
    )
}

export default TorbuEditor
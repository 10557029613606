import { Grid, Text } from '@mantine/core'
import React from 'react'
import { Card } from '../../Components/Card'
import { Header } from '../../Components/Header'
import ChangeAvatar from './ChangeAvatar'
import ChangePassword from './ChangePassword'
import ChangeUserInfo from './ChangeUserInfo'

export const Profile = () => {
    return (
        <>
            <Header
                text={'Profile'}
                icon={'User'}
            />

            <Card>
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Personal Information</Text>
                        <Text
                            color="dimmed"
                            size="xs"
                        >
                            You can change your name and profile photo.
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <ChangeUserInfo />
                    </Grid.Col>
                </Grid>
            </Card>

            <Card>
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Your Avatar</Text>
                        <Text
                            color="dimmed"
                            size="xs"
                        >
                            Let your avatar express who you are, upload your own avatar.
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <ChangeAvatar />
                    </Grid.Col>
                </Grid>
            </Card>

            <Card>
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Change Password</Text>
                        <Text
                            color="dimmed"
                            size="xs"
                        >
                            After a successful password update, you will be redirected to the login page where you can log in with your new password.
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <ChangePassword />
                    </Grid.Col>
                </Grid>
            </Card>
        </>
    )
}
export default Profile

import { Box,Group,HoverCard,Popover,Text } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import { useNotify } from '../../Hooks/useNotify'
import React,{ useState } from 'react'
import Moment from 'react-moment'
import { useMutation } from 'react-query'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import { ContactCard } from '../../Components/ContactCard'
import Icon from '../../Helpers/Icon'
import IconText from '../../Helpers/IconText'
import StrideAvatar from '../../Helpers/StrideAvatar'
import TorbuEditor from '../../Helpers/TorbuEditor'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import useGQL from '../../Hooks/useGQL'
import usePermission from '../../Hooks/usePermission'
import useTorbuEditor from '../../Hooks/useTorbuEditor'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'
import ChatEditor from './ChatEditor'
export const Message = ({ message }) => {
    const { currentUser } = useCurrentUser()
    const { hasPermissionAnywhere, hasMessageDelete } = usePermission()
    const notify = useNotify()

    const torbuEditorReadOnly = useTorbuEditor({ editable: false, defaultContents: message.body })
    const torbuEditorEditOnly = useTorbuEditor({ defaultContents: message.body })

    const [isEditing, setIsEditing] = useState(false)

    const updateMessageQuery = useGQL('updateMessage')
    const archiveMessageGQL = useGQL('archiveMessage')

    const archiveMessageMutation = useMutation(data => graphqlClient.request(archiveMessageGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllMessages'] }).then(() => {
                notify.archived('Message')
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const archiveMessage = () => {
        openConfirmModal({
            title: 'Confirm',
            children: <Text size="sm">Are you sure you want to delete this message?</Text>,
            labels: { confirm: 'Confirm', cancel: 'Cancel' },
            onConfirm: () => {
                archiveMessageMutation.mutate({ id: message.id })
            }
        })
    }

    const updateMessage = useMutation(data => graphqlClient.request(updateMessageQuery, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: 'getAllMessages' }).then(() => {
                notify.updated('Message')
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const save = (cancelled = false) => {
        setIsEditing(false)
        if (cancelled === false) {
            updateMessage.mutate({
                id: message.id,
                body: torbuEditorEditOnly.contents
            })
        }
    }

    return (
        <>
            <Box
                px={16}
                style={{ minHeight: '70px' }}
                className="chat-message"
            >
                {(!isEditing && hasMessageDelete(message)) && (
                    <Box
                        my={'xs'}
                        mr={15}
                        className="float-right"
                    >
                        <Popover
                            shadow="md"
                            width={200}
                            withArrow
                            position="bottom"
                            withinPortal
                        >
                            <Popover.Target>
                                <Box>
                                    <ButtonPrimary
                                        size={'xs'}
                                        variant={'outline'}
                                    >
                                        <Icon icon={'DotsVertical'} />
                                    </ButtonPrimary>
                                </Box>
                            </Popover.Target>

                            <Popover.Dropdown>
                                {(currentUser?.id === message?.createdBy?.id && hasPermissionAnywhere('channel:create:v2')) && (
                                    <Box
                                        mb={'sm'}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <IconText
                                            icon={'Edit'}
                                            text={'Edit Message'}
                                        />
                                    </Box>
                                )}
                                {hasMessageDelete(message) && (
                                    <Box
                                        mb={'sm'}
                                        onClick={archiveMessage}
                                    >
                                        <IconText
                                            icon={'Trash'}
                                            text={'Delete Message'}
                                        />
                                    </Box>
                                )}
                            </Popover.Dropdown>
                        </Popover>
                    </Box>
                )}

                <Group
                    spacing={8}
                    noWrap
                    align
                    pb={32}
                >
                    <Box>
                        <HoverCard
                            withArrow
                            withinPortal
                            position="top"
                            width={280}
                            bg="none"
                        >
                            <HoverCard.Target>
                                <Box>
                                    <StrideAvatar
                                        user={message.user}
                                        size={44}
                                    />
                                </Box>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                                <ContactCard user={message.user} />
                            </HoverCard.Dropdown>
                        </HoverCard>
                    </Box>
                    {isEditing ? (
                        <>
                            <Box w={'100%'}>
                                <ChatEditor
                                    editor={torbuEditorEditOnly}
                                    onSave={save}
                                    isEditing
                                />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box mt={-3}>
                                <Group>
                                    <Box>
                                        <Text
                                            size={'md'}
                                            fw={500}
                                            color={'#3d3d3d'}
                                        >
                                            {message.user.firstName} {message.user.lastName}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text
                                            size={'xs'}
                                            fw={300}
                                            span
                                        >
                                            <Moment format={'MMM Do hh:mm A'}>{message.createdAt}</Moment>
                                        </Text>

                                        {message.updatedAt && (
                                            <Text
                                                size={'xs'}
                                                fw={300}
                                                ml={10}
                                                color={'#5e5e5e'}
                                                span
                                            >
                                                (edited)
                                            </Text>
                                        )}
                                    </Box>
                                </Group>

                                <TorbuEditor
                                    editor={torbuEditorReadOnly}
                                    readOnly
                                />
                            </Box>
                        </>
                    )}
                </Group>
            </Box>
        </>
    )
}
export default Message

import React,{ useEffect } from "react"
import { QueryCache } from "react-query"
import { useRecoilValue } from 'recoil'
import StrideLoader from '../../../Helpers/StrideLoader'
import SiteWrapper from '../../../Theme/Components/SiteWrapper'
import state from '../../../Utils/State'

export const Logout = () => {
    const sessionId = useRecoilValue(state.sessionId)
    const queryCache = new QueryCache()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_STRIDE_API_ENDPOINT}/sign-out`, {
            method: "GET",
            headers: {
                "Content-Type" : "application/json",
                "Stride-Auth" : "web",
                "Stride-Web-Token" : sessionId
            },
        })
            .then((response) => response.json())
            .then(() => {

            })

        queryCache.clear()
        localStorage.removeItem("Stride-Data")
        location.replace("/")

    }, [])

    return (
        <SiteWrapper>
            <StrideLoader transitionDuration={250} />
        </SiteWrapper>
    )
}
export default Logout
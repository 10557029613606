import React from "react";
import Moment from 'react-moment';
import { useNavigate } from "react-router";
import usePermission from '../../../Hooks/usePermission';

export const UpcomingEvent = ({event}) => {
    const { hasPermissionAnywhere } = usePermission()
    const navigate = useNavigate()
    
    return (
        <>
            <div
                onClick={() => {
                    if (hasPermissionAnywhere('event:view:v2')) {
                        navigate(`/dashboard/${event.id}/view`)
                    }
                }}
                className={'mb-4 cursor-pointer rounded-md border border-slate-400 bg-white p-2 shadow-md hover:bg-gray-100'}
                style={{ borderTop: `3px solid ${event.eventType.color}` }}
            >
                <div className="flex gap-2 text-gray-400 font-torbu-demi-bold">
                    <div className="p-2 text-center border rounded-lg border-slate-300 ">
                        <div className="mb-1">
                            <Moment format="MMM">{event.startTime}</Moment>
                        </div>
                        <div>
                            <Moment format="DD">{event.startTime}</Moment>
                        </div>
                    </div>

                    <div className="mt-1">
                        <div className="mb-1 text-sm text-gray-700 font-torbu-demi-bold">{event.title}</div>
                        <div className="mt-1 text-sm text-gray-500">
                            <Moment format="h:mma-">{event.startTime}</Moment>
                            <Moment format="h:mma">{event.endTime}</Moment>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default UpcomingEvent
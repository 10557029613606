import React, { useEffect, useRef } from "react"
import { Anchor, Card, Center } from "@mantine/core"
import { useLocation, useNavigate } from "react-router"
import useCurrentAccount from "../../Hooks/Models/useCurrentAccount"
import usePermission from "../../Hooks/usePermission"

const alwaysAllowedLocations = new Set([
    '/account-select',
    '/account-manager/billing',
    '/profile'
])

const ExpiredAccountAlert = () => {
    const { currentAccount } = useCurrentAccount()
    const { hasPermissionAnywhere } = usePermission()
    const location = useLocation()
    const navigate = useNavigate()
    return (currentAccount.isTrialExpired && !alwaysAllowedLocations.has(location.pathname) &&
        <div className={'bg-[rgba(0,_0,_0,_.5)] absolute top-0 left-0 z-account-expired-modal w-full h-full flex justify-center items-center'}>
            <div className={'bg-torbu-white border border-torbu-black rounded-md p-4'}>
                {hasPermissionAnywhere('billing:manage') ? (
                    <>
                        Account expired, please go to <span className={'cursor-pointer text-torbu-peach'} onClick={() => navigate('/account-manager/billing')}>billing</span> to re-activate.
                    </>
                ) : (
                    <>
                        Account expired, please contact account admin to re-activate.
                    </>
                )}
            </div>
        </div>
    )
}

export default ExpiredAccountAlert

import { Box, Divider, Text } from '@mantine/core'
import { useNotify } from '../../Hooks/useNotify'
import { cloneDeep, filter, find, indexOf, isEmpty, sortBy } from 'lodash'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useResizeDetector } from 'react-resize-detector'
import { useRecoilState } from 'recoil'
import { Header } from '../../Components/Header'
import ApplicationTwoColumns from '../../Helpers/ApplicationTwoColumns'
import Icon from '../../Helpers/Icon'
import StrideLoader from '../../Helpers/StrideLoader'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import useGQL from '../../Hooks/useGQL'
import useTorbuEditor from '../../Hooks/useTorbuEditor'
import state from '../../Utils/State'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'
import ChatChannels from './ChatChannels'
import ChatDrawerManager from './ChatDrawerManager'
import ChatEditor from './ChatEditor'
import ChatMessages from './ChatMessages'
import ChatPanelHeader from './ChatPanelHeader'
import ChatPanelTop from './ChatPanelTop'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Can from '../../Helpers/Can'

export const ChatApplication = () => {
    const { currentUser } = useCurrentUser()
    const [selectedChannel, setSelectedChannel] = useRecoilState(state.activeChatChannel)
    const [drawer, setDrawer] = useState({})
    const [channels, setChannels] = useState([])
    const [lastMessageRead, setLastMessageRead] = useState({})
    const { height, ref } = useResizeDetector()
    const torbuEditor = useTorbuEditor({ placeholder: 'Message the channel' })
    const notify = useNotify()

    const channelQuery = useQuery(['getAllChannels'], {
        onSettled: data => {
            if (data.getAllChannels?.length) {
                let channelData = sortBy(data.getAllChannels, ['title', 'createdAt'])
                setChannels(channelData)

                if (channelData.length && channelData && !isEmpty(selectedChannel)) {
                    let foundChannel = filter(channelData, channelDataTmp => channelDataTmp.id === selectedChannel.id)
                    if (foundChannel.length) {
                        setSelectedChannel(foundChannel[0])
                        setLastMessageRead({})
                    }
                }
            }
        }
    })

    const setLastReadMessageGQL = useGQL('setLastReadMessage')
    const setLastReadMessageQuery = useMutation(data => graphqlClient.request(setLastReadMessageGQL, data))

    const updateLastRead = message => {
        const messagePayload = {
            messageId: message.id
        }
        setLastMessageRead(message)
        setLastReadMessageQuery.mutate(messagePayload)

        let channelCopy = cloneDeep(channels)
        let channel = find(channelCopy, channel => channel.id === selectedChannel.id)
        let channelIndex = indexOf(channelCopy, channel)
        let channelUsersCopy = cloneDeep(channel?.channelUsers)
        let channelUser = find(channelUsersCopy, channelUser => channelUser.user.id === currentUser.id)
        let channelUserIndex = indexOf(channelUsersCopy, channelUser)

        if (channel && channelUser) {
            channelUsersCopy[channelUserIndex].lastReadCount = 0
            channelCopy[channelIndex].channelUsers = channelUsersCopy
        }
        setChannels(channelCopy)
    }

    const handleLastMessageRead = message => {
        if (isEmpty(lastMessageRead)) {
            updateLastRead(message)
        } else if (message.id !== lastMessageRead.id && message.createdAt > lastMessageRead.createdAt) {
            updateLastRead(message)
        }
    }

    const createMessageQuery = useGQL('createMessage')
    const createMessage = useMutation(data => graphqlClient.request(createMessageQuery, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: 'getAllMessages' }).then(() => {
                torbuEditor.clearContents()
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const saveMessage = () => {
        const contents = torbuEditor.contents
        if (!isEmpty(contents)) {
            createMessage.mutate({
                channelId: selectedChannel.id,
                body: contents
            })
        }
    }

    return (
        <>
            <Header
                text={'Messages'}
                icon={'Chat'}
            />

            <StrideLoader loading={channelQuery.isLoading}>
                <ApplicationTwoColumns
                    leftColumn={
                        <>
                            <ChatChannels
                                setLastMessageRead={setLastMessageRead}
                                channels={channels}
                                drawer={drawer}
                                setDrawer={setDrawer}
                            />
                        </>
                    }
                    rightColumn={
                        <>
                            <ChatPanelTop
                                drawer={drawer}
                                setDrawer={setDrawer}
                            />
                            <Divider />
                            {selectedChannel !== null ? (
                                <>
                                    <ChatPanelHeader setDrawer={setDrawer} />
                                    <ChatMessages
                                        messageEditorHeight={height}
                                        selectedChannel={selectedChannel}
                                        handleLastMessageRead={handleLastMessageRead}
                                    />
                                    <Box
                                        mt={16}
                                        ref={ref}
                                    >
                                        <ChatEditor
                                            editor={torbuEditor}
                                            onSave={saveMessage}
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        ta={'center'}
                                        mt={25}
                                    >
                                        <Text>Select a channel on the left to start chatting</Text>
                                        <Can create={"channel"}>
                                            <Text>OR</Text>
                                            <ButtonPrimary
                                                onClick={() => {
                                                    setDrawer({ type: 'channelAdd' })
                                                }}
                                            >
                                                Start a new channel
                                            </ButtonPrimary>
                                        </Can>
                                    </Box>
                                </>
                            )}
                        </>
                    }
                />
            </StrideLoader>

            <ChatDrawerManager
                drawer={drawer}
                setDrawer={setDrawer}
            />
        </>
    )
}
export default ChatApplication

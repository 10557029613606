import { Box } from '@mantine/core'
import { find } from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Header } from '../../Components/Header'
import ApplicationTwoColumns from '../../Helpers/ApplicationTwoColumns'
import IconText from '../../Helpers/IconText'
import AdminAccounts from './Accounts/AdminAccounts'
import AdminInviteCodes from './InviteCodes/AdminInviteCodes'

const links = [
    {
        title: 'Accounts',
        to: '/admin/accounts',
        content: <AdminAccounts />
    },
    {
        title: 'Invite Codes',
        to: '/admin/invite-codes',
        content: <AdminInviteCodes />
    }
]

export const Admin = () => {
    const { page } = useParams()

    const renderContent = () => {
        const content = find(links, link => link.to.includes(page))
        if (content) {
            return content.content
        } else {
            return <></>
        }
    }

    return (
        <>
            <Header
                text={'Torbu Administration'}
                icon={'Admin'}
            />
            <ApplicationTwoColumns
                leftColumn={
                    <>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'Affiliate'}
                                text={'Accounts'}
                                to={'/admin/accounts'}
                            />
                        </Box>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'Ticket'}
                                text={'Invite Codes'}
                                to={'/admin/invite-codes'}
                            />
                        </Box>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'Writing'}
                                text={'Account Stats'}
                                to={'/admin/account-stats'}
                            />
                        </Box>
                    </>
                }
                rightColumn={<>{renderContent()}</>}
            />
        </>
    )
}
export default Admin

import { Box,Group,Modal,Paper,Text,Tooltip } from '@mantine/core'
import { truncate } from 'lodash'
import React,{ useState } from 'react'
import { useNavigate } from 'react-router'
import ButtonLink from '../../../../Components/Buttons/ButtonLink'
import ButtonPrimary from '../../../../Components/Buttons/ButtonPrimary'
import Can from '../../../../Helpers/Can'
import Icon from '../../../../Helpers/Icon'
import StrideAvatar from '../../../../Helpers/StrideAvatar'
import usePermission from '../../../../Hooks/usePermission'
import AddNewAccount from '../AddNewAccount'

const truncateOptions = {
    length: 30,
    omission: '...',
    separator: /,? +/
}

const NodeTemplate = ({ account }) => {
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false)
    const { hasPermissionAnywhere } = usePermission()

    const goToAccount = () => {
        navigate(`/account-manager/accounts/${account.id}`)
    }

    const handleManageButton = () => {
        window.Intercom("trackEvent", "manage-team-button")
        navigate(`/account-manager/accounts/${account.id}`)
    }
    
    const handleModalClose = e => {}

    const colorsAllowed = ['#FC7C57', '#D6FC49', '#035157', '#51cf66', '#5c7cfa', '#ff922b', '#22b8cf', '#ff6b6b', '#845ef7', '#fcc419', '#339af0', '#f06595', '#cc5de8', '#51cf66', '#5c7cfa', '#ff922b', '#22b8cf', '#ff6b6b', '#845ef7', '#fcc419']
    return (
        <>
            <Paper
                withBorder
                shadow={'sm'}
                p={'sm'}
                w={225}
                style={{ position: 'relative', borderTop: `5px solid ${colorsAllowed[account.generationLevel - 1]}`, cursor: 'default' }}
            >
                {account.isPrimaryAccount && (
                    <Box style={{ position: 'absolute', right: '4px', top: '4px' }}>
                        <Tooltip
                            label={'Primary Account.'}
                            withArrow
                            withinPortal
                        >
                            <Box>
                                <Icon
                                    icon={'Star'}
                                    size={14}
                                    stroke={2.5}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                )}

                <Box>
                    <Group
                        noWrap
                        align
                        spacing={'xs'}
                    >
                        <Box>
                            <StrideAvatar account={account} />
                        </Box>
                        <Box>
                            <Box>
                                <Text
                                    size={12}
                                    fw={500}
                                >
                                    {truncate(account.name, truncateOptions)}
                                </Text>
                            </Box>
                        </Box>
                    </Group>

                    <Box mt={'md'}>
                        <Group position={hasPermissionAnywhere('team:manage') ? 'apart' : 'right'}>
                            <Can manage={'team'} accountId={account.id}>
                                <Tooltip
                                    label={'Add a new team under this teams organization'}
                                    position={'bottom'}
                                    withArrow
                                    withinPortal
                                >
                                    <Box data-intercom-target="Add Team Button">
                                        <ButtonPrimary onClick={() => setModalOpen(true)}>Add Team</ButtonPrimary>
                                    </Box>
                                </Tooltip>
                            </Can>

                            <Tooltip
                                label={'Manage team, users, roles, and permissions'}
                                position={'bottom'}
                                withArrow
                                withinPortal
                            >
                                <Can manage={"team"} accountId={account.id}>
                                    <Box data-intercom-target="Manage Team Button">
                                        <ButtonPrimary onClick={handleManageButton}>Manage</ButtonPrimary>
                                    </Box>
                                </Can>
                            </Tooltip>
                        </Group>
                    </Box>
                </Box>
            </Paper>

            <Modal
                opened={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Add New Team"
            >
                <AddNewAccount
                    parentAccountId={account.id}
                    handleModalClose={handleModalClose}
                />
            </Modal>
        </>
    )
}

export default NodeTemplate

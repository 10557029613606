/* eslint-disable */
import {sortBy} from "lodash";
import queryClient from "../../Utils/queryClient";
import useGetQuery from "../useGetQuery";

const useAccountUsers = (variables={}) => {
    const key = 'getAllAccountUsersSimple'
    const query = useGetQuery(
        key,
        variables,
        'user.id',
        (data) => {
            return sortBy(data, ['user.fullName'])
        },
        {}
    )

    const invalidate = () => {
        return queryClient.invalidateQueries({ queryKey: [key, variables]})
    }

    return {
        data: query.data || [],
        invalidate: invalidate,
        isLoading: query.isLoading,
        isSuccess: query.isSuccess
    }
}
export default useAccountUsers
import React,{ useCallback } from 'react'
import { toast } from 'react-hot-toast'

export const useNotify = () => {
    const created = useCallback(
        (entity, text = 'has been created') => {
            toast.success(`${entity} ${text}`)
        },
        [toast.success]
    )
    
    const archived = useCallback(
        (entity, text = 'has been archived') => {
            toast.success(`${entity} ${text}`)
        },
        [toast.success]
    )

    const invited = useCallback(
        (entity, text = 'Invitation Successful') => {
            toast.success(`${text}`)
        },
        [toast.success]
    )

    const updated = useCallback(
        (entity, text = 'has been updated') => {
            toast.success(`${entity} ${text}`)
        },
        [toast.success]
    )

    const uploaded = useCallback(
        (entity, text = 'uploaded successfully') => {
            toast.success(`${entity} ${text}`)
        },
        [toast.success]
    )

    const recorded = useCallback(
        (entity, text = 'has been recorded') => {
            toast.success(`${entity} ${text}`)
        },
        [toast.success]
    )

    const registration = useCallback(
        (entity, text = 'Registration was successful, please log in.') => {
            toast.success(`${entity} ${text}`)
        },
        [toast.success]
    )

    const error = useCallback(
        (text = 'Something went wrong') => {
            toast.error(`${text}`)
        },
        [toast.error]
    )

    const archiveRoleError = useCallback(
        (text = 'Role with users cannot be archived') => {
            toast.error(`${text}`)
        },
        [toast.error]
    )

    const passwordReqError = useCallback(
        (text = 'Password does not meet minimal password requirements.') => {
            toast.error(`${text}`)
        },
        [toast.error]
    )

    return {
        error: error,
        created: created,
        updated: updated,
        invited: invited,
        archived: archived,
        uploaded: uploaded,
        recorded: recorded,
        registration: registration,
        passwordReqError: passwordReqError,
        archiveRoleError: archiveRoleError
    }
}

import React from 'react'
import {isEmpty, isString, isUndefined} from 'lodash'
import {Box, Group, Text, Tooltip} from '@mantine/core'
import Icon from './Icon'
import {useNavigate} from 'react-router'

const GroupContainer = React.forwardRef(
    ({ icon, text, iconProps, textProps, link}, ref) => (
        <div ref={ref}>
            <Group spacing={8} align noWrap>
                <Box>
                    <Icon
                        icon={icon}
                        {...iconProps}
                    />
                </Box>
                <Box>
                    <Text
                        fw={'300'}
                        size={'md'}
                        lineClamp={1}
                        {...link}
                        {...textProps}
                    >
                        {text}
                    </Text>
                </Box>
            </Group>
        </div>
    )
)

export const IconText = ({icon, text, to, iconProps = {}, textProps = {}}) => {
    const navigate = useNavigate()

    if(!isString(icon) && !isString(text)){
        return null
    }

    let link = {}
    if(!isUndefined(to)){
        link = {
            className: 'pointer',
            onClick: () => {
                navigate(to)
            }
        }
    }

    return (
        <>
            {!isEmpty(link) ? (
                <Tooltip label={text} position={'bottom'} withArrow withinPortal>
                    <GroupContainer icon={icon} text={text} link={link} iconProps={iconProps} textProps={textProps} />
                </Tooltip>
            ):(
                <GroupContainer icon={icon} text={text} link={link} iconProps={iconProps} textProps={textProps} />
            )}
        </>
    )
}

export default IconText
import React from 'react'
import {Box, Text} from '@mantine/core'
import {useDrop} from 'react-dnd'
import moment from 'moment/moment'

const IntervalDroppable = ({interval, settings, user, canEdit, handleSelectedInterval}) => {
    const date = moment(interval.date)
    let hoverClass = canEdit ? 'segment-interval' : ''

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'SegmentContainer',
        drop: () => ({ interval: interval, user: user }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const isActive = canDrop && isOver

    const buildBackground = () => {
        let color = ''
        if (isActive) {
            color = settings.intervalHighlightColor
        }else if(date.format('mm') === '00'){
            color = settings.intervalBackgroundColor
        }
        return color
    }

    return (
        <Box
            ref={drop}
            h={settings.timeIncrementsHeight - 1}
            mah={settings.timeIncrementsHeight - 1}
            style={{
                borderBottom: `1px solid ${settings.intervalBorderColor}`,
                backgroundColor: buildBackground()
            }}
            className={hoverClass}
            onClick={() => {
                handleSelectedInterval({interval: interval, assignedUser: user})
            }}
        >
            {date.format('hh:mm a') === '12:00 am' && (
                <Text align={'center'} color={'dimmed'} size={'xs'} pt={8}>
                    {date.format('MMMM Do')}
                </Text>
            )}
        </Box>
    )
}

export default IntervalDroppable

import { Container,Divider,Group,Paper,PinInput,ScrollArea,Text } from '@mantine/core'
import { sortBy } from 'lodash'
import React,{ useState } from 'react'
import { QueryCache,useMutation,useQuery } from 'react-query'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Icon from '../../Helpers/Icon'
import StrideAvatar from '../../Helpers/StrideAvatar'
import StrideLoader from '../../Helpers/StrideLoader'
import useCurrentAccount from '../../Hooks/Models/useCurrentAccount'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import SiteWrapper from '../../Theme/Components/SiteWrapper'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'

export const AccountSelector = () => {
    const [inviteCode, setInviteCode] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const [accounts, setAccounts] = useState([])
    const { setCurrentAccount } = useCurrentAccount()
    const notify = useNotify()

    const queryCache = new QueryCache()

    const getAllAccountsGQL = useGQL('getAllAccounts')
    const accountsQuery = useQuery([getAllAccountsGQL, { filter: { primaryAccounts: true } }], {
        onSuccess: data => {
            if (data?.getAllAccounts?.edges?.length) {
                setAccounts(sortBy(data.getAllAccounts.edges, account => account.node.name.toLowerCase(), 'node.name').map(account => account.node))
            } else {
                setAccounts([])
            }
        }
    })

    const addCallerWithInviteCodeGQL = useGQL('addCallerWithInviteCode')
    const addUserWithInviteCode = useMutation(data => graphqlClient.request(addCallerWithInviteCodeGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: getAllAccountsGQL }).then(() => {
                setInviteCode('')
                setButtonLoading(false)
            })
            notify.invited()

        },
        onError: error => {
            console.error(error)
            notify.error()
            setButtonLoading(false)
        }
    })

    const handleAccountToggle = account => {
        setCurrentAccount(account)
        queryCache.clear()
        location.replace('/')
    }

    const attemptCode = () => {
        if (inviteCode !== '') {
            setButtonLoading(true)
            addUserWithInviteCode.mutate({ inviteCode: inviteCode })
        }
    }

    return (
        <SiteWrapper showHeader={false}>
            <StrideLoader loading={accountsQuery.isLoading}>
                <Container
                    size={400}
                    mt={100}
                >
                    <Paper
                        withBorder
                        bg="white"
                        shadow="md"
                        p="md"
                    >
                        {accounts.length ? (
                            <>
                                <Text
                                    size={'sm'}
                                    color={'#424242'}
                                    mb={5}
                                    fw={'bold'}
                                    data-intercom-target="Select Account Text"
                                >
                                    Select an account
                                </Text>
                                <ScrollArea.Autosize
                                    mah={300}
                                    type="always"
                                >
                                    {accounts.map(account => (
                                        <div
                                            key={account.id}
                                            className="account-selector-item"
                                            onClick={() => {
                                                handleAccountToggle(account)
                                            }}
                                        >
                                            <Group>
                                                <StrideAvatar account={account} />
                                                <Text size={'md'}>{account.name}</Text>
                                            </Group>
                                        </div>
                                    ))}
                                </ScrollArea.Autosize>
                                <Divider
                                    my="xl"
                                    label="OR"
                                    labelPosition="center"
                                />

                                <Text
                                    size={'sm'}
                                    color={'#2d2d2d'}
                                    fw={'bold'}
                                    data-intercom-target="Add Account Text"
                                >
                                    Add a new account with an invite code below
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text
                                    size={'sm'}
                                    color={'#424242'}
                                    mb={5}
                                    fw={'bold'}
                                >
                                    There was no accounts found to be associated with your account. To associate with an account please enter an invite code given to you.
                                </Text>
                            </>
                        )}

                        <Group
                            position="center"
                            mt={16}
                        >
                            <PinInput
                                length={6}
                                size={'xl'}
                                onChange={setInviteCode}
                                value={inviteCode}
                            />
                        </Group>

                        <div className="mt-8">
                            <ButtonPrimary
                                fullWidth
                                type="submit"
                                onClick={attemptCode}
                                saving={buttonLoading}
                            >
                                Submit Code
                            </ButtonPrimary>
                        </div>
                    </Paper>
                </Container>
            </StrideLoader>
        </SiteWrapper>
    )
}
export default AccountSelector

import React,{ useState } from "react";
import { Header } from '../../Components/Header';
import ApplicationTwoColumns from '../../Helpers/ApplicationTwoColumns';
import { Calendar } from "./Views/Calendar";
import CalendarDrawer from "./Views/CalendarDrawer";
import { MiniCalendar } from "./Views/MiniCalendar";
import { UpcomingEventsList } from "./Views/UpcomingEventsList";
import { CalendarProvider } from "../../Hooks/useCalendar";
import Can from "../../Helpers/Can";
import { useNavigate } from 'react-router'
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";

export const CalendarApplication = () => {
    const [selectedTeams, setSelectedTeams] = useState([]);
    const navigate = useNavigate()

    const handleAddEventButton = () => {
        window.Intercom("trackEvent", "add-event-button")
        navigate("/events/new")
    }
   
    return (
        <CalendarProvider>
            <Header
                text={'Teamspace'}
                icon={'Teamspace'}
            >
                <Can create={"event"}>
                    <ButtonPrimary data-intercom-target="Add Event Button" onClick={handleAddEventButton}>Add New Event</ButtonPrimary>
                </Can>
            </Header>

            <ApplicationTwoColumns
                leftColumn={
                    <>
                        <MiniCalendar />
                        <UpcomingEventsList selectedTeams={selectedTeams}/>
                    </>
                }
                rightColumn={<Calendar selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}/>}
            />

            <CalendarDrawer />
        </CalendarProvider>
    )

}

export default CalendarApplication

import { isNull, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

const useGetQuery = (gql, variables = {}, uniqueKey = 'id', dataCallback, options = {}) => {
    const [responseData, setResponseData] = useState(null)
    const [pageInfo, setPageInfo] = useState({})
    const [cursor, setCursor] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    if (!isNull(cursor) && !Object.keys(variables).includes('after')) {
        variables.after = cursor
    }

    const query = useQuery([gql, variables], {
        onSettled: data => {
            if (data) {
                const topLevelKey = Object.keys(data)[0]
                const topLevelData = data[topLevelKey]

                let dataAccessor = ''
                if (Object.keys(topLevelData).includes('edges')) {
                    dataAccessor = topLevelData.edges.map(d => d.node)

                    if (isNull(responseData)) {
                        setResponseData(dataAccessor)
                    } else {
                        setResponseData(uniqBy([...responseData, ...dataAccessor], uniqueKey))
                    }

                    if (Object.keys(topLevelData).includes('pageInfo')) {
                        setPageInfo(topLevelData.pageInfo)

                        if (topLevelData.pageInfo.hasNextPage === true) {
                            setCursor(topLevelData.pageInfo.endCursor)
                        }
                    }
                } else {
                    setResponseData(uniqBy(topLevelData, uniqueKey))
                }
            }
        },
        ...options
    })

    useEffect(() => {
        setIsLoading(query.isLoading)
    }, [query])

    if (query.isFetched && dataCallback instanceof Function) {
        return {
            data: dataCallback(responseData),
            pagination: pageInfo,
            isLoading: isLoading,
            isDone: query.isFetched
        }
    } else {
        return {
            data: responseData,
            pagination: pageInfo,
            isLoading: isLoading,
            isDone: query.isFetched
        }
    }
}

export default useGetQuery

import { Box } from '@mantine/core'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Header } from '../../Components/Header'
import ApplicationTwoColumns from '../../Helpers/ApplicationTwoColumns'
import IconText from '../../Helpers/IconText'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Stat = ({ stat, title }) => {
    return (
        <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">
                {title}
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{stat}</dd>
        </div>
    )
}

const AccountStats = () => {
    const [stats, setStats] = useState({})

    const inviteCodesQuery = useQuery(['getAccountStats'], {
        onSettled: data => {
            if (data.getAccountStats) {
                setStats(data.getAccountStats)
            } else {
                setStats({})
            }
        }
    })

    return (
        <>
            <Header
                text={'Torbu Administration'}
                icon={'Admin'}
            />
            <ApplicationTwoColumns
                leftColumn={
                    <>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'Affiliate'}
                                text={'Accounts'}
                                to={'/admin/accounts'}
                            />
                        </Box>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'Ticket'}
                                text={'Invite Codes'}
                                to={'/admin/invite-codes'}
                            />
                        </Box>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'Writing'}
                                text={'Account Stats'}
                                to={'/admin/account-stats'}
                            />
                        </Box>
                    </>
                }
                rightColumn={
                    <>
                        <div className="p-4 mb-4 bg-gray-200 rounded-md">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Accounts</h3>
                            <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
                                <Stat
                                    stat={stats.primaryAccountsTotal}
                                    title={'Total Accounts'}
                                />
                                <Stat
                                    stat={stats.primaryAccountsCurrentMonth}
                                    title={'This Month'}
                                />
                                <Stat
                                    stat={stats.primaryAccountsCurrentWeek}
                                    title={'This Week'}
                                />
                                <Stat
                                    stat={stats.primaryAccountsCurrentDay}
                                    title={'Today'}
                                />
                            </dl>

                            <h3 className="mt-4 text-base font-semibold leading-6 text-gray-900">Child Accounts</h3>
                            <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
                                <Stat
                                    stat={stats.childAccountsTotal}
                                    title={'Total Child Accounts'}
                                />
                                <Stat
                                    stat={stats.childAccountsCurrentMonth}
                                    title={'This Month'}
                                />
                                <Stat
                                    stat={stats.childAccountsCurrentWeek}
                                    title={'This Week'}
                                />
                                <Stat
                                    stat={stats.childAccountsCurrentDay}
                                    title={'Today'}
                                />
                            </dl>
                        </div>
                        <div className="p-4 mb-4 bg-gray-200 rounded-md">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Users</h3>
                            <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
                                <Stat
                                    stat={stats.usersTotal}
                                    title={'Total Users'}
                                />
                                <Stat
                                    stat={stats.usersCurrentMonth}
                                    title={'Monthly'}
                                />
                                <Stat
                                    stat={stats.usersCurrentWeek}
                                    title={'This Week'}
                                />
                                <Stat
                                    stat={stats.usersCurrentDay}
                                    title={'Today'}
                                />
                            </dl>
                        </div>

                        <div className="p-4 mb-4 bg-gray-200 rounded-md">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Events</h3>
                            <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
                                <Stat
                                    stat={stats.eventsTotal}
                                    title={'Total Events'}
                                />
                                <Stat
                                    stat={stats.eventsCurrentMonth}
                                    title={'This Month'}
                                />
                                <Stat
                                    stat={stats.eventsCurrentWeek}
                                    title={'This Week'}
                                />
                                <Stat
                                    stat={stats.eventsCurrentDay}
                                    title={'Today'}
                                />
                            </dl>
                        </div>
                        <div className="p-4 mb-4 bg-gray-200 rounded-md">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Chat Channels</h3>
                            <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
                                <Stat
                                    stat={stats.channelsTotal}
                                    title={'Total Channels'}
                                />
                                <Stat
                                    stat={stats.channelsCurrentMonth}
                                    title={'This Month'}
                                />
                                <Stat
                                    stat={stats.channelsCurrentWeek}
                                    title={'This Week'}
                                />
                                <Stat
                                    stat={stats.channelsCurrentDay}
                                    title={'Today'}
                                />
                            </dl>

                            <h3 className="mt-4 text-base font-semibold leading-6 text-gray-900">Messages</h3>
                            <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
                                <Stat
                                    stat={stats.messagesTotal}
                                    title={'Total Messages'}
                                />
                                <Stat
                                    stat={stats.messagesCurrentMonth}
                                    title={'This Month'}
                                />
                                <Stat
                                    stat={stats.messagesCurrentWeek}
                                    title={'This Week'}
                                />
                                <Stat
                                    stat={stats.messagesCurrentDay}
                                    title={'Today'}
                                />
                            </dl>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default AccountStats

import {isUndefined} from "lodash"
import gqlRegistry from "../Utils/gqlRegistry"

const useGQL = (query) => {

    if(
        !isUndefined(query) &&
        !isUndefined(gqlRegistry) &&
        !isUndefined(gqlRegistry[query])
    ){
        return gqlRegistry[query]
    }else{
        console.error(`Graphql file is not loaded into the utils/gqlRegistry.js. In your terminal run: yarn run gql OR restart your yarn server.`)
    }

    return null
}
export default useGQL
import { useEffect,useState } from 'react'
import { useQuery } from 'react-query'
import useGQL from "../Hooks/useGQL"

const useAccountDirectory = (requiredPermissionCode) => {
    const [accounts, setAccounts] = useState([])
    const [options, setOptions] = useState([])

    const getAllAccountsGQL = useGQL('getAllAccounts')
    
    useQuery([getAllAccountsGQL, { filter: { requiredPermissionCode } }], {
        onSettled: (data) => {
            if(data.getAllAccounts?.edges?.length){
                setAccounts(data.getAllAccounts.edges.map(account => account.node))
            }else{
                setAccounts([])
            }
        }
    })

    const buildAccountHierarchy = (data, account) => {
        account.children = []
        let accountsChildren = data.filter(item => item.parent?.id === account.id)
        if(accountsChildren.length > 0){
            accountsChildren.forEach(subAccount => {
                account.children.push(buildAccountHierarchy(data,subAccount))
            })
        }
        return account
    }

    const renderAccountOption = account => {
        setOptions(oldOptions => [
            ...oldOptions,
            {
                value: account.id,
                label: account.name,
                account: account,
                group: account.parent ? account.parent.name : 'Primary Account'
            }
        ])

        if (account.children.length > 0) {
            account.children.forEach(subAccount => {
                renderAccountOption(subAccount)
            })
        }
    }

    useEffect(() => {
        if (accounts.length) {
            setOptions([])

            // Find the minimum generation level among all accounts
            const minGenerationLevel = accounts.reduce((minLevel, account) => {
                if (minLevel === null || account.generationLevel < minLevel) {
                    return account.generationLevel
                }
                return minLevel
            }, null)

            // Find all accounts with the minimum generation level
            const minGenerationAccounts = accounts.filter(account => account.generationLevel === minGenerationLevel)

            // Build the account hierarchy and render options for each account
            minGenerationAccounts.forEach(account => {
                const accountHierarchy = buildAccountHierarchy(accounts, account)
                renderAccountOption(accountHierarchy)
            })
        } else {
            setOptions([])
        }
    }, [accounts])


    return options
}

export default useAccountDirectory
import {isObject} from "lodash"
import { QueryClient } from "react-query"
import useGQL from "../Hooks/useGQL"
import graphqlClient from "./graphqlClient"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: async function fetchGraphQL({ queryKey }) {
                const [query, variables] = queryKey

                if(isObject(query)){
                    return graphqlClient.request(query, variables)
                }else{
                    const gqlFile = useGQL(query)
                    return graphqlClient.request(gqlFile, variables)
                }
            }
        }
    }
})
export default queryClient

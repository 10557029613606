import {sortBy} from "lodash"
import {useCallback} from "react"
import queryClient from "../../Utils/queryClient"
import useGetQuery from "../useGetQuery"
import {isEmpty} from 'lodash'

const useUserDirectory = (variables = {}) => {
    const key = variables.accountIds ? 'getUserDirectory': 'getCallerAddUserDirectory'

    const query = useGetQuery(
        key,
        variables,
        'id',
        (data) => {
            return sortBy(data, user => user.firstName.toLowerCase(), ['firstName', 'lastName'])
        },
    )

    const invalidate = () => {
        return queryClient.invalidateQueries({ queryKey: [key, variables]})
    }

    const renderOptions = useCallback(() => {
        return query.data?.map(user => {
            return {
                label: user.firstName + ' '+ user.lastName,
                value: user.id,
                user: user,
                group: 'Individuals'
            }
        }) || []
    }, [query])

    return {
        data: query.data || [],
        renderOptions: renderOptions,
        invalidate: invalidate,
        isLoading: query.isLoading,
        isSuccess: query.isSuccess
    }
}
export default useUserDirectory

import React from 'react'
import { Text, Box } from '@mantine/core'
import Icon from "../../Helpers/Icon"

const PasswordRequirement = ({ meets, label }) => {

    return (
        <Text
            color={meets ? '#035157' : '#fc7c57'}
            sx={{ display: 'flex', alignItems: 'center' }}
            mt={"sm"}
            size={"sm"}
        >
            {meets ?
                <Icon icon={'Check'} color={'#035157'} size="0.9rem" />
                :
                <Icon icon={'X'} color={'#fc7c57'} size="0.9rem" />
            }
            <Box ml={10}>{label}</Box>
        </Text>
    )
}

export default PasswordRequirement

import React, { useEffect, useRef, useState } from 'react'
import StrideHeader from '../../../Helpers/StrideHeader'
import { Divider } from '@mantine/core'
import Icon from '../../../Helpers/Icon'
import useCards from '../../../Hooks/Models/useCards'
import useStripeUrl from '../../../Hooks/Models/useStripeUrl'
import Can from '../../../Helpers/Can'
import useAccountSubscription from '../../../Hooks/Models/useAccountSubscription'
import Moment from 'react-moment'
import ButtonPrimary from '../../../Components/Buttons/ButtonPrimary'

const cardHeight = 180
const cardWidth = cardHeight * 1.586
const formatCardNumber = (lastFour) => {
    return `**** **** **** ${lastFour}`
}
const formatCardType = (type) => {
    const words = type.split(' ')
    for (let i = 0; i < words.length; i++) {
        const word = words[i]
        const newWord = word[0].toUpperCase() + word.slice(1)
        words[i] = newWord
    }
    return words.join(' ')
}

export const AccountBilling = () => {
    const cards = useCards()
    const [sortedCards, setSortedCards] = useState(cards)
    const currentSubscription = useAccountSubscription()
    const stripeUrl = useStripeUrl()
    // there should always be a default card,
    // but this check exists just in case to prevent issues
    let noDefaultCard = useRef(false)
    useEffect(() => {
        let defaultCardExists = false
        for (const card of cards) {
            if (card.default) {
                defaultCardExists = true
                break
            }
        }
        noDefaultCard.current = !defaultCardExists
    }, [cards])
    useEffect(() => {
        if (noDefaultCard.current || cards.length < 2 || cards[0].default) {
            setSortedCards([...cards])
        } else {
            const sortedCards = [...cards]
            let defaultCardFound = false
            sortedCards.sort((a, b) => {
                if (Boolean(a.default) === Boolean(b.default)) return 0
                return a.default ? -1 : 1
            })
            setSortedCards(sortedCards)
        }
    }, [cards])
    const goToStripe = () => {
        if (stripeUrl) window.location = stripeUrl
    }
    return (
        <>
            <StrideHeader
                header={'Billing'}
                icon={'CreditCard'}
                withBorder
            />
            <div className={'flex flex-col gap-4'}>
                <StrideHeader
                    header={'Subscription'}
                />
                <div className={'flex items-center gap-4'}>
                    {currentSubscription?.subscriptionEndDate ? (
                        <>
                            <span><span className={'font-semibold'}>Subscribed</span> | Next billing date: <Moment format="MMMM Do">
                                {currentSubscription.subscriptionEndDate}
                            </Moment></span>
                            <Can manage={'billing'}><ButtonPrimary onClick={() => goToStripe()}>Edit</ButtonPrimary></Can>
                        </>
                    ) : (
                        <>
                            <span>No subscription</span>
                            <Can manage={'billing'}><ButtonPrimary onClick={() => goToStripe()}>Subscribe</ButtonPrimary></Can>
                        </>
                    )}
                </div>
                <Divider />
                <StrideHeader
                    header={'Payment Methods'}
                />
                <div className={'border-box flex flex-wrap gap-4'}>
                    {sortedCards.map(card => (
                        <div key={card.id} className={'shadow-lg border border-torbu-black p-4 rounded-md'} style={{
                            width: cardWidth,
                            height: cardHeight
                        }}>
                            <div className={'grid grid-rows-[1fr_repeat(4,_auto)] grid-cols-[1fr_auto] gap-2 h-full'}>
                                <div className={'row-start-1 col-start-1 text-lg'}>{card.billingName}</div>
                                <Can manage={'billing'}>
                                    {!card.default && (
                                        <Can admin={'billing'}>
                                            <div className={'row-start-1 col-start-2 w-[24px] h-[24px] cursor-pointer'} onClick={() => card.delete()}>
                                                <Icon icon={'Trash'} themeColor={'torbu-peach'} />
                                            </div>
                                        </Can>
                                    )}
                                </Can>
                                {card.expires !== undefined ? (
                                    <div className={'row-start-2 col-start-1 col-span-2'}>{`Expires: ${card.expires.getMonth()}/${card.expires.getDate()}/${card.expires.getFullYear()}`}</div>
                                ) : null}
                                <div className={'row-start-3 col-start-1 col-span-2'}>{formatCardNumber(card.lastFour)}</div>
                                <div className={'row-start-4 col-start-1 col-span-2'}>{formatCardType(card.type)}</div>
                                <div className={'row-start-5 col-start-1 col-span-2'}>
                                    {card.default ? (
                                        <span>Default</span>
                                    ) : (
                                        <Can manage={'billing'}>
                                            <span className={'cursor-pointer text-torbu-peach'} onClick={() => card.setAsDefault()}>Set as default</span>
                                        </Can>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

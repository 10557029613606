import { Box, Checkbox, Grid, Group, NumberInput, Paper, Select, Tabs, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconMessageCircle, IconPhoto, IconTrash } from '@tabler/icons-react'
import { isEmpty, sortBy } from 'lodash'
import moment from 'moment/moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import 'react-time-picker/dist/TimePicker.css'
import ButtonArchive from '../../Components/Buttons/ButtonArchive'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import ButtonEdit from '../../Components/Buttons/ButtonEdit'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Can from '../../Helpers/Can'
import TorbuEditor from '../../Helpers/TorbuEditor'
import UsersWithAvatars from '../../Helpers/UsersWithAvatars'
import useEventMutation from '../../Hooks/Models/useEventMutation'
import useUserDirectory from '../../Hooks/Models/useUserDirectory'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import useTorbuEditor from '../../Hooks/useTorbuEditor'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'

const AgendaModal = ({ event, segment, intervals, selectedInterval, openModal, closeModal }) => {
    const torbuEditor = useTorbuEditor({})
    const notify = useNotify()

    const intervalOptions = useMemo(() => {
        return intervals.map(interval => ({
            label: moment(interval.date).format('MMM DD - h:mm a'),
            value: moment(interval.date).unix()
        }))
    }, [intervals])

    const form = useForm({
        initialValues: {
            title: '',
            startTime: intervalOptions[0].value,
            duration: '',
            assignedUserId: '',
            saveAsTemplate: false
        },
        validate: {
            title: isNotEmpty('Title is required'),
            assignedUserId: isNotEmpty('You must select at least 1 assigned user'),
            startTime: isNotEmpty('Start time is required'),
            duration: isNotEmpty('Duration is required')
        }
    })

    const [activeTab, setActiveTab] = useState('new')
    const [segmentTemplates, setSegmentTemplates] = useState([])

    const userDirectory = useUserDirectory({ accountIds: event.accounts.map(account => account.id) })

    const archiveSegmentTemplateGQL = useGQL('archiveSegmentTemplate')

    useEffect(() => {
        if (!isEmpty(selectedInterval)) {
            form.setFieldValue('assignedUserId', selectedInterval.assignedUser?.id)
            form.setFieldValue('startTime', moment(selectedInterval.interval.date).unix())
        }
    }, [selectedInterval])

    useEffect(() => {
        if (!isEmpty(segment)) {
            const eventStart = moment(event.startTime)

            torbuEditor.setContents(segment.description)
            form.setFieldValue('title', segment.title)
            form.setFieldValue('assignedUserId', segment.assignedUser?.id)
            form.setFieldValue('duration', segment.duration)
            form.setFieldValue('startTime', eventStart.add(segment.minutesFromEventStart, 'minutes').unix())
        }
    }, [segment, torbuEditor.setContents])

    const getSegmentTemplatesKey = ['getAllSegmentTemplates', { accountIds: event?.accounts?.map(account => account.id) }]
    useQuery(getSegmentTemplatesKey, {
        onSettled: data => {
            if (data.getAllSegmentTemplates?.length) {
                setSegmentTemplates(sortBy(data.getAllSegmentTemplates, 'title'))
            } else {
                setSegmentTemplates([])
            }
        },
        enabled: !isEmpty(event)
    })

    const createSegmentMutation = useEventMutation({
        type: 'createSegment',
        onSuccess: () => {
            notify.created('Topic')
            form.reset()
            closeModal()
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const updateSegmentMutation = useEventMutation({
        type: 'updateSegment',
        onSuccess: () => {
            notify.updated('Topic')
            form.reset()
            closeModal()
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const archiveSegmentMutation = useEventMutation({
        type: 'archiveSegment',
        onSuccess: () => {
            notify.archived('Topic')
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })
    const archiveSegmentTemplateMutation = useMutation(data => graphqlClient.request(archiveSegmentTemplateGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllSegmentTemplates'] }).then(() => {
                notify.archived('Topic Template')
                form.reset()
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const saveSegment = data => {
        const eventStartTime = moment(event.startTime)
        const segmentStartTime = moment(data.startTime, 'X')

        const seg = {
            eventId: event.id,
            title: data.title,
            assignedUserId: data.assignedUserId,
            duration: data.duration,
            saveAsTemplate: data.saveAsTemplate,
            minutesFromEventStart: parseInt(segmentStartTime.diff(eventStartTime, 'minutes')),
            description: torbuEditor.contents
        }

        if (!isEmpty(segment)) {
            seg.id = segment.id

            //Set this for the segment that is already rendered. If we don't and immediately drag it will reset to prev state
            segment.title = seg.title
            segment.description = seg.description
            segment.assignedUserId = seg.assignedUserId
            segment.duration = seg.duration
            segment.minutesFromEventStart = seg.minutesFromEventStart

            updateSegmentMutation.mutate(seg)
        } else {
            createSegmentMutation.mutate(seg)
        }
    }

    return (
        <div>
            <Tabs
                value={activeTab}
                onTabChange={setActiveTab}
                allowTabDeactivation
            >
                <Tabs.List>
                    <Tabs.Tab
                        value="new"
                        icon={<IconPhoto size="0.8rem" />}
                    >
                        Add Topics
                    </Tabs.Tab>
                    <Tabs.Tab
                        value="library"
                        icon={<IconMessageCircle size="0.8rem" />}
                    >
                        Topic Library
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value="new"
                    pt="xs"
                >
                    <Grid>
                        <Grid.Col span={6}>
                            <TextInput
                                label="Title"
                                withAsterisk
                                {...form.getInputProps('title')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Select
                                label={'Assigned to'}
                                withAsterisk
                                data={userDirectory.renderOptions()}
                                itemComponent={UsersWithAvatars}
                                limit={25}
                                searchable
                                clearable
                                {...form.getInputProps('assignedUserId')}
                            />
                        </Grid.Col>
                    </Grid>

                    <Grid>
                        <Grid.Col span={3}>
                            <Select
                                label="Start Time"
                                data={intervalOptions}
                                withAsterisk
                                withinPortal
                                {...form.getInputProps('startTime')}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <NumberInput
                                label="Duration"
                                withAsterisk
                                defaultValue={15}
                                min={15}
                                step={15}
                                {...form.getInputProps('duration')}
                            />
                        </Grid.Col>
                    </Grid>

                    <Box mt={'lg'}>
                        <TorbuEditor editor={torbuEditor} />
                    </Box>
                    <Group position={'bottom left'}>
                        <Box mt={15}>
                            <Checkbox
                                label={'Save topic in library'}
                                {...form.getInputProps('saveAsTemplate')}
                            />
                        </Box>
                    </Group>
                    {isEmpty(segment) ? (
                        <>
                            <Can create={'event'}>
                                <Group position={'right'}>
                                    <ButtonCreate onClick={form.onSubmit(saveSegment)}>Save Topic</ButtonCreate>
                                </Group>
                            </Can>
                        </>
                    ) : (
                        <>
                            <Group position={'center'}>
                                <Can create={'event'}>
                                    <ButtonArchive
                                        modalTitle={'Are you sure you want to archive this segment?'}
                                        onConfirm={() => {
                                            archiveSegmentMutation.mutate({ id: segment.id })
                                        }}
                                        onCancel={() => {
                                            openModal()
                                        }}
                                    >
                                        Archive Topic
                                    </ButtonArchive>
                                </Can>
                                <Can create={'event'}>
                                    <ButtonEdit onClick={form.onSubmit(saveSegment)}>Save Topic</ButtonEdit>
                                </Can>
                            </Group>
                        </>
                    )}
                </Tabs.Panel>

                <Tabs.Panel
                    value="library"
                    pt="xs"
                >
                    {segmentTemplates.length ? (
                        <>
                            <Text mb={16}>Select a topic from a previously saved topic.</Text>

                            {segmentTemplates.map(segmentTemplate => (
                                <Paper
                                    key={segmentTemplate.id}
                                    withBorder
                                    shadow
                                    p={'sm'}
                                    bg={'#F6F7F8'}
                                    mb={16}
                                >
                                    <Grid>
                                        <Grid.Col span={5}>
                                            <Text
                                                size={'md'}
                                                fw={'bold'}
                                            >
                                                Title:
                                            </Text>
                                            <Text
                                                size={'md'}
                                                mb={8}
                                            >
                                                {segmentTemplate.title}
                                            </Text>
                                            <Text
                                                size={'md'}
                                                fw={'bold'}
                                            >
                                                Description:
                                            </Text>
                                            <Text
                                                size={'sm'}
                                                dangerouslySetInnerHTML={{ __html: segmentTemplate.description }}
                                            />
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <Text
                                                size={'md'}
                                                fw={'bold'}
                                            >
                                                Start Time:
                                            </Text>
                                            <Text
                                                size={'md'}
                                                mb={8}
                                            >
                                                {moment(event.startTime).add(segmentTemplate.minutesFromEventStart, 'minutes').format('MMMM DD @ hh:mm a')}
                                            </Text>
                                            <Text
                                                size={'md'}
                                                fw={'bold'}
                                            >
                                                Duration:
                                            </Text>
                                            <Text size={'md'}>{segmentTemplate.duration} minutes</Text>
                                        </Grid.Col>
                                        <Grid.Col span={3}>
                                            <Box ta={'right'}>
                                                <ButtonPrimary
                                                    fullWidth
                                                    onClick={() => {
                                                        torbuEditor.setContents(segmentTemplate.description)
                                                        form.setFieldValue('title', segmentTemplate.title)
                                                        form.setFieldValue('duration', segmentTemplate.duration)
                                                        form.setFieldValue('startTime', moment(event.startTime).add(segmentTemplate.minutesFromEventStart, 'minutes').unix())
                                                        setActiveTab('new')
                                                    }}
                                                >
                                                    Apply Template
                                                </ButtonPrimary>
                                            </Box>
                                            <Box ta={'right'}>
                                                <ButtonArchive
                                                    fullWidth
                                                    modalTitle={'Are you sure you want to archive this segment from your library?'}
                                                    onConfirm={() => {
                                                        archiveSegmentTemplateMutation.mutate({ id: segmentTemplate.id })
                                                    }}
                                                    onClose={openModal}
                                                    leftIcon={<IconTrash size={'1.0rem'} />}
                                                >
                                                    Archive
                                                </ButtonArchive>
                                            </Box>
                                        </Grid.Col>
                                    </Grid>
                                </Paper>
                            ))}
                        </>
                    ) : (
                        <>
                            <Text>No topics have been added to your library</Text>
                        </>
                    )}
                </Tabs.Panel>
            </Tabs>
        </div>
    )
}

export default AgendaModal

import React from "react"
import {Drawer} from '@mantine/core'

export const StrideDrawer = ({title, children, ...rest}) => {
    return (
        <>
            <Drawer.Root
                padding="md"
                position="right"
                size="50%"
                {...rest}
            >
                <Drawer.Overlay />
                <Drawer.Content>
                    <Drawer.Header>
                        <Drawer.Title>
                            {title}
                        </Drawer.Title>
                        <Drawer.CloseButton />
                    </Drawer.Header>
                    <Drawer.Body>
                        {children}
                    </Drawer.Body>
                </Drawer.Content>
            </Drawer.Root>
        </>
    )
}
export default StrideDrawer
import React, {useState} from 'react'
import {Box, ColorSwatch, Group, Modal, Select, Text} from '@mantine/core'
import {isEmpty} from 'lodash'
import StrideLoader from "../../Helpers/StrideLoader";
import useEventTypes from "../../Hooks/Models/useEventTypes";
import EventTypeModal from './EventTypeModal'

const SelectItem = React.forwardRef(
    // eslint-disable-next-line no-unused-vars
    ({ color, label, description, ...others }, ref) => (
        <Box ref={ref} {...others}>
            <Group noWrap>
                <ColorSwatch color={color} />
                <Box>
                    <Text size="sm">{label}</Text>
                </Box>
            </Group>
        </Box>
    )
)

export const EventTypeForm = ({form, accountIds}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const eventTypesModel = useEventTypes({accountIds: accountIds[0]})

    const options = () => {
        let options = eventTypesModel.data.map(
            (eventType) => {
                return {
                    value: eventType.id,
                    label: eventType.title,
                    color: eventType.color
                }
            }
        )

        options.push(
            {
                value: 'add-new-event-type',
                label: 'Add new event type'
            }
        )

        return options
    }

    const handleChange = (value) => {
        if(value === 'add-new-event-type'){
            setModalOpen(true)
        }else{
            form.setFieldValue('eventTypeId', value)
        }
    }

    const handleNewEventType = (id) => {
        eventTypesModel.invalidate().then(() => {
            form.setFieldValue('eventTypeId', id)
            setModalOpen(false)
        })
    }

    return (
        <>
            <StrideLoader loading={eventTypesModel.isLoading}>
                <Select
                    label="Category"
                    placeholder="Select the category for this event"
                    itemComponent={SelectItem}
                    data={options()}
                    required
                    {...form.getInputProps('eventTypeId')}
                    onChange={handleChange}
                    disabled={isEmpty('attendees')}
                />

                <Modal
                    opened={modalOpen}
                    onClose={() => setModalOpen(false)}
                    title="Event Type Management"
                    size="md"
                >
                    <EventTypeModal handleNewEventType={handleNewEventType} />
                </Modal>
            </StrideLoader>
        </>
    )
}

export default EventTypeForm
import moment from 'moment';
import React, { useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router';
import Loader from '../../../Components/Loader';
import useCalendar from '../../../Hooks/useCalendar';
import usePermission from '../../../Hooks/usePermission';
import '../../../Theme/css/calendar.css';
import { MultiSelect } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import useAccountDirectory from '../../../Helpers/AccountDirectory';
import AccountsWithAvatars from '../../../Helpers/AccountsWithAvatars';
const localizer = momentLocalizer(moment)

export const Calendar = ({selectedTeams, setSelectedTeams}) => {
    const accountDirectory = useAccountDirectory()
    const navigate = useNavigate()
    const {hasPermissionAnywhere} = usePermission()
    const calendar = useCalendar()
    const [isMultiselectActive, setMultiselectActive] = useState(false);

    const handleOnSelectEvent = event => {
        if (!isMultiselectActive) {
            if (event.id && hasPermissionAnywhere('event:view:v2')) {
                navigate(`/dashboard/${event.id}/view`)
            }
        }
    }

    const handleOnSelectSlot = slot => {
        if (!isMultiselectActive) {
            if (slot.start && slot.end && hasPermissionAnywhere('event:view:v2')) {
                const startDate = moment(slot.start).utc().unix()
                const endDate = moment(slot.end).utc().unix()
                navigate(`/events/new?startDate=${startDate}&endDate=${endDate}`)
            }
        }
    }

    const handleMultiselectChange = (items) => {
        setSelectedTeams(items);
        calendar.setTeams(items);
    };

    return (
        <>
            <div className="h-full" data-intercom-target="Dashboard Calendar"> 
                <MultiSelect
                    icon={<IconUsers size={14} />}
                    label="Filter"
                    data={accountDirectory}
                    value={selectedTeams}
                    placeholder="Select the teams you want to see"
                    itemComponent={AccountsWithAvatars}
                    searchable
                    withinPortal
                    onDropdownOpen={() => setMultiselectActive(true)}
                    onDropdownClose={() => setMultiselectActive(false)}
                    onChange={handleMultiselectChange}
                    mb={10}
                />
                <Loader
                    loading={calendar.isLoading}
                    label="Loading Events"
                >
                    <BigCalendar
                        localizer={localizer}
                        events={calendar.events || []}
                        date={calendar.date}
                        view={calendar.view}
                        onView={calendar.setView}
                        onNavigate={calendar.setDate}
                        onSelectSlot={handleOnSelectSlot}
                        onSelectEvent={handleOnSelectEvent}
                        selectable={hasPermissionAnywhere('event:create:v2')}
                        eventPropGetter={event => {
                            if (event?.eventType?.color) {
                                return {
                                    style: {
                                        borderTopColor: event.eventType.color,
                                        borderTopWidth: '3px'
                                    }
                                }
                            }
                        }}
                        startAccessor={event => {
                            return new Date(event.startTime)
                        }}
                        endAccessor={event => {
                            return new Date(event.endTime)
                        }}
                    />
                </Loader>
            </div>
        </>
    )
};

export default Calendar

import { atom, selector } from "recoil"
import { recoilPersist } from "recoil-persist"
import { createCalendarEngine } from "./utils"

const { persistAtom } = recoilPersist({ key: "Stride-Data" })

const state = {
    activityStatus: atom({
        key: 'activityStatus',
        default: null,
        effects_UNSTABLE: [persistAtom]
    }),
    activeChatChannel: atom({
        key: 'activeChatChannel',
        default: null,
        effects_UNSTABLE: [persistAtom]
    }),
    calendarEngine: atom({
        key: 'calendarEngine',
        default: createCalendarEngine(),
        effects_UNSTABLE: [persistAtom]
    }),
    sessionId: atom({
        key: 'sessionId',
        default: null,
        effects_UNSTABLE: [persistAtom]
    }),
    currentUser: atom({
        key: 'currentUser',
        default: {},
        effects_UNSTABLE: [persistAtom]
    }),
    currentAccount: atom({
        key: 'currentAccount',
        default: {},
        effects_UNSTABLE: [persistAtom]
    }),
    accountSettingsTab: atom({
        key: 'accountSettingsTab',
        default: 'account',
        effects_UNSTABLE: [persistAtom]
    }),
    notifications: (() => {
        const notificationsAtom = atom({
            key: 'notifications',
            default: [],
            effects_UNSTABLE: [persistAtom]
        })
        let currentNotifications = undefined
        return selector({
            key: 'notificationsSelector',
            get({ get }) {
                return get(notificationsAtom)
            },
            set({ get, set }, action) {
                if (currentNotifications === undefined) currentNotifications = get(notificationsAtom)
                const newState = action.clear ? [] : [...currentNotifications]
                if (action.add) newState.unshift(action.add)
                if (action.delete) {
                    let i = newState.findIndex(el => el === action.delete)
                    if (i < 0) return
                    newState.splice(i, 1)
                }
                set(notificationsAtom, newState)
                currentNotifications = newState
            }
        })
    })()
}

export default state

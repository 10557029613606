import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { ErrorHandler } from '../../Components/ErrorHandler'
import ExternalSiteWrapper from './ExternalSiteWrapper'
import { LoginMarketing } from './Marketing/LoginMarketing'
import ChangePassword from './Pages/ChangePassword'
import ForgotPassword from './Pages/ForgotPassword'
import Login from './Pages/Login'
import Register from './Pages/Register'
import RegisterSelector from './Pages/RegisterSelector'
import SignUp from './Pages/SignUp'

export const ExternalManager = () => {
    const location = useLocation()
    const [page, setPage] = useState('login')
    const [errorMessage, setErrorMessage] = useState({ title: '', errors: [] })
    const [sharedData, setSharedData] = useState({})

    useEffect(() => {
        if (location.pathname === '/signup') {
            setPage('signup')
        }
        if (location.pathname === '/login') {
            setPage('login')
        }
    }, [])

    const pages = {
        login: {
            content: (
                <Login
                    page={page}
                    setPage={setPage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sharedData={sharedData}
                    setSharedData={setSharedData}
                />
            ),
            marketing: <LoginMarketing />
        },
        registerSelect: {
            content: (
                <RegisterSelector
                    page={page}
                    setPage={setPage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sharedData={sharedData}
                    setSharedData={setSharedData}
                />
            ),
            marketing: <LoginMarketing />
        },
        register: {
            content: (
                <Register
                    page={page}
                    setPage={setPage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sharedData={sharedData}
                    setSharedData={setSharedData}
                />
            ),
            marketing: <LoginMarketing />
        },
        signup: {
            content: (
                <SignUp
                    page={page}
                    setPage={setPage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sharedData={sharedData}
                    setSharedData={setSharedData}
                />
            ),
            marketing: <LoginMarketing />
        },
        forgot: {
            content: (
                <ForgotPassword
                    page={page}
                    setPage={setPage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sharedData={sharedData}
                    setSharedData={setSharedData}
                />
            ),
            marketing: <LoginMarketing />
        },
        change: {
            content: (
                <ChangePassword
                    page={page}
                    setPage={setPage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    sharedData={sharedData}
                    setSharedData={setSharedData}
                />
            ),
            marketing: <LoginMarketing />
        }
    }

    return (
        <ExternalSiteWrapper marketing={pages[page].marketing}>
            <ErrorHandler errors={errorMessage} />
            {pages[page].content}
        </ExternalSiteWrapper>
    )
}

export default ExternalManager

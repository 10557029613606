import { Alert,Box,Divider,Grid,Group,MultiSelect,Paper,Text,Tooltip,useMantineTheme } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import { filter,isEmpty } from 'lodash'
import Papa from 'papaparse'
import React,{ useEffect,useState } from 'react'
import { CSVLink } from 'react-csv'
import { useMutation } from 'react-query'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Can from '../../Helpers/Can'
import Icon from '../../Helpers/Icon'
import StrideLoader from '../../Helpers/StrideLoader'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import graphqlClientUpload from '../../Utils/graphqlClientUpload'
import queryClient from '../../Utils/queryClient'
import CSVColumn from './CSVColumn'

const csvData = [['firstName', 'lastName', 'email', 'phone']]
const detectors = {
    firstName: ['^(f|F)irst', '^(f|F)irst(n|N)ame', '^(f|F)irst (n|N)ame', '^(g|G)iven', '^(f|F)orename', '^(g|G)iven (n|N)ame', '^(f|F)name'],
    lastName: ['^(l|L)ast', '(l|L)ast(n|N)ame', '(l|L)ast (n|N)ame', '^(s|S)urname', '^(f|F)amily (n|N)ame', '^(l|L)name'],
    email: ['^(e|E)mail', '(e|E)mail', '^(e|E)mail (a|A)ddress', '^(a|A)ddress', '^(e|E)-?mail', '^(e|E)mail (a|A)ddress', '^(e|E)-?mail (a|A)ddress', '^(p|P)layer (e|E)-?mail (a|A)ddress'],
    phone: ['^(p|P)hone', '(p|P)hone', '^(n|N)umber', '^(p|P)hone (n|N)umber', '^(t|T)elephone', '^(c|C)ell', '^(m|M)obile']
}

export const CSVManager = ({ setUserDrawer, account, roles }) => {
    const theme = useMantineTheme()
    const notify = useNotify()

    const [isDisabled, setIsDisabled] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [uploadFile, setUploadFile] = useState(null)
    const [uploadURL, setUploadURL] = useState('')
    const [csvContents, setCsvContents] = useState({})
    const [columns, setColumns] = useState([])
    const [roleIds, setRoleIds] = useState([])

    const uploadUserCSVGQL = useGQL('uploadUserCSV')
    const uploadUserCSVMutation = useMutation(data => graphqlClientUpload.request(uploadUserCSVGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllAccountUsers'] }).then(() => {
                notify.uploaded('CSV')
                setIsSaving(false)
                setUserDrawer({})
            })
        },
        onError: error => {
            setIsSaving(false)
            console.error(error)
            notify.error()
        }
    })

    const resetUploader = () => {
        setCsvContents({})
        setColumns([])
        setUploadFile(null)
        setUploadURL('')
        setRoleIds([])
        setIsSaving(false)
        setIsDisabled(true)
    }

    const uploadCSV = () => {
        setIsSaving(true)
        const data = {
            accountId: account.id,
            roleIds: roleIds,
            file: uploadFile,
            columnMaps: filter(columns, col => col.columnMapped !== 'ignore')
        }
        uploadUserCSVMutation.mutate(data)
    }

    // eslint-disable-next-line no-unused-vars
    const downloadFileFromURL = () => {
        parseFile(`https://cors.torbu.com/${uploadURL}`, true)
    }

    // eslint-disable-next-line no-unused-vars
    const handleURLChange = e => {
        e.preventDefault()
        const url = e.currentTarget.value
        if (url.length) {
            setUploadURL(url)
        } else {
            setUploadURL(false)
        }
    }

    const autoDetectHeaders = data => {
        const headers = data[0]
        const headerDetectResults = []
        const matchedHeaders = []

        headers.forEach((header, index) => {
            let columnPreview = []
            let matchedOnDetector = null

            for (let j = 1; j <= Math.min(3, data.length) && j < data.length; j++) {
                columnPreview.push(data[j][index])
            }

            for (const [detectorName, detectorVariations] of Object.entries(detectors)) {
                for (const detectorVariation of detectorVariations) {
                    const regex = new RegExp(detectorVariation, 'i')

                    if (regex.test(headers[index]) && !matchedHeaders.includes(detectorName)) {
                        matchedHeaders.push(detectorName)
                        matchedOnDetector = detectorName
                    }
                }
            }
            const result = {
                headerColumnIndex: index,
                headerColumnValue: header,
                columnPreview: columnPreview.join(', '),
                matchedOnDetector: matchedOnDetector,
                selectedColumnMatch: matchedOnDetector ? matchedOnDetector : 'ignore'
            }
            headerDetectResults.push(result)

            setColumns(oldColumns => [
                ...oldColumns,
                {
                    columnIndex: index,
                    columnMapped: result.selectedColumnMatch
                }
            ])
        })

        return headerDetectResults
    }

    const parseFile = (file, isExternalUrl = false) => {
        Papa.parse(file, {
            header: false,
            skipEmptyLines: true,
            download: isExternalUrl,
            complete: results => {
                if (!results.errors.length) {
                    results.headerDetails = {
                        headers: results.data[0],
                        headerDetections: autoDetectHeaders(results.data)
                    }
                }
                setCsvContents(results)
            }
        })
    }

    const handleFileUpload = files => {
        if (files.length) {
            setUploadFile(files[0])
            parseFile(files[0], false)
        }
    }

    useEffect(() => {
        const columnsThatAreSet = filter(columns, col => col.columnMapped !== 'ignore')
        setIsDisabled(!(roleIds.length !== 0 && columnsThatAreSet.length >= 3 && columnsThatAreSet.length <= 4))
    }, [columns, roleIds])

    return (
        <>
            {isEmpty(csvContents) && (
                <>
                    <Box>
                        <Text
                            size={'lg'}
                            my={16}
                        >
                            Bulk Import Users via .csv files
                        </Text>

                        <Group grow>
                            <Box>
                                <Text size={'sm'}>To begin, you have three options to upload your data. You can either drag and drop your CSV file into the designated area, click on the upload area, or browse for the file on your computer.</Text>

                                <Text
                                    size={'sm'}
                                    mt={8}
                                >
                                    After you have uploaded the file, we will review the headers, columns, and rows to make sure they meet our criteria. Once confirmed, you can map the required columns, and we will upload all your users for you.
                                </Text>
                            </Box>
                            <Box ta={'center'}>
                                <CSVLink
                                    filename={'torbu-example-user-upload.csv'}
                                    data={csvData}
                                >
                                    <Icon
                                        icon={'FileText'}
                                        size={60}
                                    />
                                    <Text
                                        size={'md'}
                                        color={'dark'}
                                        underline
                                    >
                                        Download CSV data structure (.csv)
                                    </Text>
                                </CSVLink>
                            </Box>
                        </Group>
                    </Box>

                    <Divider my={32} />

                    <Dropzone
                        onDrop={handleFileUpload}
                        onReject={files => console.log('rejected files', files)}
                        accept={['text/csv']}
                        maxFiles={1}
                    >
                        <Group
                            position="center"
                            spacing="xl"
                            style={{ minHeight: 220, pointerEvents: 'none' }}
                        >
                            <Dropzone.Accept>
                                <Box ta={'center'}>
                                    <Icon
                                        icon={'CloudUpload'}
                                        size={50}
                                        stroke={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />

                                    <Text size="xl">Drag .csv here or click to select the .csv on your device.</Text>
                                </Box>
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <Icon
                                    icon={'X'}
                                    size={50}
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <Box ta={'center'}>
                                    <Icon
                                        icon={'CloudUpload'}
                                        size={50}
                                        stroke={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />

                                    <Text size="xl">Drag .CSV here or click to select the .csv on your device.</Text>
                                </Box>
                            </Dropzone.Idle>
                        </Group>
                    </Dropzone>

                    {/*<Divider my={32} labelPosition={'center'} label={'OR '} variant="dashed"/>*/}

                    {/*<TextInput*/}
                    {/*    icon={<Icon icon={'Link'}/> }*/}
                    {/*    label={'Upload CSV from URL'}*/}
                    {/*    placeholder={'Enter full URL for CSV'}*/}
                    {/*    onChange={handleURLChange}*/}
                    {/*/>*/}

                    {/*<Group mt={16} position={'right'}>*/}
                    {/*    <ButtonPrimary*/}
                    {/*        disabled={isEmpty(uploadURL)}*/}
                    {/*        leftIcon={<Icon icon={'CloudUpload'} color={'white'}/>}*/}
                    {/*        onClick={downloadFileFromURL}*/}
                    {/*    >*/}
                    {/*        Upload CSV*/}
                    {/*    </ButtonPrimary>*/}
                    {/*</Group>*/}

                    <Divider my={32} />
                </>
            )}

            {!isEmpty(csvContents) && (
                <>
                    <Box>
                        <Text
                            size={'lg'}
                            my={16}
                        >
                            Map Columns
                        </Text>

                        <Text size={'md'}>Match the columns that you have in your CSV with the our respective columns.</Text>
                    </Box>

                    <Divider my={32} />

                    <Box>
                        <Paper
                            p={'md'}
                            withBorder
                            bg={'#eeeeee'}
                        >
                            <Grid align>
                                <Grid.Col span={1}>
                                    <Tooltip
                                        label={'Match'}
                                        withinPortal
                                        position={'bottom'}
                                        withArrow
                                    >
                                        <Text
                                            size={'sm'}
                                            fw={'bold'}
                                            lineClamp={1}
                                        >
                                            Match
                                        </Text>
                                    </Tooltip>
                                </Grid.Col>
                                <Grid.Col span={3}>
                                    <Tooltip
                                        label={'Column from File'}
                                        withinPortal
                                        position={'bottom'}
                                        withArrow
                                    >
                                        <Text
                                            size={'sm'}
                                            fw={'bold'}
                                            lineClamp={1}
                                        >
                                            File Column
                                        </Text>
                                    </Tooltip>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Tooltip
                                        label={'Column Values from File'}
                                        withinPortal
                                        position={'bottom'}
                                        withArrow
                                    >
                                        <Text
                                            size={'sm'}
                                            fw={'bold'}
                                            lineClamp={1}
                                        >
                                            Column Values
                                        </Text>
                                    </Tooltip>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Tooltip
                                        label={'Mapped Header Attribute'}
                                        withinPortal
                                        position={'bottom'}
                                        withArrow
                                    >
                                        <Text
                                            size={'sm'}
                                            fw={'bold'}
                                            lineClamp={1}
                                        >
                                            Mapped Torbu Column
                                        </Text>
                                    </Tooltip>
                                </Grid.Col>
                            </Grid>
                        </Paper>

                        <StrideLoader
                            loading={isSaving}
                            label={'Uploading CSV... Do not navigate from this page.'}
                        >
                            {csvContents.headerDetails.headerDetections.map((column, index) => (
                                <Box key={column.columnMapped + String(index)}>
                                    <CSVColumn
                                        column={column}
                                        columns={columns}
                                        setColumns={setColumns}
                                        index={index}
                                    />
                                </Box>
                            ))}

                            <Paper
                                withBorder
                                p={'lg'}
                                bg={'#F6F7F8'}
                                my={32}
                            >
                                <Text>Select the role(s) that you would like to assign to these users.</Text>

                                <MultiSelect
                                    mt={15}
                                    icon={
                                        <Icon
                                            icon={'Lock'}
                                            size={16}
                                        />
                                    }
                                    data={roles.map(role => {
                                        return {
                                            label: role.label,
                                            value: role.value
                                        }
                                    })}
                                    withAsterisk
                                    withinPortal
                                    searchable
                                    clearable
                                    onChange={setRoleIds}
                                />
                            </Paper>

                            <Paper
                                withBorder
                                p={'lg'}
                                bg={'#F6F7F8'}
                                my={32}
                            >
                                <Text size={'xl'}>
                                    <Text
                                        fw={'bold'}
                                        span
                                    >
                                        {' '}
                                        {csvContents.data.length - 1}{' '}
                                    </Text>
                                    Total User(s) will be uploaded with this import.
                                </Text>
                            </Paper>

                            {isDisabled && (
                                <Alert
                                    my={16}
                                    icon={
                                        <Icon
                                            icon={'AlertCircle'}
                                            size="1rem"
                                            color={'red'}
                                        />
                                    }
                                    color="red"
                                    variant="outline"
                                >
                                    To upload a CSV file you MUST select at least the First Name, Last Name, and Email Address columns as well as selected at least 1 role. Phone Number is optional
                                </Alert>
                            )}
                        </StrideLoader>
                    </Box>

                    <Can manage={'team'}>
                        <Group position={'apart'}>
                            <ButtonPrimary
                                onClick={resetUploader}
                                disabled={isSaving}
                            >
                                Upload a new CSV file
                            </ButtonPrimary>
                            <ButtonPrimary
                                onClick={uploadCSV}
                                saving={isSaving}
                                disabled={isDisabled}
                            >
                                Bulk Import User(s)
                            </ButtonPrimary>
                        </Group>
                    </Can>
                </>
            )}
        </>
    )
}

export default CSVManager

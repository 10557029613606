import { Disclosure } from '@headlessui/react'
import React from 'react'
import { useRecoilValue } from 'recoil'
import usePermission from '../../Hooks/usePermission'
import state from '../../Utils/State'
import { HiBars3, HiXMark } from 'react-icons/hi2'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { TorbuAvatar } from '../../Components/TorbuAvatar'
import IconLogoGreen from '../images/icon_logo_green.svg'
import NotificationMenu from './NotificationMenu'
import TorbuMenu from '../../Components/TorbuMenu'

const navigationMenu = [
    {
        to: '/',
        title: 'Teamspace',
        // Teamspace renders by default with individual calendar events only
    },
    {
        to: '/chat',
        title: 'Messages',
        requiredPermission: 'channel:view'
    },
    {
        to: '/account-manager/accounts',
        title: 'Teams',
        // Teams tab renders view only by default
    },
    {
        to: '/admin/accounts',
        title: 'Admin',
        requiredPermission: 'torbuAdmin' // Only renders if torbuAdmin
    }
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export const ThemeHeader = ({showHeader}) => {
    const currentAccount = useRecoilValue(state.currentAccount)
    const currentUser = useRecoilValue(state.currentUser)
    const navigate = useNavigate()
    const { hasPermissionAnywhere } = usePermission()

    return (
        <div className={'relative z-trial-banner'}>
            <Disclosure
                as="nav"
                className="shadow bg-torbu-peach"
            >
                {({ open }) => (
                    <>
                        <div className="px-2 sm:px-4 lg:px-4">
                            {/* {currentAccount.trialExpiresAt && (
                                <div className={'absolute bottom-0 right-1/2 translate-x-1/2 translate-y-full rounded-bl-md rounded-br-md bg-torbu-silver px-4 py-1 z-[-1]'}>
                                    {(() => {
                                        const billingLink = (
                                            <span className={'text-torbu-peach cursor-pointer'} onClick={() => navigate('/account-manager/billing')}>billing</span>
                                        )
                                        return (
                                            currentAccount.isTrialExpired ? (
                                                hasPermissionAnywhere('billing:manage') ? (
                                                    <span>Your trial period has expired. Please go to {billingLink} to purchase.</span>
                                                ) : (
                                                    <span>Your trial period has expired. Please contact your account administrator to purchase.</span>
                                                )
                                            ) : (
                                                hasPermissionAnywhere('billing:manage') ? (
                                                    <span>Your trial period will end on <Moment format="MMMM Do">{currentAccount.trialExpiresAt}</Moment>. Please go to {billingLink} to purchase.</span>
                                                ) : (
                                                    <span>Your trial period will end on <Moment format="MMMM Do">{currentAccount.trialExpiresAt}</Moment>. Please contact your account administrator to purchase.</span>
                                                )
                                            )
                                        )
                                    })()}
                                </div>
                            )} */}
                            <div className="flex justify-between h-16">
                                <div className="flex px-2 lg:px-0">
                                    <div className="flex items-center flex-shrink-0">
                                        <Link to="/">
                                            <img
                                                src={IconLogoGreen}
                                                alt="Torbu"
                                                className="block w-auto h-8 lg:hidden"
                                            />
                                            <img
                                                src={IconLogoGreen}
                                                alt="Torbu"
                                                className="hidden w-auto h-8 lg:block"
                                            />
                                        </Link>
                                    </div>
                                    <div className="hidden uppercase lg:ml-6 lg:flex lg:space-x-6">
                                        {navigationMenu.map(link => {
                                            if (hasPermissionAnywhere(link.requiredPermission) && showHeader !== false) {
                                                return (
                                                    <NavLink
                                                        key={link.to}
                                                        className={({ isActive }) =>
                                                            isActive
                                                                ? 'text-md inline-flex items-center border-b-2 border-torbu-green px-1 pt-1 font-torbu-medium text-torbu-green'
                                                                : 'text-md inline-flex items-center border-b-2 border-transparent px-1 pt-1 font-torbu-medium text-torbu-green hover:border-torbu-green hover:text-gray-900'
                                                        }
                                                        to={link.to}
                                                        data-intercom-target={link.title}
                                                    >
                                                        {link.title}
                                                    </NavLink>
                                                )
                                            } /*else {
                                                return <>test</>
                                            }*/
                                        })}
                                    </div>
                                </div>
                                {/* <div className="flex items-center justify-center flex-1 px-2 lg:ml-6 lg:justify-end">
                                    <div className="w-full max-w-lg lg:max-w-xs">
                                        <label
                                            htmlFor="search"
                                            className="sr-only"
                                        >
                                            Search
                                        </label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <MagnifyingGlassIcon
                                                    className="w-5 h-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                id="search"
                                                name="search"
                                                className="block w-full rounded-md border-0 bg-white  py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-torbu-green sm:text-sm sm:leading-6"
                                                placeholder="Search"
                                                type="search"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="flex items-center lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-torbu-green hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-torbu-green">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <HiXMark
                                                className="block w-6 h-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <HiBars3
                                                className="block w-6 h-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                    <NotificationMenu />
                                    <TorbuMenu className='flex-shrink-0 ml-4'>
                                        <TorbuMenu.Button>
                                            <div className="flex">
                                                <div className="mr-3 text-right text-torbu-green">
                                                    <div className="text-base font-medium ">
                                                        {currentUser.firstName} {currentUser.lastName}
                                                    </div>
                                                    <div className="text-sm font-medium" data-intercom-target="Header Selected Team">{currentAccount.name}</div>
                                                </div>
                                                <div className="" data-intercom-target="Header Profile Icon">
                                                    <TorbuAvatar
                                                        user={currentUser}
                                                        width="10"
                                                    />
                                                </div>
                                            </div>
                                        </TorbuMenu.Button>
                                        <TorbuMenu.Items className='w-48 right-0'>
                                            <TorbuMenu.Item>
                                                <Link
                                                    to="/profile"
                                                    className={'block px-4 py-2 text-sm text-gray-700'}
                                                    title="View your profile"
                                                    data-intercom-target="Header Profile Link"
                                                >
                                                    Your Profile
                                                </Link>
                                            </TorbuMenu.Item>
                                            <TorbuMenu.Item>
                                                <Link
                                                    to="/account-select"
                                                    className={'block px-4 py-2 text-sm text-gray-700'}
                                                    title="Change accounts"
                                                    data-intercom-target="Header Change Account Link"
                                                >
                                                    Change Account
                                                </Link>
                                            </TorbuMenu.Item>
                                            <TorbuMenu.Item>
                                                <Link
                                                    to="/logout"
                                                    className={'block px-4 py-2 text-sm text-gray-700'}
                                                    title="Logout"
                                                    data-intercom-target="Header Logout Link"
                                                >
                                                    Logout
                                                </Link>
                                            </TorbuMenu.Item>
                                        </TorbuMenu.Items>
                                    </TorbuMenu>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="lg:hidden">
                            <div className="pt-2 pb-3 space-y-1">
                                {navigationMenu.map(link => {
                                    if (hasPermissionAnywhere(link.requiredPermission)) {
                                        return (
                                            <NavLink
                                                key={link.to}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? 'block border-l-4 border-torbu-green bg-torbu-green/20 py-2 pl-3 pr-4 text-base font-medium text-torbu-white'
                                                        : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-torbu-green hover:border-torbu-green hover:bg-torbu-green/20 hover:text-torbu-green'
                                                }
                                                to={link.to}
                                            >
                                                {link.title}
                                            </NavLink>
                                        )
                                    }
                                })}
                            </div>
                            <div className="pt-4 pb-3 border-t border-gray-200">
                                <div className="flex items-center px-4">
                                    <div className="flex-shrink-0">
                                        <TorbuAvatar
                                            user={currentUser}
                                            width="10"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-torbu-green">
                                            {currentUser.firstName} {currentUser.lastName}
                                        </div>
                                        <div className="text-sm font-medium text-torbu-green">{currentAccount.name}</div>
                                    </div>
                                    {/* <button
                                        type="button"
                                        className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-torbu-peach focus:ring-offset-2"
                                    >
                                        <span className="sr-only">View notifications</span>
                                        <HiBell
                                            className="w-6 h-6"
                                            aria-hidden="true"
                                        />
                                    </button> */}
                                </div>
                                <div className="mt-3 space-y-1">
                                    <Link
                                        to="/profile"
                                        className={'block px-4 py-2 text-sm text-gray-700'}
                                        title="View your profile"
                                    >
                                        Your Profile
                                    </Link>

                                    <Link
                                        to="/account-select"
                                        className={'block px-4 py-2 text-sm text-gray-700'}
                                        title="Change accounts"
                                    >
                                        Change Account
                                    </Link>

                                    <Link
                                        to="/logout"
                                        className={'block px-4 py-2 text-sm text-gray-700'}
                                        title="Logout"
                                    >
                                        Logout
                                    </Link>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
}
export default ThemeHeader

import { Box, Group,Tooltip } from '@mantine/core';
import React from 'react';
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary';
import Can from "../../Helpers/Can";
import Icon from "../../Helpers/Icon";
import StrideHeader from "../../Helpers/StrideHeader";

export const ChatPanelTop = ({setDrawer}) => {
    const toggleDrawer = () => {
        setDrawer({type: 'channelAdd'})
    }

    return (
        <>
            <Group
                position={'apart'}
                mb={8}
            >
                <StrideHeader
                    header={'Chat'}
                    icon={'Messages'}
                    withBorder
                />
                <Can create={'channel'}>
                    <Tooltip
                        label="Start a new channel with others"
                        position="left"
                        withinPortal
                        withArrow
                    >
                        <Box data-intercom-target="Start New Channel Button">
                            <ButtonPrimary
                                icon={'Chat'}
                                onClick={toggleDrawer}
                            >
                                Start new channel
                            </ButtonPrimary>
                        </Box>
                    </Tooltip>
                </Can>
            </Group>
        </>
    )
}
export default ChatPanelTop

import useCurrentUser from "../Hooks/Models/useCurrentUser";
import useScript from "../Hooks/useScript";

const IntercomProvider = ({children, id}) => {
    const { currentUser } = useCurrentUser()

    useScript('https://widget.intercom.io/widget/' + id, () => {
        window.Intercom('reattach_activator');
        window.Intercom('update', window.intercomSettings);

        window.Intercom("update");

        if (currentUser) {
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: id,
                name: currentUser.fullName,
                email: currentUser.email,
                created_at: currentUser.createdAt, // Signup date as a Unix timestamp
                team_manage: false, // Can the user manage a team
            });
        }
    }, [id, currentUser])

    return children
}

export default IntercomProvider
import { Paper, Text } from '@mantine/core'
import { isEmpty, orderBy, uniqBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Virtuoso } from 'react-virtuoso'
import { useRecoilValue } from 'recoil'
import StrideLoader from '../../Helpers/StrideLoader'
import state from '../../Utils/State'
import Message from './Message'
import usePusher from '../../Hooks/usePusher'

export const ChatMessages = ({ handleLastMessageRead, messageEditorHeight }) => {
    const selectedChannel = useRecoilValue(state.activeChatChannel)
    const messagesScrollRef = useRef(null)

    const [messages, setMessages] = useState([])
    const [messagePanelHeight, setMessagePanelHeight] = useState(520)

    const messagesQuery = useQuery(['getAllMessages', { filter: { channelId: selectedChannel.id } }], {
        onSettled: data => {
            if (data?.getAllMessages?.edges?.length) {
                const uniqueMessages = uniqBy(
                    data.getAllMessages.edges.map(edge => edge.node),
                    'id'
                )

                setMessages(uniqueMessages)
                handleLastMessageRead(uniqueMessages[0])
            } else {
                setMessages([])
            }
        }
    })

    usePusher(subscribePusherEvent => {
        subscribePusherEvent(`private-${selectedChannel.id}`, 'new-message', newMessage => {
            newMessage.createdBy = { id: newMessage.user.id }
            setMessages(oldMessages => [...oldMessages, newMessage])
        })
    }, [selectedChannel.id])

    useEffect(() => {
        if (messageEditorHeight) {
            if (isEmpty(messages) && messagePanelHeight === 520) {
                setMessagePanelHeight(328 + messageEditorHeight)
            } else {
                setMessagePanelHeight(278 + messageEditorHeight)
            }
        } else {
            setMessagePanelHeight(520)
        }
    }, [messageEditorHeight])

    return (
        <>
            <StrideLoader loading={messagesQuery.isLoading}>
                <Paper
                    bg={'#F6F7F8'}
                    withBorder
                    style={{ height: `calc(100vh - ${messagePanelHeight}px)`, overflow: 'hidden', transition: 'height 0.6s ease-out' }}
                    className={'chat-messages'}
                >
                    {messages.length ? (
                        <Virtuoso
                            ref={messagesScrollRef}
                            alignToBottom={true}
                            initialTopMostItemIndex={messages.length - 1}
                            followOutput={'auto'}
                            itemContent={(value, message) => <Message message={message} />}
                            data={orderBy(messages, [obj => new Date(obj.createdAt).getTime()], 'asc')}
                        />
                    ) : (
                        <>
                            <Text
                                ta={'center'}
                                p={'xl'}
                            >
                                No messages in this channel
                            </Text>
                        </>
                    )}
                </Paper>
            </StrideLoader>
        </>
    )
}

export default ChatMessages

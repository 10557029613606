import { Calendar } from '@mantine/dates';
import React from 'react';
import useCalendar from '../../../Hooks/useCalendar';
import { Indicator } from "@mantine/core";
import { calculateDateRange } from '../../../Utils/utils';
import moment from 'moment';

export const MiniCalendar = () => {
    const calendar = useCalendar();

    const handleDateChange = (date) => {
        calendar.setDate(date)
    }

    const renderDay = (date) => {
        const day = date.getDate()

        const hasEvent = calendar.events.some((event) => {
            // Event must start and end on the same date to show
            // Compare full dates to avoid showing in different months
            const eventDateStart = new Date(event.startTime);
            return eventDateStart.getDate() === date.getDate()
                && eventDateStart.getMonth() === date.getMonth()
        });

        return (
            <div>
                {hasEvent ? (
                    <Indicator size={6} color="#FC7C57" offset={-2}>
                        <div>{day}</div>
                    </Indicator>
                ) : (
                    <div>{day}</div>
                )}
            </div>
        );
    };

    return (
        <div data-intercom-target="Minicalendar">
            <Calendar 
                date={calendar.date}
                firstDayOfWeek="sunday"
                onDateChange={handleDateChange}
                getDayProps={date => ({
                    onClick() {
                        handleDateChange(date)
                    }
                })}
                renderDay={renderDay}
            />
        </div>
    );
};

export default MiniCalendar;

import { Box,Group,PasswordInput,PinInput,Popover,Progress,Stack,Text,TextInput } from '@mantine/core'
import { isNotEmpty,useForm } from '@mantine/form'
import React,{ useState } from 'react'
import { useNavigate } from 'react-router'
import Icon from '../../../Helpers/Icon'
import usePasswordStrength,{ getStrength,passwordRequirements } from '../../../Hooks/usePasswordStrength'
import PasswordRequirement from '../../Authentication/PasswordRequirement'

const ChangePassword = ({ page, setPage, errorMessage, setErrorMessage, sharedData, setSharedData }) => {
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)

    const form = useForm({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validate: {
            newPassword: isNotEmpty('New Password is required'),
            confirmPassword: isNotEmpty('Password Confirmation is required')
        }
    })

    const authenticateWithAPI = data => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Stride-Auth': 'web'
            },
            body: JSON.stringify(data)
        }
        fetch(`${process.env.REACT_APP_STRIDE_API_ENDPOINT}/reset-forgotten-password`, requestOptions)
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setLoading(false)
                    setShowSuccess(false)
                    setShowError(true)
                    setErrorMessage({ errors: ['Could not update password. Either your code is incorrect, or your password does not meet our requirements'] })
                } else {
                    setLoading(false)
                    setShowSuccess(true)
                    setShowError(false)
                }
            })
            .catch(error => {
                setLoading(false)
                setShowError(true)
                setErrorMessage({ errors: ['Could not update password. Either your code is incorrect, or your password does not meet our requirements'] })

                console.error('error', error)
            })
    }

    const { password, setPassword, strength } = usePasswordStrength()
    const passwordStrength = getStrength(form?.values?.newPassword)
    const color = passwordStrength === 100 ? 'teal' : passwordStrength > 50 ? 'yellow' : 'red'
    const [popoverOpened, setPopoverOpened] = useState(false)

    const passwordChecks = passwordRequirements.map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(form?.values?.newPassword)}
        />
    ))

    const updatePassword = data => {
        setLoading(true)
        setShowSuccess(false)
        setShowError(false)
        data.email = sharedData.email
        data.code = code
        authenticateWithAPI(data)
    }

    const handleCode = code => {
        if (code.length === 6) {
            setCode(code)
        } else {
            setCode('')
        }
    }

    return (
        <>
            <h1 className="line font-torbu-heading text-3xl leading-8 tracking-tight text-torbu-green dark:text-white sm:leading-3">Validate Code</h1>
            <div className="pb-4 text-sm font-light text-gray-500 dark:text-gray-300">If your email address was found within Torbu, you will be recieving a 6 digit code that you will need to enter below.</div>

            {loading ? (
                <>
                    <div className="h-15 flex w-full flex-col rounded-lg border border-torbu-gray bg-torbu-white p-8 text-center">
                        <div className="mx-auto mb-4">
                            <Icon
                                icon={'Loader'}
                                size={40}
                                className="animate-spin-slow"
                            />
                        </div>
                        <div>Verifying all of your information is correct...</div>
                    </div>
                </>
            ) : (
                <>
                    {showSuccess ? (
                        <>
                            <div className="h-15 flex w-full flex-col rounded-lg border border-green-400 bg-green-200 p-8 text-center font-torbu-content">
                                <div className="mb-4 font-torbu-heading text-lg">Password Updated!</div>
                                <div>Your password has been updated. Please click below to go to the login page.</div>

                                <div className="mt-12">
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setErrorMessage({})
                                            setPage('login')
                                        }}
                                        className="font-torbu-heading text-lg text-torbu-peach hover:underline"
                                    >
                                        Back to Login
                                    </a>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <form onSubmit={form.onSubmit(updatePassword)}>
                                <div className="mb-8">
                                    <PinInput
                                        type={/^[0-9]+/}
                                        inputMode="numeric"
                                        length={6}
                                        size="xl"
                                        position="center"
                                        radius={'md'}
                                        withAsterisk
                                        onChange={handleCode}
                                    />
                                </div>

                                {code && code.length === 6 && (
                                    <>
                                        <Popover
                                            opened={popoverOpened}
                                            position="bottom"
                                            width="target"
                                            transitionProps={{ transition: 'pop' }}
                                        >
                                            <Popover.Target>
                                                <Box
                                                    onFocusCapture={() => setPopoverOpened(true)}
                                                    onBlurCapture={() => setPopoverOpened(false)}
                                                >
                                                    <PasswordInput
                                                        size="sm"
                                                        mb={15}
                                                        placeholder="Enter New Password"
                                                        label="New Password"
                                                        description="To ensure the safety of your account, your password must meet the following requirements: it must be at least six characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
                                                        withAsterisk
                                                        {...form.getInputProps('newPassword')}
                                                    />
                                                </Box>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <Progress
                                                    color={color}
                                                    value={passwordStrength}
                                                    size={5}
                                                    mb="sx"
                                                />

                                                <PasswordRequirement
                                                    label="Includes at least 6 characters"
                                                    meets={form?.values?.newPassword.length > 5}
                                                />
                                                {passwordChecks}
                                            </Popover.Dropdown>
                                        </Popover>
                                        <PasswordInput
                                            size="sm"
                                            placeholder="Retype New Password to confirm"
                                            label="Confirm New Password"
                                            withAsterisk
                                            {...form.getInputProps('confirmPassword')}
                                        />

                                        <div className="my-8">
                                            <button
                                                type="submit"
                                                className="dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg border-2 border-torbu-peach bg-torbu-white px-5 py-2.5 text-center font-torbu-demi-bold text-sm text-torbu-green hover:bg-torbu-peach hover:text-torbu-white focus:outline-none focus:ring-4 focus:ring-torbu-peach dark:bg-torbu-peach"
                                            >
                                                Change Password
                                            </button>
                                        </div>
                                    </>
                                )}
                            </form>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default ChangePassword

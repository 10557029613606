import { Box, Card, Group, Paper, ScrollArea, Text } from '@mantine/core'
import { sortBy } from 'lodash'
import 'moment-timezone'
import React from 'react'
import { Link } from 'react-router-dom'
import ButtonLink from '../../Components/Buttons/ButtonLink'
import Can from '../../Helpers/Can'
import Icon from '../../Helpers/Icon'
import IconText from '../../Helpers/IconText'
import StrideAvatar from '../../Helpers/StrideAvatar'
import AgendaViewer from '../EventAgenda/AgendaViewer'
import MediaDisplay from '../Media/MediaDisplay'
import usePermission from "../../Hooks/usePermission"

const EventViewTab = ({ event }) => {
    const { hasEventEdit } = usePermission()

    const buildAttendeesList = () => {
        return (
            <>
                <ScrollArea.Autosize
                    mah={150}
                    type={'always'}
                    offsetScrollbars
                >
                    {sortBy(event.eventUsers, ['user.firstName', 'user.lastName']).map(eventUser => (
                        <Box
                            key={eventUser.user.id}
                            p={8}
                        >
                            <Group>
                                <StrideAvatar user={eventUser.user} />
                                <Box>
                                    <Text
                                        color={'#363636'}
                                        fw={'bold'}
                                    >
                                        {eventUser.user.firstName} {eventUser.user.lastName}
                                    </Text>
                                    <Text
                                        color={'#4f4f4f'}
                                        fw={'300'}
                                    >
                                        {eventUser.user.email}
                                    </Text>
                                </Box>
                            </Group>
                        </Box>
                    ))}
                </ScrollArea.Autosize>
            </>
        )
    }

    return (
        <Card
            p={0}
            mt={32}
        >
            <IconText
                icon={'Friends'}
                text={'Attendees'}
            />
            <Paper
                withBorder
                mt={8}
                p={'xs'}
                mb={32}
            >
                {buildAttendeesList()}
            </Paper>

            <Can view={'event'}>
                <Group
                    position={'apart'}
                    mb={'xs'}
                >
                    <IconText
                        icon={'ListDetails'}
                        text={'Agenda'}
                    />
                    <Can create={'event'}>
                        {hasEventEdit(event) && (
                            <ButtonLink
                                icon={'Edit'}
                                to={`/events/agenda/${event.id}`}
                            >
                                Agenda Planner
                            </ButtonLink>
                        )}
                    </Can>
                </Group>

                <Paper
                    withBorder
                    pt={16}
                    pl={8}
                    pb={8}
                    mb={32}
                >
                    <ScrollArea.Autosize
                        mah={350}
                        type={'always'}
                        offsetScrollbars
                    >
                        <AgendaViewer event={event} />
                    </ScrollArea.Autosize>
                </Paper>
            </Can>

            <Group
                position={'apart'}
                mb={'xs'}
            >
                <IconText
                    icon={'Paperclip'}
                    text={'Attachments'}
                />
                {hasEventEdit(event) && (
                    <ButtonLink
                        icon={'Upload'}
                        to={`/events/media/${event.id}`}
                    >
                        Upload Attachments
                    </ButtonLink>
                )}

            </Group>
            <Paper
                withBorder
                pt={16}
                pl={8}
                pb={8}
                mb={32}
            >
                <MediaDisplay event={event} />
            </Paper>
            <IconText
                icon={'Location'}
                text={'Location'}
            />
            <Paper
                withBorder
                pt={16}
                pl={8}
                pb={8}
                mb={32}
            >
                {event.location ? (
                    <Text
                        size={'sm'}
                        dangerouslySetInnerHTML={{ __html: event.location }}
                    />
                ) : (
                    <>
                        <Text
                            color={'dimmed'}
                            align={'center'}
                        >
                            No description added.
                        </Text>
                    </>
                )}
            </Paper>
            <IconText
                icon={'Notes'}
                text={'Description'}
            />
            <Paper
                withBorder
                pt={16}
                pl={8}
                pb={8}
                mb={32}
            >
                {event.description ? (
                    <Text
                        size={'sm'}
                        dangerouslySetInnerHTML={{ __html: event.description }}
                    />
                ) : (
                    <>
                        <Text
                            color={'dimmed'}
                            align={'center'}
                        >
                            No description added.
                        </Text>
                    </>
                )}
            </Paper>
        </Card>
    )
}

export default EventViewTab

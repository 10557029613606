import React from 'react'
import { twMerge } from 'tailwind-merge'
import Icon from '../Icon'
import { useTorbuModal } from '../TorbuModal'

export const ButtonArchive = ({
    icon = 'Archive',
    saving,
    classNames = '',
    children, fullWidth, leftIcon,
    onClick,
    modalTitle = 'Are you sure you want to archive this item?',
    onCancel,
    onConfirm,
    onClose,
    modalContent,
    ...other
}) => {
    const { openArchiveModal } = useTorbuModal()

    if (fullWidth) {
        classNames += 'w-full'
    }
    return (
        <button
            className={twMerge(
                'inline-flex items-center justify-center gap-x-1 rounded border-2 border-red-600 bg-red-400 px-4 py-2 text-torbu-white shadow-sm hover:bg-red-600 hover:text-torbu-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400',
                classNames
            )}
            onClick={e => {
                onClick?.(e)
                openArchiveModal({
                    title: modalTitle,
                    content: modalContent,
                    onCancel,
                    onConfirm,
                    onClose
                })
            }}
            {...other}
        >
            {saving ? (
                <>
                    <Icon
                        icon={'Loader'}
                        size={6}
                        className="animate-spin-fast"
                    />
                    Saving
                </>
            ) : (
                <>
                    {icon && (
                        <Icon
                            icon={icon}
                            size={6}
                        />
                    )}

                    {children}
                </>
            )}
        </button>
    )
}
export default ButtonArchive

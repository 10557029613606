import moment from "moment"

// Need to destruct these functions
export const getTotalMinutesFromDateTime = (dateTime) =>
    Number(dateTime.hour() * 60) + Number(dateTime.minute())

export const getHoursMinutesFromMinute = (minute) => {
    if (!minute) return "N/A"

    const minutes = Math.floor(minute % 60)
    const hours = Math.floor(minute / 60)

    return { minutes, hours }
}

export const calculateDateRange = (date, view) => {
    let start = moment(date).startOf('day')
    let end = moment(date).endOf('day')
    const monthStart = moment(start).startOf('month').startOf('week')
    const monthEnd = moment(end).endOf('month').endOf('week')

    switch (view) {
    case 'month': {
        start.startOf('month').startOf('week')
        end.endOf('month').endOf('week')
        break
    }
    case 'agenda': {
        end.add(1, 'month')
        break
    }
    case 'week': {
        start.startOf('week')
        end.endOf('week')
        break
    }
    }
    if (monthStart.isBefore(start)) start = monthStart
    if (monthEnd.isAfter(end)) end = monthEnd
    return [start.toDate(), end.toDate()]
}

export const getTimezone = timezone => {
    if (timezone) {
        if (Intl.supportedValuesOf('timeZone').includes(timezone)) {
            return timezone
        } else {
            throw new Error(`Timezone given: ${timezone} is not in the supported list of timezones from Intl`)
        }
    } else {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
}

export const createCalendarEngine = () => {
    const now = new Date()
    const range = calculateDateRange(now, 'week')
    return {
        date: now,
        view: 'week',
        startDate: range[0],
        endDate: range[1],
        accountIds: [],
        timezone: getTimezone()
    }
}

import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Group, Paper, Text } from '@mantine/core'
import moment from 'moment'
import { filter, isEmpty, isUndefined, sortBy, uniqBy } from 'lodash'
import Can from "../../Helpers/Can"
import usePermission from "../../Hooks/usePermission"
import AgendaModal from './AgendaModal'
import Segment from './Segment'
import IntervalSlot from './Intervals/IntervalSlot'
import IntervalDroppable from './Intervals/IntervalDroppable'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import { useTorbuModal } from '../../Components/TorbuModal'

const settings = {
    timeIncrements: 15,
    timeIncrementsHeight: 35,
    intervalBorderColor: '#e6e7e8',
    intervalBackgroundColor: '#e2eaf6',
    intervalHighlightColor: '#afbacb',
}

const colorsAllowed = [
    "#5f89bd", "#f06595", "#cc5de8", "#51cf66", "#5c7cfa", "#ff922b", "#22b8cf", "#ff6b6b", "#845ef7", "#fcc419",
    "#5f89bd", "#f06595", "#cc5de8", "#51cf66", "#5c7cfa", "#ff922b", "#22b8cf", "#ff6b6b", "#845ef7", "#fcc419",
    "#5f89bd", "#f06595", "#cc5de8", "#51cf66", "#5c7cfa", "#ff922b", "#22b8cf", "#ff6b6b", "#845ef7", "#fcc419"
]

export const AgendaViewer = ({event, editable}) => {
    const { openBlankModal, closeModal } = useTorbuModal()
    const [segments, setSegments] = useState([])
    const {hasPermissionAnywhere} = usePermission()

    // const canEdit = !isUndefined(editable) && hasPermissionAnywhere('event:create:v2')
    const canEdit = !isUndefined(editable)

    useEffect(() => {
        if(!isEmpty(event) && event.segments){
            setSegments(event.segments)
        }
    }, [event])

    const intervals = useMemo(() => {
        const start = moment(event.startTime)
        const end = moment(event.endTime)
        start.minutes(Math.ceil(start.minutes() / settings.timeIncrements) * settings.timeIncrements)
        let result = []
        let current = moment(start)

        while (current <= end) {
            result.push({
                date: current.toDate(),
                key: current.valueOf(),
            })
            current.add(settings.timeIncrements, 'minutes')
        }
        if(result.length > 1){
            result.pop()
        }
        return result
    }, [event])

    const handleSelectedInterval = (segment) => {
        if (hasPermissionAnywhere('event:create:v2') && canEdit) {
            openAgendaModal({
                interval: {
                    interval: segment.interval,
                    assignedUser: segment.assignedUser
                }
            })
        }
    }

    const openAgendaModal = ({
        segment = {},
        interval = {}
    } = {}) => {
        openBlankModal({
            title: 'Topic Builder',
            content: (
                <AgendaModal
                    event={event}
                    segment={segment}
                    selectedInterval={interval}
                    intervals={intervals}
                    openModal={() => {
                        openAgendaModal({
                            segment,
                            interval
                        })
                    }}
                    closeModal={closeModal}
                />
            ),
            modalClassName: 'min-w-[75vw] h-[75vh] overflow-auto'
        })
    }

    const segmentUsers = uniqBy(sortBy(segments.map(segment => segment.assignedUser), ['firstName']), 'id')

    return (
        <>
            <Box>
                {canEdit && (
                    <Group mb={2} position={'apart'}>
                        <Text size={'sm'}>Agenda Planner:</Text>
                        <Box>
                            <Can create={'event'}>
                                {canEdit && (
                                    <ButtonCreate
                                        onClick={() => openAgendaModal()}
                                    >
                                        Create New Topics
                                    </ButtonCreate>
                                )}
                            </Can>
                        </Box>
                    </Group>
                )}
                <Can view={'event'}>
                    <Grid mb={10}>
                        <Grid.Col span={'content'}>
                            <Text h={20}>&nbsp;</Text>
                            <Paper withBorder>
                                {intervals.map(interval => (
                                    <IntervalSlot
                                        key={'timeslot' + interval.key}
                                        interval={interval}
                                        settings={settings}
                                        showTime={true}
                                        canEdit={canEdit}
                                        handleSelectedInterval={handleSelectedInterval}
                                    />
                                ))}
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={10}>
                            {segments.length === 0 ? (
                                <>
                                    <Text h={20}>&nbsp;</Text>
                                    <Paper withBorder>
                                        {intervals.map(interval => (
                                            <IntervalSlot
                                                key={'clickable' + interval.key}
                                                interval={interval}
                                                settings={settings}
                                                canEdit={canEdit}
                                                showTime={false}
                                                handleSelectedInterval={handleSelectedInterval}
                                            />
                                        ))}
                                    </Paper>

                                </>
                            ) : (
                                <>
                                    <Box>
                                        <Grid columns={segmentUsers.length}>
                                            {segmentUsers.map((user, index) => (
                                                <Grid.Col span={1} key={user.id}>
                                                    <Text h={20} size="xs" lineClamp={1} ta={'center'}>
                                                        {user.firstName} {user.lastName}
                                                    </Text>

                                                    <Paper withBorder style={{position: 'relative'}}>
                                                        {intervals.map(interval => (
                                                            <IntervalDroppable
                                                                key={'droppable' + interval.key + user.id}
                                                                interval={interval}
                                                                settings={settings}
                                                                user={user}
                                                                event={event}
                                                                canEdit={canEdit}
                                                                handleSelectedInterval={handleSelectedInterval}
                                                            />
                                                        ))}

                                                        {filter(segments, segment => segment.assignedUser.id === user.id).map((segment) => (
                                                            <Segment
                                                                key={segment.id}
                                                                canEdit={canEdit}
                                                                color={colorsAllowed[index]}
                                                                segment={segment}
                                                                settings={settings}
                                                                event={event}
                                                                onSegmentClick={() => {
                                                                    if (hasPermissionAnywhere('event:create:v2') && canEdit) {
                                                                        openAgendaModal({
                                                                            segment
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        ))}
                                                    </Paper>
                                                </Grid.Col>
                                            ))}
                                        </Grid>
                                    </Box>
                                </>
                            )}
                        </Grid.Col>
                    </Grid>
                </Can>
            </Box>
        </>
    )
}

export default AgendaViewer

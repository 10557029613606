import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import state from '../../Utils/State'
import useGQL from '../useGQL'
import queryClient from '../../Utils/queryClient'
import { isNil } from 'lodash'

const AccountContext = createContext()
const useAccountContext = () => {
    return useContext(AccountContext)
}

export const CurrentAccountProvider = ({ children }) => {
    const [currentAccount, setCurrentAccount] = useRecoilState(state.currentAccount)
    const currentAccountId = currentAccount.id
    const query = useQuery(['getAccount', {
        id: currentAccountId
    }], {
        enabled: !isNil(currentAccountId)
    })
    useEffect(() => {
        if (query.data) setCurrentAccount(query.data.getAccount)
    }, [query.data])

    return (
        <AccountContext.Provider value={{
            currentAccount: query.data?.getAccount || currentAccount,
            setCurrentAccount,
            resetCurrentAccount() {
                if (isNil(currentAccountId)) return
                queryClient.invalidateQueries(['getAccount', {
                    id: currentAccountId
                }])
            }
        }}>
            { children }
        </AccountContext.Provider>
    )
}

const useCurrentAccount = useAccountContext
export default useCurrentAccount

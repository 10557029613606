import { MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { ModalsProvider } from '@mantine/modals'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import React, { Suspense, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import IntercomProvider from '../src/Helpers/IntercomProvider'
import PrivateRoute from '../src/Pages/Authentication/PrivateRoute'
import queryClient from '../src/Utils/queryClient'
import { TorbuModalProvider } from './Components/TorbuModal'
import { CurrentAccountProvider } from './Hooks/Models/useCurrentAccount'
import { useAppTitle } from './Hooks/useAppTitle'
import { PusherProvider } from './Hooks/usePusher'
import { AuthProvider } from './Pages/Authentication/AuthProvider'
import { routes } from './Pages/Navigation/Routes'
import './Theme/css/application.css'
import './Theme/css/custom.css'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://4c18be4c12c04f7eb91b814f91599e82@o4505163345035264.ingest.sentry.io/4505163356372992',
        environment: process.env.NODE_ENV,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}
const StrideApp = () => {
    const setAppTitle = useAppTitle()

    const getSession = () => {
        const data = localStorage.getItem('Stride-Data')
        if (data) {
            return JSON.parse(data).sessionId
        }
        return null
    }

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            setAppTitle('Torbu - DEV MODE - Teams, Timelines, and Collaborations')
        } else {
            setAppTitle('Torbu - Teams, Timelines, and Collaborations')
        }
    }, [])

    const torbuPeach = '#FC7C56'
    const torbuWhite = '#f2ede8'
    const torbuLime = '#d6fc49'
    const torbuGreen = '#035157'
    const torbuBlack = '#1f2322'

    return (
        <Suspense fallback={<></>}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <PusherProvider
                    pusherKey={process.env.REACT_APP_PUSHER_KEY}
                    pusherConfig={{
                        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                        userAuthentication: {
                            endpoint: `${process.env.REACT_APP_STRIDE_API_ENDPOINT}/pusher/user-auth`,
                            headers: {
                                'Stride-Web-Token': getSession()
                            }
                        },
                        channelAuthorization: {
                            endpoint: `${process.env.REACT_APP_STRIDE_API_ENDPOINT}/pusher/auth`,
                            headers: {
                                'Stride-Web-Token': getSession()
                            }
                        }
                    }}
                >
                    <RecoilRoot>
                        <QueryClientProvider client={queryClient}>
                            <AuthProvider>
                                <CurrentAccountProvider>
                                    <IntercomProvider id={process.env.REACT_APP_INTERCOM_ID}>
                                        <DndProvider backend={HTML5Backend}>
                                            <MantineProvider
                                                theme={{
                                                    colorScheme: 'light',
                                                    colors: {
                                                        stride: ['#5f89bd', '#5f89bd', '#5f89bd', '#5f89bd', '#5f89bd', '#2c5bac', '#5f89bd', '#5f89bd', '#5f89bd', '#95b6d8'],
                                                        coolGray: ['#F8FAFB', '#E9EFF2', '#CCD9E2', '#AFC4D1', '#92AEC0', '#7599AF', '#588099', '#466578', '#334A58', '#202F38'],
                                                        gray: ['#ECEEEE', '#E0E3E2', '#C8CDCC', '#B0B8B5', '#98A29F', '#808C88', '#67726E', '#4F5754', '#373C3A', '#1E2221'],
                                                        torbu: [torbuPeach, torbuWhite, torbuLime, torbuGreen, torbuBlack, '#000', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
                                                    },
                                                    primaryColor: 'stride',
                                                    primaryShade: 4,
                                                    headings: {
                                                        sizes: {
                                                            h1: { fontSize: 20 },
                                                            h2: { fontSize: 20 },
                                                            h3: { fontSize: 18 },
                                                            h4: { fontSize: 16 },
                                                            h5: { fontSize: 14 },
                                                            h6: { fontSize: 12 }
                                                        }
                                                    },
                                                    components: {
                                                        '.mantine-Button-inner': {
                                                            color: '#FFF'
                                                        }
                                                    }
                                                }}
                                                withCSSVariables
                                                withNormalizeCSS
                                            >
                                                <DatesProvider
                                                    settings={{
                                                        firstDayOfWeek: 0,
                                                        weekendDays: [0, 6]
                                                    }}
                                                >
                                                    <ModalsProvider>
                                                        <TorbuModalProvider>
                                                            <Toaster
                                                                position="top-right"
                                                                reverseOrder={false}
                                                            />
                                                            <BrowserRouter>
                                                                <Routes>
                                                                    {routes.map(route => (
                                                                        <Route
                                                                            key={route.url}
                                                                            path={route.url}
                                                                            exact={route.exact}
                                                                            element={route.private ? <PrivateRoute permission={route.permission}>{route.element}</PrivateRoute> : <>{route.element}</>}
                                                                        />
                                                                    ))}
                                                                </Routes>
                                                            </BrowserRouter>
                                                        </TorbuModalProvider>
                                                    </ModalsProvider>
                                                </DatesProvider>
                                            </MantineProvider>
                                        </DndProvider>
                                    </IntercomProvider>
                                </CurrentAccountProvider>
                            </AuthProvider>
                        </QueryClientProvider>
                    </RecoilRoot>
                </PusherProvider>
            </GoogleOAuthProvider>
        </Suspense>
    )
}
export default StrideApp

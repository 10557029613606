import { Box } from '@mantine/core'
import { find, isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Header } from '../../Components/Header'
import ApplicationTwoColumns from '../../Helpers/ApplicationTwoColumns'
import IconText from '../../Helpers/IconText'
import AccountView from '../AccountSettings/Accounts/AccountView'
import AccountManager from './Accounts/AccountManager'
import { AccountBilling } from './Accounts/AccountBilling'
import useCurrentAccount from '../../Hooks/Models/useCurrentAccount'
import Can from '../../Helpers/Can'

export const AccountSettings = () => {
    const { page, id } = useParams()
    const { isPrimaryAccount } = useCurrentAccount()

    const links = [
        {
            title: 'View All Teams',
            icon: 'Affiliate',
            to: '/account-manager/accounts',
            content: <AccountManager />
        },
        {
            title: 'Billing',
            icon: 'CreditCard',
            to: '/account-manager/billing',
            content: <AccountBilling />,
            // show: hasPermissionAnywhere('billing:manage'),
            show: false
        }
    ]

    const renderContent = () => {
        if (page === 'accounts' && !isEmpty(id)) {
            return <AccountView />
        } else {
            const content = find(links, link => link.to.includes(page))
            if (content) {
                return content.content
            } else {
                return <></>
            }
        }
    }
    
    return (
        <>
            <Header
                text={'Team Management'}
                icon={'Team'}
            />
            <ApplicationTwoColumns
                leftColumn={
                    <>
                        {links.map(link => (
                            (link.show === undefined || link.show) &&
                                <Box
                                    mb={'sm'}
                                    key={link.title}
                                >
                                    <IconText
                                        icon={link.icon}
                                        text={link.title}
                                        to={link.to}
                                    />
                                </Box>
                        ))}
                    </>
                }
                rightColumn={<>{renderContent()}</>}
            />
        </>
    )
}

export default AccountSettings

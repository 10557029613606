import React, {forwardRef} from 'react'
import {
    Group,
    Text,
} from '@mantine/core'
import StrideAvatar from "./StrideAvatar"

export const UsersWithAvatars = forwardRef(
    ({ user, label, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <StrideAvatar user={user} />
                <div>
                    <Text size="md" fw={300}>{label}</Text>
                </div>
            </Group>
        </div>
    )
)

export default UsersWithAvatars
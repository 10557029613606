/* eslint-disable */

import React, {useEffect, useState} from "react"
import {Avatar, Box, Divider, Group, Text, Tooltip} from '@mantine/core'
import {IconChevronDown, IconHash, IconLayoutGrid} from '@tabler/icons-react'
import {filter, sortBy} from 'lodash'
import {useRecoilValue} from 'recoil'
import Icon from "../../Helpers/Icon";
import useCurrentUser from "../../Hooks/Models/useCurrentUser"
import usePermission from "../../Hooks/usePermission"
import state from '../../Utils/State'

export const ChatPanelHeader = ({setDrawer}) => {
    const selectedChannel = useRecoilValue(state.activeChatChannel)
    const {currentUser} = useCurrentUser()
    const { hasChannelEdit } = usePermission()
    const maxAvatars = 4
    
    const [title, setTitle] = useState('')

    useEffect(() => {
        if(selectedChannel){

            let channelName = selectedChannel.title

            if(selectedChannel.directMessage){
                let channelUsers = sortBy(selectedChannel.channelUsers, ["user.firstName", "user.lastName"])
                channelName = filter(channelUsers, channelUser => channelUser.user.id !== currentUser.id)
                    .map(channelUer => channelUer.user.firstName + ' '+ channelUer.user.lastName)
                    .join(', ')
            }

            setTitle(channelName)
        }
    }, [selectedChannel])

    const handleChannelEditClick =() => {
        setDrawer({type: 'channelEdit'})
    }

    return (
        <>
            <Box py={10}>
                <Group align noWrap>
                    <Box>
                        {selectedChannel.directMessage ? (
                            <Icon icon={'LayoutGrid'} size={24}  />
                        ) : (
                            <Icon icon={'Hash'} size={24} />
                        )}
                    </Box>
                    <Box ml={-10}>
                        <Tooltip
                            label={`${title} and You`}
                            position="bottom"
                            withArrow
                            multiline
                            withinPortal
                            width={220}
                            className={'pointer'}
                        >
                            <Text fsize={'md'} fw={'500'} lineClamp={1}>
                                {title}
                            </Text>
                        </Tooltip>
                    </Box>
                    {hasChannelEdit(selectedChannel) && (
                        <Box ml={-10}>
                            {!selectedChannel.directMessage && (
                                <Tooltip
                                    label={'Edit channel details'}
                                    position="bottom"
                                    withArrow
                                    withinPortal
                                >
                                    <Box
                                        onClick={handleChannelEditClick}
                                        className="pointer"
                                    >
                                        <IconChevronDown />
                                    </Box>
                                </Tooltip>
                        )}
                        </Box>
                    )}
                </Group>
            </Box>

            <Divider mb={16}/>
        </>
    )
}
export default ChatPanelHeader

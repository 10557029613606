import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import NodeTemplate from './NodeTemplate'

export default memo(({ data, isConnectable }) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={false}
            />
            <NodeTemplate account={data} />
            {data.children.length > 0 && (
                <Handle
                    type="source"
                    position={Position.Bottom}
                    isConnectable={false}
                />
            )}
        </>
    )
})

import { Box,Divider,MultiSelect,Radio,Text,TextInput } from '@mantine/core';
import { useForm } from "@mantine/form";
import React,{ useState } from 'react';
import { useMutation } from 'react-query';
import ButtonCreate from '../../../Components/Buttons/ButtonCreate';
import useAccountDirectory from '../../../Helpers/AccountDirectory';
import AccountsWithAvatars from "../../../Helpers/AccountsWithAvatars";
import Icon from "../../../Helpers/Icon";
import StrideLoader from "../../../Helpers/StrideLoader";
import UsersWithAvatars from "../../../Helpers/UsersWithAvatars";
import useUserDirectory from "../../../Hooks//Models/useUserDirectory";
import useCurrentUser from "../../../Hooks/Models/useCurrentUser";
import useGQL from "../../../Hooks/useGQL";
import { useNotify } from '../../../Hooks/useNotify';
import graphqlClient from '../../../Utils/graphqlClient';
import queryClient from '../../../Utils/queryClient';

export const ChannelAdd = ({setDrawer, channelType}) => {
    const {currentUser} = useCurrentUser()
    const [isSaving, setIsSaving] = useState(false)
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            channelType: channelType || '',
            title: '',
            accountIds: [],
            userIds: []
        }
    })

    const accountDirectory = useAccountDirectory('channel:create:v2')
    const userDirectory = useUserDirectory({accountIds: form.values?.accountIds})

    const createChannelGQL = useGQL('createChannel')
    const createChannel = useMutation(
        (data) => graphqlClient.request(createChannelGQL, data),
        {
            onSuccess: () => {
                queryClient
                    .invalidateQueries({ queryKey: ['getAllChannels'] })
                    .then(() => {

                        notify.created('Channel')
                        form.reset()
                        setIsSaving(false)
                        setDrawer({})
                    })
            },
            onError: (error) => {
                setIsSaving(false)
                console.error(error)
                notify.error()
            }
        }
    )

    const save = (data) => {
        setIsSaving(true)
        let channelData = {}

        if(data.channelType === 'groupChannel' && data.accountIds.length && data.title){
            channelData = {
                title: data.title,
                accountIds: data.accountIds,
                userIds: []
            }
        }

        if(data.channelType === 'directMessage' && data.userIds.length){
            channelData = {
                title: 'Direct Message Group',
                accountIds: data.accountIds,
                userIds: [...data.userIds].concat(currentUser.id)
            }
        }
        createChannel.mutate(channelData)
    }

    return (
        <>
            <Divider />
            <form onSubmit={form.onSubmit(save)}>
                <Box
                    mb={25}
                    mt={15}
                >
                    <Radio.Group
                        label="What type of channel would you like to create?"
                        orientation="vertical"
                        withAsterisk
                        {...form.getInputProps('channelType')}
                    >
                        <Radio
                            value="groupChannel"
                            label="Group Channel"
                        />
                        <Radio
                            value="directMessage"
                            label="Direct Message"
                        />
                    </Radio.Group>
                </Box>

                <Divider />

                {form.values?.channelType === 'groupChannel' && (
                    <>
                        <Box mt={15}>
                            <TextInput
                                label="Channel name"
                                icon={
                                    <Icon
                                        icon={'Hash'}
                                        size={14}
                                    />
                                }
                                mb={15}
                                {...form.getInputProps('title')}
                            />

                            <MultiSelect
                                label="Accounts(s) in this channel"
                                placeholder="Select the account(s) to add to this channel"
                                data={accountDirectory}
                                itemComponent={AccountsWithAvatars}
                                icon={
                                    <Icon
                                        icon={'Users'}
                                        size={14}
                                    />
                                }
                                withAsterisk
                                withinPortal
                                searchable
                                {...form.getInputProps('accountIds')}
                            />

                            <ButtonCreate  
                                fullWidth
                                type={'submit'}
                                disabled={isSaving}
                            >
                                Create new Group Channel
                            </ButtonCreate>
                        </Box>
                    </>
                )}

                {form.values?.channelType === 'directMessage' && (
                    <>
                        <Box mt={15}>
                            <Box mb={15}>
                                <MultiSelect
                                    label="Accounts(s) to filter selectable users"
                                    placeholder="Select the accounts(s) to filter selectable users"
                                    icon={
                                        <Icon
                                            icon={'Users'}
                                            size={14}
                                        />
                                    }
                                    data={accountDirectory}
                                    itemComponent={AccountsWithAvatars}
                                    withAsterisk
                                    withinPortal
                                    searchable
                                    {...form.getInputProps('accountIds')}
                                />
                            </Box>

                            {form.values?.accountIds.length > 0 && (
                                <Box mb={15}>
                                    <StrideLoader
                                        loading={userDirectory.isLoading}
                                        label={'Loading Users...'}
                                    >
                                        <MultiSelect
                                            label={'Direct Message Users:'}
                                            data={userDirectory.renderOptions() || []}
                                            itemComponent={UsersWithAvatars}
                                            limit={100}
                                            required
                                            withinPortal
                                            searchable
                                            clearable
                                            {...form.getInputProps('userIds')}
                                        />
                                    </StrideLoader>
                                </Box>
                            )}

                            <ButtonCreate
                                type={'submit'}
                                fullWidth
                                saving={isSaving}
                            >
                                Create new Direct Message channel
                            </ButtonCreate>
                        </Box>
                    </>
                )}

                <Text
                    mb={25}
                    fw={300}
                    size={'sm'}
                    my={'xs'}
                >
                    Torbu supports two types of channels:
                </Text>

                <Text
                    mb={25}
                    fw={300}
                    size={'sm'}
                    my={'xs'}
                >
                    <Text
                        mb={25}
                        fw={'bold'}
                        span
                    >
                        Group Channels:
                    </Text>{' '}
                    Everyone will be added to the channel based on the group(s) that you select. No specific users can be added or removed. When new members become part of the team they are added to the Group Channel automatically.
                </Text>

                <Text
                    mb={25}
                    fw={300}
                    size={'sm'}
                >
                    <Text
                        mb={25}
                        fw={'bold'}
                        span
                    >
                        Direct Messages
                    </Text>{' '}
                    This type of channel supports a list of individual users. New users will not be automatically assigned to these channels
                </Text>
            </form>
        </>
    )
}
export default ChannelAdd

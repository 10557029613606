/* eslint-disable */

import { Badge,Group,HoverCard,Text } from '@mantine/core'
import { isEmpty,sortBy } from 'lodash'
import React,{ useMemo,useState } from 'react'
import ButtonPrimary from '../../Components/Buttons/ButtonPrimary'
import Can from '../../Helpers/Can'
import StrideAvatar from '../../Helpers/StrideAvatar'
import StrideTable from '../../Helpers/StrideTable'
import usePermission from '../../Hooks/usePermission'
import UserEditDrawer from '../UserManager/UserEditDrawer'

export const UsersTable = ({ account, accountUsersModel }) => {
    const { hasPermissionAnywhere } = usePermission()

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [drawerUser, setDrawerUser] = useState({})
    const [users, setUsers] = useState([])

    const columns = useMemo(() => {
        return [
            {
                id: 'firstName',
                name: 'User',
                selector: row => row.user.firstName,
                sortable: true,
                cell: row => (
                    <Group>
                        <StrideAvatar user={row.user} />
                        <Text>
                            {row.user.firstName} {row.user.lastName}
                        </Text>
                    </Group>
                )
            },
            {
                id: 'roles',
                name: 'Role(s)',
                reorder: true,
                cell: row => (
                    <>
                        {row.roles.length ? (
                            <>
                                {sortBy(row.roles, ['name']).map(role => (
                                    <Badge
                                        variant="filled"
                                        mr={8}
                                        key={role.id}
                                    >
                                        <Text>{role.name}</Text>
                                    </Badge>
                                ))}
                            </>
                        ) : (
                            <>
                                <HoverCard
                                    width={280}
                                    shadow="md"
                                    withinPortal
                                >
                                    <HoverCard.Target>
                                        <Badge
                                            variant="filled"
                                            mr={8}
                                            color={'red'}
                                        >
                                            <Text>PENDING REGISTRATION</Text>
                                        </Badge>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Text size="lg">Pending Registration:</Text>

                                        <Text
                                            size="sm"
                                            mt={16}
                                        >
                                            Users that have been invited to the Torbu platform remain in a Pending Registration status until they login for the first time.
                                        </Text>
                                        <Text
                                            size="sm"
                                            mt={8}
                                        >
                                            Once the user successfully registers and logs in only then will they be available to be used throughout the platform.
                                        </Text>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </>
                        )}
                    </>
                )
            },
            {
                id: 'manage',
                name: '',
                cell: row => (
                    <>
                        <Can manage={'team'}>
                            <ButtonPrimary data-intercom-target="Manage User Button"
                                onClick={() => handleRowClick(row)}
                            >
                                Manage
                            </ButtonPrimary>
                        </Can>
                    </>
                ),
                button: true
            }
        ]
    }, [])

    const handleRowClick = row => {
        if (hasPermissionAnywhere('team:manage')) {
            setDrawerUser(row)
            setDrawerOpen(true)
        }
    }

    return (
        <>
            <StrideTable
                columns={columns}
                data={accountUsersModel.data}
                onClick={handleRowClick}
                searchFilter={(data, filter) => {
                    const regex = new RegExp(filter, 'i')
                    return data.filter(user => {
                        return regex.test(user.user.firstName) || regex.test(user.user.lastName) || (user.roles && user.roles.some(role => regex.test(role.name)))
                    })
                }}
            />

            {!isEmpty(account) && !isEmpty(drawerUser) && (
                <UserEditDrawer
                    setUsers={setUsers}
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    user={drawerUser}
                    account={account}
                />
            )}
        </>
    )
}

export default UsersTable

import React, { useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router'
import { useAuth } from "./AuthProvider"
import SiteWrapper from "../../Theme/Components/SiteWrapper"
import usePermission from "../../Hooks/usePermission"
import { useParams } from 'react-router-dom'
import useCurrentAccount from '../../Hooks/Models/useCurrentAccount'

const PrivateRoute = ({ children, permission }) => {
    const authed = useAuth()
    const { id } = useParams()
    const { hasPermissionAnywhere, hasPermissionInAccount } = usePermission()
    const { currentAccount } = useCurrentAccount()
    const navigate = useNavigate()

    useEffect(() => {
        // If the user has not selected an account reroute them on all private pages
        if (authed === true && Object.keys(currentAccount).length === 0) {
            navigate('/account-select')
        }
    }, [])

    const checkPermission = () => {
        if (permission === "team:manage") {
            return hasPermissionInAccount("team:manage", id)
        }
        return hasPermissionAnywhere(permission)
    }

    const hasPermission = checkPermission()

    return hasPermission && authed ? <SiteWrapper>{children}</SiteWrapper> : <Navigate to="/errors"/>
}

export default PrivateRoute

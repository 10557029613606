import React from 'react'
import Icon from '../Icon'

export const ButtonEdit = ({ icon = 'Save', saving, children, ...other }) => {
    return (
        <button
            className="inline-flex items-center gap-x-1.5 rounded bg-torbu-white py-1.5 px-3 text-md text-torbu-green border-2 border-torbu-peach shadow-sm hover:bg-torbu-peach hover:text-torbu-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-torbu-peach"
            {...other}
        >
            {saving ? (
                <>
                    <Icon
                        icon={'Loader'}
                        size={6}
                        className="animate-spin-fast"
                    />
                    Saving
                </>
            ) : (
                <>
                    <Icon
                        icon={icon}
                        size={6}
                    />
                    {children}
                </>
            )}
        </button>
    )
}
export default ButtonEdit

import React from 'react'
import {Box, Text} from '@mantine/core'
import moment from 'moment'

const IntervalSlot = ({interval, settings, handleSelectedInterval, showTime, canEdit}) => {
    const date = moment(interval.date)
    let hoverClass = canEdit ? 'segment-interval' : ''

    return (
        <Box
            h={settings.timeIncrementsHeight - 1}
            mah={settings.timeIncrementsHeight - 1}
            style={{
                borderBottom: `1px solid ${settings.intervalBorderColor}`,
                backgroundColor: (date.format('mm') === '00') ? settings.intervalBackgroundColor : ''
            }}
            className={hoverClass}
            onClick={() => handleSelectedInterval({interval: interval, assignedUser: {}})}
        >
            {showTime === true && (
                <Text size={10} pt={8} px={8}>
                    {date.format('hh:mm a')}
                </Text>
            )}

            {(showTime === false && date.format('hh:mm a') === '12:00 am') && (
                <Text align={'center'} color={'dimmed'} size={'xs'} pt={8}>
                    {date.format('MMMM Do')}
                </Text>
            )}

        </Box>
    )
}

export default IntervalSlot

import { Box, Divider, Text } from '@mantine/core'
import { isEmpty } from 'lodash'
import React from 'react'
import Can from '../../Helpers/Can'
import IconText from '../../Helpers/IconText'

export const EventNavigation = ({ event }) => {
    return (
        <>
            <Box
                mb={15}
                mt={0}
            >
                <Text
                    fw={'300'}
                    size={'md'}
                    lineClamp={1}
                >
                    Events Menu
                </Text>
            </Box>

            <Divider mb={'lg'} />

            {isEmpty(event.id) ? (
                <>
                    <Can create={'event'}>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'SquarePlus'}
                                text={'Create New Event'}
                                to={`/events/new`}
                            />
                        </Box>
                    </Can>
                </>
            ) : (
                <>
                    <Can create={'event'}>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'SquarePlus'}
                                text={'Create New Event'}
                                to={`/events/new`}
                            />
                        </Box>
                    </Can>

                    <Can create={'event'}>
                        <Box mb={'sm'}>
                            <IconText
                                icon={'CalendarEvent'}
                                text={'Edit Event Details'}
                                to={`/events/edit/${event.id}`}
                            />
                        </Box>

                        <Can view={'event'}>
                            <Box mb={'sm'}>
                                <IconText
                                    icon={'ListDetails'}
                                    text={'Agenda Planner'}
                                    to={`/events/agenda/${event.id}`}
                                />
                            </Box>
                        </Can>

                        <Box mb={'sm'}>
                            <IconText
                                icon={'CloudUpload'}
                                text={'Media Uploads'}
                                to={`/events/media/${event.id}`}
                            />
                        </Box>
                    </Can>
                </>
            )}

            <Can view={'event'}>
                <Box mb={'sm'}>
                    <IconText
                        icon={'Calendar'}
                        text={'View Calendar'}
                        to={`/`}
                    />
                </Box>
            </Can>
        </>
    )
}

export default EventNavigation

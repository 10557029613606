import React, {useEffect} from 'react';
import Loader from '../../../Components/Loader';
import { Title } from '../../../Components/Title';
import UpcomingEvent from '../Components/UpcomingEvent';
import useEvents from "../../../Hooks/Models/useEvents";

export const UpcomingEventsList = ({selectedTeams}) => {
    const now = new Date()
    now.setSeconds(0)
    now.setMilliseconds(0)

    /* switched first to 3 as it is pulling 2 events per item */
    const upcomingEventsFilter = {
        first: 3,
        filter: {
            archived: false,
            startDate: now.toISOString(),
            accountIds: selectedTeams
        }
    }

    const upcomingEvents = useEvents(upcomingEventsFilter)

    useEffect(() => {
        upcomingEvents.setFilter(upcomingEventsFilter);
    }, [selectedTeams])

    return (
        <>
            <div className="mt-4" data-intercom-target="Upcoming Events">
                <Title>Upcoming Events</Title>

                <Loader
                    loading={upcomingEvents.isLoading}
                    label="Loading Events"
                >
                    {upcomingEvents.data.map(event => (
                        <UpcomingEvent
                            key={event.id}
                            event={event}
                        />
                    ))}
                </Loader>
            </div>
        </>
    )
}

export default UpcomingEventsList

import React,{ useState } from 'react'
import { useForm } from 'react-hook-form'
import Icon from '../../../Helpers/Icon'

const ForgotPassword = ({ page, setPage, errorMessage, setErrorMessage, sharedData, setSharedData }) => {
    const [loading, setLoading] = useState(false)

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: '',
        }
    })
    
    const authenticateWithAPI = data => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Stride-Auth': 'web'
            },
            body: JSON.stringify(data)
        }
        fetch(`${process.env.REACT_APP_STRIDE_API_ENDPOINT}/forgot-password`, requestOptions)
            .then(response => response.json())
            .then(response => {
                setLoading(false)
                if (!response.error) {
                    setSharedData({ email: data.email })
                }
                setPage('change')
            })
            .catch(error => {
                setLoading(false)
                console.error('error', error)
            })
    }

    const save = data => {
        setLoading(true)
        authenticateWithAPI(data)
    }

    return (
        <>
            <h1 className="line font-torbu-heading text-3xl leading-8 tracking-tight text-torbu-green dark:text-white sm:leading-3">Forget Something?</h1>
            <div className="pb-4 text-sm font-light text-gray-500 dark:text-gray-300">Enter your email address below, Torbu will send you a 6 digit code to your inbox. After validating the 6 digit code you will be able to reset your password.</div>

            {loading ? (
                <>
                    <div className="h-15 flex w-full flex-col rounded-lg border border-torbu-gray bg-torbu-white p-8 text-center">
                        <div className="mx-auto mb-4">
                            <Icon
                                icon={'Loader'}
                                size={40}
                                className="animate-spin-slow"
                            />
                        </div>
                        <div>Sending code to your inbox...</div>
                    </div>
                </>
            ) : (
                <>
                    <form onSubmit={handleSubmit(save)}>
                        <div>
                            <label
                                htmlFor="email"
                                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-torbu-green focus:ring-torbu-green dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-torbu-green dark:focus:ring-torbu-green sm:text-sm"
                                placeholder="name@company.com"
                                {...register('email', { required: true })}
                            />
                        </div>

                        <div className="my-8">
                            <button
                                type="submit"
                                className="dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg border-2 border-torbu-peach bg-torbu-white px-5 py-2.5 text-center font-torbu-demi-bold text-sm text-torbu-green hover:bg-torbu-peach hover:text-torbu-white focus:outline-none focus:ring-4 focus:ring-torbu-peach dark:bg-torbu-peach"
                            >
                                Verify Email Address
                            </button>
                        </div>
                    </form>

                    <div className="mt-12 flex items-center justify-between">
                        <a
                            href="#"
                            onClick={() => {
                                setErrorMessage({})
                                setPage('login')
                            }}
                            className="text-primary-600 dark:text-primary-500 text-sm hover:underline"
                        >
                            Back to Login
                        </a>
                    </div>
                </>
            )}
        </>
    )
}

export default ForgotPassword
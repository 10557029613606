import { Avatar, Text, Tooltip } from '@mantine/core'
import { isEmpty, isUndefined, startCase } from 'lodash'
import React, { useEffect, useState } from 'react'

export const StrideAvatar = ({ user, account, ...rest }) => {
    const getInitials = name => {
        let matches = name.match(/\b(\w)/g)
        return matches ? matches.slice(0, 2).join('') : ''
    }
    const buildUser = () => {
        if (!isUndefined(user) && !isEmpty(user.firstName) && !isEmpty(user.lastName)) {
            const name = [startCase(user?.firstName?.trim()), startCase(user?.lastName?.trim())].join(' ').trim()
            return {
                name: name,
                initials: getInitials(name),
                url: !isEmpty(user.avatarFile) ? user.avatarFile.url : null,
                status: null
            }
        }
        return {}
    }
    const buildAccount = () => {
        if (!isUndefined(account) && !isEmpty(account.name)) {
            const name = startCase(account?.name?.trim()).trim()

            return {
                name: name,
                initials: getInitials(name),
                url: !isEmpty(account.avatarFile) ? account.avatarFile.url : null,
                status: null
            }
        }
        return {}
    }

    const buildAvatar = () => {
        if (!isUndefined(account)) {
            return buildAccount()
        }
        if (!isUndefined(user)) {
            return buildUser()
        }

        return {}
    }

    const [avatar, setAvatar] = useState(buildAvatar())

    useEffect(() => {
        if (!isUndefined(user)) {
            setAvatar(buildUser())
        }
    }, [user])

    useEffect(() => {
        if (!isUndefined(account)) {
            setAvatar(buildAccount())
        }
    }, [account])

    return (
        <Tooltip
            label={
                <>
                    {avatar.name}
                    {avatar.status && <> - [ONLINE]</>}
                </>
            }
            position="bottom-start"
            arrowPosition="center"
            withArrow
            withinPortal
        >
            <Avatar
                alt={avatar.name}
                color={'torbu.0'}
                variant="filled"
                src={avatar.url}
                radius={'xl'}
                {...rest}
            >
                <Text>{avatar.initials}</Text>
            </Avatar>
        </Tooltip>
    )
}
export default StrideAvatar

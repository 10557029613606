import { GraphQLClient } from "graphql-request"

const data = localStorage.getItem("Stride-Data")
const getSession = () => {
    if (data) {
        return JSON.parse(localStorage.getItem("Stride-Data")).sessionId
    }
    return null
}

const getcurrentAccount = () => {
    if (data) {
        return JSON.parse(localStorage.getItem("Stride-Data")).currentAccount?.id
    }
    return null
}

const middleware = (response) => {
    if (response) {
        let parsedResponse = JSON.parse(JSON.stringify(response))
        if(parsedResponse.status === 500) {
            location.replace(`/errors/${parsedResponse.status}`)
        }
        if(String(parsedResponse.status).startsWith('4')) {
            location.replace(`/logout?code=${parsedResponse.status}`)
        }
    }
}

const graphqlClient = new GraphQLClient(
    `${process.env.REACT_APP_STRIDE_API_ENDPOINT}/graphql`,
    {
        responseMiddleware: middleware,
        headers: {
            "Content-Type": "application/json",
            "Stride-Web-Token": getSession(),
            "Stride-Lock-Token": Math.floor(Date.now() / 1000),
            "Stride-Account": getcurrentAccount(),
        }
    }
)
export default graphqlClient

import { Link } from '@mantine/tiptap'
import Blockquote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import Document from '@tiptap/extension-document'
import Gapcursor from '@tiptap/extension-gapcursor'
import Image from '@tiptap/extension-image'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import Mention from '@tiptap/extension-mention'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import { useCallback, useEffect } from 'react'
import sanitizeHtml from 'sanitize-html'

import { lowlight } from 'lowlight'
// load all highlight.js languages and register them
import css from 'highlight.js/lib/languages/css'
import java from 'highlight.js/lib/languages/java'
import js from 'highlight.js/lib/languages/javascript'
import php from 'highlight.js/lib/languages/php'
import python from 'highlight.js/lib/languages/python'
import ruby from 'highlight.js/lib/languages/ruby'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'

lowlight.registerLanguage('html', html)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)
lowlight.registerLanguage('ruby', ruby)
lowlight.registerLanguage('ruby', ruby)
lowlight.registerLanguage('java', java)
lowlight.registerLanguage('php', php)
lowlight.registerLanguage('python', python)

const useTorbuEditor = ({ placeHolder = '', defaultContents, editable = true }) => {
    const extensions = [
        Document,
        Bold,
        Strike,
        Italic,
        Paragraph,
        Text,
        Code,
        Underline,
        Link,
        OrderedList,
        ListItem,
        BulletList,
        Blockquote,
        Gapcursor,
        CodeBlockLowlight.configure({
            lowlight,
            HTMLAttributes: {
                class: 'chat-message-code-block'
            }
        }),
        Mention.configure({
            HTMLAttributes: {
                class: 'mention'
            }
        }),
        Image.configure({
            inline: true,
            HTMLAttributes: {
                height: 32,
                width: 32
            }
        }),
        Placeholder.configure({ placeholder: placeHolder })
    ]

    const editor = useEditor({
        editable: editable,
        content: defaultContents,
        extensions: extensions
    })

    useEffect(() => {
        if (defaultContents) {
            setContents(defaultContents)
        }
    }, [defaultContents])

    const getContents = useCallback(() => {
        let contents = ''
        const html = editor?.getHTML()
        if (html) {
            const parsedHtml = sanitizeHtml(html, {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
            })
            if (parsedHtml && parsedHtml !== '<p></p>') {
                contents = parsedHtml
            }
        }
        return contents
    }, [editor])

    const setContents = useCallback(
        content => {
            editor?.commands?.setContent(content)
        },
        [editor]
    )

    const clearContents = useCallback(() => {
        editor?.commands?.clearContent(true)
    }, [editor])

    return {
        editor: editor,
        contents: getContents(),
        setContents: setContents,
        clearContents: clearContents
    }
}
export default useTorbuEditor

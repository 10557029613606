import { useEffect, useState } from "react"

const useAppTitle = title => {
    const [appTitle, setAppTitle] = useState(title)

    useEffect(() => {
        document.title = appTitle
    },[appTitle])

    return setAppTitle
}

export {useAppTitle}
import React from 'react'

import AccountSelector from '../../Pages/Authentication/AccountSelector'
import AccountSettings from '../AccountSettings/AccountSettings'
import Admin from '../Admin/Admin'
import ChatApplication from '../Chat/ChatApplication'
import Dashboard from '../Dashboard/Dashboard'
import Whiteboard from '../Documents/Whiteboard'
import EventApplication from '../EventApplication/EventApplication'
import ErrorPage from '../External/Pages/ErrorPage'
import Logout from '../External/Pages/Logout'
import { Huddle } from '../Huddle/Huddle'
import Journals from '../Journals/Journals'
import Profile from '../Profile/Profile'
import AccountStats from '../AccountStats/AccountStats'

const routes = [
    {
        url: '/',
        exact: true,
        element:<Dashboard header={true}/>,
        private: false
    },
    {
        url: '/signup',
        exact: true,
        element: <Dashboard header={true}/>,
        private: false
    },
    {
        url: '/login',
        exact: true,
        element: <Dashboard header={true}/>,
        private: false
    },
    {
        url: '/dashboard',
        element: <Dashboard header={false}/>,
        private: true
    },
    {
        url: '/dashboard/new-event',
        exact: true,
        element: <Dashboard header={false}/>,
        private: true,
        permission: "event:create:v2"
    },
    {
        url: '/dashboard/:eventId/:eventTab',
        exact: true,
        element: <Dashboard header={false}/>,
        private: true,
        permission: "event:view:v2"
    },
    {
        url: '/events/:view',
        exact: true,
        element: <EventApplication />,
        private: true,
        permission: "event:view:v2"
    },
    {
        url: '/events/:view/:eventId',
        exact: true,
        element: <EventApplication />,
        private: true,
        permission: "event:view:v2"
    },
    {
        url: '/errors',
        exact: true, // Invalid permission error
        element: <ErrorPage code={"403"}/>,
        private: false
    },
    {
        url: '/logout',
        exact: true,
        element: <Logout />,
        private: false
    },
    {
        url: '/account-manager',
        exact: true,
        element: <AccountSettings />,
        private: true
    },
    {
        url: '/account-manager/:page',
        exact: true,
        element: <AccountSettings />,
        private: true
    },
    {
        url: '/account-manager/:page/:id',
        exact: true,
        element: <AccountSettings />,
        private: true, 
        permission: "team:manage"
    },
    {
        url: '/account-select',
        exact: true,
        element: <AccountSelector />,
        private: false
    },
    {
        url: '/journals',
        exact: true,
        element: <Journals />,
        private: true
    },
    {
        url: '/admin',
        exact: true,
        element: <Admin />,
        private: true,
        permission: "torbuAdmin"
    },
    {
        url: '/admin/:page',
        exact: true,
        element: <Admin />,
        private: true,
        permission: "torbuAdmin"
    },
    {
        url: '/admin/account-stats',
        exact: true,
        element: <AccountStats />,
        private: true,
        permission: "torbuAdmin"
    },
    {
        url: '/profile',
        exact: true,
        element: <Profile />,
        private: true
    },
    {
        url: '/chat',
        exact: true,
        element: <ChatApplication />,
        private: true, 
        permission: "channel:view"
    },
    {
        url: '/chat/:channelId',
        exact: true,
        element: <ChatApplication />,
        private: true, 
        permission: "channel:create:v2"
    },
    {
        url: '/huddle',
        exact: true,
        element: <Huddle />,
        private: true
    },
    {
        url: '*', // Universal page not found
        exact: false,
        element: <ErrorPage code={"404"}/>,
        private: false
    }
]

export { routes }

import React, { createContext, useContext, useEffect, useState } from "react"

const AuthContext = createContext("")
const SetAuthContext = createContext("")

const getSession = () => {
    const data = localStorage.getItem("Stride-Data")
    if (data) {
        return JSON.parse(localStorage.getItem("Stride-Data")).sessionId
    }
    return ""
}

export const AuthProvider = ({ children }) => {
    const [value, setAuth] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            const sessionId = getSession()
            if (sessionId !== null && sessionId !== undefined && sessionId !== "") {
                setAuth(true)
            } else {
                setAuth(false)
            }
            setLoaded(true)
            
        }
    }, [loaded, setLoaded, setAuth])

    if (!loaded) {
        return null
    }
    return (
        <AuthContext.Provider value={value}>
            <SetAuthContext.Provider value={setAuth}>
                {children}
            </SetAuthContext.Provider>
        </AuthContext.Provider>
    )
}
export const useAuth = () => {
    const auth = useContext(AuthContext)
    if (auth === undefined) {
        throw new Error("useAuth must be used withing an AuthProvider")
    }
    return auth
}

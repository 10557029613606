import {loader} from "graphql.macro"


///////////////////////////////////////////////////////////////////////////////////////////////////////
//
// DO NOT EDIT THIS FILE MANUALLY
// Generate this file by running yarn run gql or restart your yarn server
//
///////////////////////////////////////////////////////////////////////////////////////////////////////


const gqlRegistry = {
    'addCallerWithInviteCode': loader('../gql/addCallerWithInviteCode.gql'),
    'addUser': loader('../gql/addUser.gql'),
    'archiveAccount': loader('../gql/archiveAccount.gql'),
    'archiveAccountUser': loader('../gql/archiveAccountUser.gql'),
    'archiveChannel': loader('../gql/archiveChannel.gql'),
    'archiveEvent': loader('../gql/archiveEvent.gql'),
    'archiveMessage': loader('../gql/archiveMessage.gql'),
    'archiveRole': loader('../gql/archiveRole.gql'),
    'archiveSegment': loader('../gql/archiveSegment.gql'),
    'archiveSegmentTemplate': loader('../gql/archiveSegmentTemplate.gql'),
    'associateUsers': loader('../gql/associateUsers.gql'),
    'createAccount': loader('../gql/createAccount.gql'),
    'createChannel': loader('../gql/createChannel.gql'),
    'createEvent': loader('../gql/createEvent.gql'),
    'createEventAttendances': loader('../gql/createEventAttendances.gql'),
    'createEventType': loader('../gql/createEventType.gql'),
    'createJournal': loader('../gql/createJournal.gql'),
    'createJournalEntry': loader('../gql/createJournalEntry.gql'),
    'createMessage': loader('../gql/createMessage.gql'),
    'createRole': loader('../gql/createRole.gql'),
    'createSegment': loader('../gql/createSegment.gql'),
    'createSegmentTemplateGroup': loader('../gql/createSegmentTemplateGroup.gql'),
    'deletePaymentMethod': loader('../gql/deletePaymentMethod.gql'),
    'getAccount': loader('../gql/getAccount.gql'),
    'getAccountStats': loader('../gql/getAccountStats.gql'),
    'getAllAccountUsers': loader('../gql/getAllAccountUsers.gql'),
    'getAllAccountUsersSimple': loader('../gql/getAllAccountUsersSimple.gql'),
    'getAllAccounts': loader('../gql/getAllAccounts.gql'),
    'getAllCallerEvents': loader('../gql/getAllCallerEvents.gql'),
    'getAllChannels': loader('../gql/getAllChannels.gql'),
    'getAllEventTypes': loader('../gql/getAllEventTypes.gql'),
    'getAllEvents': loader('../gql/getAllEvents.gql'),
    'getAllFiles': loader('../gql/getAllFiles.gql'),
    'getAllInviteCodes': loader('../gql/getAllInviteCodes.gql'),
    'getAllMessages': loader('../gql/getAllMessages.gql'),
    'getAllPermissions': loader('../gql/getAllPermissions.gql'),
    'getAllPermissionsV2': loader('../gql/getAllPermissionsV2.gql'),
    'getAllRoles': loader('../gql/getAllRoles.gql'),
    'getAllRolesV2': loader('../gql/getAllRolesV2.gql'),
    'getAllSegmentTemplateGroups': loader('../gql/getAllSegmentTemplateGroups.gql'),
    'getAllSegmentTemplates': loader('../gql/getAllSegmentTemplates.gql'),
    'getAllUsers': loader('../gql/getAllUsers.gql'),
    'getCallerAddUserDirectory': loader('../gql/getCallerAddUserDirectory.gql'),
    'getChannel': loader('../gql/getChannel.gql'),
    'getCurrentUser': loader('../gql/getCurrentUser.gql'),
    'getEvent': loader('../gql/getEvent.gql'),
    'getStripeUrl': loader('../gql/getStripeUrl.gql'),
    'getSubscription': loader('../gql/getSubscription.gql'),
    'getUser': loader('../gql/getUser.gql'),
    'getUserDirectory': loader('../gql/getUserDirectory.gql'),
    'preloadUser': loader('../gql/preloadUser.gql'),
    'setDefaultPaymentMethod': loader('../gql/setDefaultPaymentMethod.gql'),
    'setLastReadMessage': loader('../gql/setLastReadMessage.gql'),
    'toggleRolePermission': loader('../gql/toggleRolePermission.gql'),
    'updateAccount': loader('../gql/updateAccount.gql'),
    'updateAccountUser': loader('../gql/updateAccountUser.gql'),
    'updateChannel': loader('../gql/updateChannel.gql'),
    'updateEvent': loader('../gql/updateEvent.gql'),
    'updateMessage': loader('../gql/updateMessage.gql'),
    'updatePassword': loader('../gql/updatePassword.gql'),
    'updateRole': loader('../gql/updateRole.gql'),
    'updateSegment': loader('../gql/updateSegment.gql'),
    'updateUser': loader('../gql/updateUser.gql'),
    'uploadFiles': loader('../gql/uploadFiles.gql'),
    'uploadUserCSV': loader('../gql/uploadUserCSV.gql')
}


///////////////////////////////////////////////////////////////////////////////////////////////////////
//
// DO NOT EDIT THIS FILE MANUALLY
// Generate this file by running yarn run gql or restart your yarn server
//
///////////////////////////////////////////////////////////////////////////////////////////////////////

export default gqlRegistry

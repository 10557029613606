import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import useCurrentAccount from "./useCurrentAccount"

const useAccountSubscription = () => {
    const { currentAccount } = useCurrentAccount()
    if (!currentAccount) return undefined
    const query = useQuery(['getSubscription', {
        accountId: currentAccount.id
    }])
    const [subscription, setSubscription] = useState()
    useEffect(() => {
        if (!query.data?.getStripeSubscriptionInfo) {
            setSubscription(undefined)
        } else {
            const subscription = { ...query.data.getStripeSubscriptionInfo }
            subscription.subscriptionEndDate = new Date(subscription.subscriptionEndDate)
            setSubscription(subscription)
        }
    }, [query.data])
    return subscription
}

export default useAccountSubscription

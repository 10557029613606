import { useTheme } from '@emotion/react'
import { Loader as MLoader,Paper,Text } from '@mantine/core'
import React,{ useEffect,useState } from 'react'

export const Loader = ({ loading, label = 'Loading', children, ...other }) => {
    const theme = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(loading)
    }, [loading])

    return (
        <>
            {isLoading === true ? (
                <div className="relative flex flex-col items-center w-full h-full p-8 text-center border rounded-lg justify-items-center border-torbu-gray bg-torbu-silver">
                    <div className="mx-auto mb-6">
                        <MLoader
                            variant="bars"
                            color={'orange'}
                        />
                    </div>
                    <div className="w-full">{label}...</div>
                </div>
            ) : (
                <>{children}</>
            )}
        </>
    )
}
export default Loader

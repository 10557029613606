import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import useCurrentAccount from '../../Hooks/Models/useCurrentAccount'
import SiteWrapper from '../../Theme/Components/SiteWrapper'
import { useAuth } from '../Authentication/AuthProvider'

import { CalendarApplication } from '../Calendar/CalendarApplication'
import { ExternalManager } from '../External/ExternalManager'


const Dashboard = ({ header }) => {
    const { currentAccount } = useCurrentAccount()

    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (auth === true && Object.keys(currentAccount).length === 0 ) {
            navigate('/account-select')
        }
    }, [])

    if (!auth) {
        return <ExternalManager />
    }

    if (header) {
        return (
            <SiteWrapper>
                <CalendarApplication />
            </SiteWrapper>
        )
    }

    return <CalendarApplication />
}

export default Dashboard

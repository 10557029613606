import { useTheme } from '@emotion/react'
import { LoadingOverlay, Paper, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'

export const StrideLoader = ({ loading, label = 'Loading', children, ...other }) => {
    const theme = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(loading)
    }, [loading])

    return (
        <>
            {isLoading === true ? (
                <Paper
                    withBorder
                    h={200}
                    pos={'relative'}
                    {...other}
                >
                    <LoadingOverlay
                        loaderProps={{ color: theme.colors.torbu[0], variant: 'bars' }}
                        overlayOpacity={0.3}
                        overlayColor={'#8e8e8e'}
                        visible
                    />

                    <Text
                        align={'center'}
                        mt={140}
                    >
                        {label}
                    </Text>
                </Paper>
            ) : (
                <>{children}</>
            )}
        </>
    )
}
export default StrideLoader

import React from "react"
import { Box } from '@mantine/core'

export const Folder = ({children}) => {

    return (
        <Box p={'xs'}>
            <Box mb={15} className="folder">
                <Box className="folder-top"></Box>
                <Box className="folder-bottom" p={'sm'}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
export default Folder

import React from 'react'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'

import { selectIsPeerAudioEnabled, selectIsPeerVideoEnabled, useAVToggle, useHMSStore, useVideo } from '@100mslive/react-sdk'
import Icon from '../../Helpers/Icon'
import StrideAvatar from '../../Helpers/StrideAvatar'

function Peer({ peer }) {
    const { currentUser } = useCurrentUser()

    const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle()
    const { videoRef } = useVideo({
        trackId: peer.videoTrack
    })

    const audioOn = useHMSStore(selectIsPeerAudioEnabled(peer.id))
    const videoOn = useHMSStore(selectIsPeerVideoEnabled(peer.id))

    const metadata = JSON.parse(peer.metadata)

    return (
        <div className="peer-container">
            {videoOn ? (
                <>
                    <video
                        ref={videoRef}
                        className={`peer-video ${peer.isLocal ? 'local' : ''}`}
                        autoPlay
                        muted
                        playsInline
                    />
                </>
            ) : (
                <>
                    <StrideAvatar
                        user={metadata}
                        className={'peer-video'}
                    />
                </>
            )}
            {audioOn ? (
                <>
                    <Icon
                        icon="Microphone"
                        color="white"
                        stroke={3}
                        className="peer-audio"
                    />
                </>
            ) : (
                <>
                    <Icon
                        icon="MicrophoneOff"
                        color="white"
                        stroke={3}
                        className="peer-audio"
                    />
                </>
            )}

            <div className="peer-name">
                {peer.name} {peer.isLocal ? '(You)' : ''}
            </div>
        </div>
    )
}

export default Peer

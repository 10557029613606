import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import NodeTemplate from './NodeTemplate'

export default memo(({ data, isConnectable }) => {
    return (
        <>
            <NodeTemplate account={data} />
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={false}
            />
        </>
    )
})
 
import { Box, Divider, Grid, Group, Paper, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import ButtonArchive from '../../../Components/Buttons/ButtonArchive'
import ButtonEdit from '../../../Components/Buttons/ButtonEdit'
import Can from '../../../Helpers/Can'
import Icon from '../../../Helpers/Icon'
import useGQL from '../../../Hooks/useGQL'
import { useNotify } from '../../../Hooks/useNotify'
import usePermission from '../../../Hooks/usePermission'
import graphqlClient from '../../../Utils/graphqlClient'
import queryClient from '../../../Utils/queryClient'
import AvatarUploader from '../../Avatar/AvatarUploader'

export const EditAccount = ({ account }) => {
    const { hasPermissionAnywhere } = usePermission()
    const notify = useNotify()
    const navigate = useNavigate

    const form = useForm({
        initialValues: {
            name: account.name
        },
        validate: {
            name: isNotEmpty('Name is required')
        }
    })
    useEffect(() => {
        if (account && account.name) form.setFieldValue('name', account.name)
    }, [account])

    const [isSaving, setIsSaving] = useState(false)

    const updateAccountGQL = useGQL('updateAccount')
    const updateAccountMutation = useMutation(data => graphqlClient.request(updateAccountGQL, data), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getAllAccounts'] }).then(() => {
                notify.updated('Team')
                window.location.reload(false)
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const updateAccount = data => {
        if (hasPermissionAnywhere('team:manage')) {
            setIsSaving(true)
            data.id = account.id
            updateAccountMutation.mutate(data)
        }
    }

    const archiveAccountGQL = useGQL('archiveAccount')
    const archiveAccountMutation = useMutation(data => graphqlClient.request(archiveAccountGQL, data), {
        onSuccess: () => {
            notify.archived('Team')
            window.location.href = '/'
        },
        onError: error => {
            console.error(error)
            notify.error('something went wrong while attempting to archive account')
        }
    })
    const archiveAccount = data => {
        if (hasPermissionAnywhere('team:manage') && !archiveAccountMutation.isLoading) {
            archiveAccountMutation.mutate({
                id: account.id
            })
        }
    }

    return (
        <>
            <Box mb={32}>
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Team Details</Text>
                        <Text
                            color="dimmed"
                            size="xs"
                        >
                            Update your team information
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Paper
                            bg={'#F6F7F8'}
                            p={'sm'}
                            withBorder
                        >
                            <form onSubmit={form.onSubmit(updateAccount)}>
                                <Box w={250}>
                                    <TextInput
                                        label={'Team name'}
                                        required
                                        {...form.getInputProps('name')}
                                    />
                                </Box>

                                <Box mt={16}>
                                    <Group position={'right'}>
                                        <ButtonEdit
                                            type="submit"
                                            loading={isSaving}
                                        >
                                            Update Team
                                        </ButtonEdit>
                                    </Group>
                                </Box>
                            </form>
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Box>

            <Divider my={32} />

            <Box mt={32}>
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Change Team Avatar</Text>
                        <Text
                            color="dimmed"
                            size="xs"
                        >
                            Click on the avatar to upload one of your own.
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Paper
                            bg={'#F6F7F8'}
                            p={'sm'}
                            withBorder
                        >
                            <AvatarUploader account={account} />
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Box>

            <Divider my={32} />
            <Can manage={'team'}>
                <Box mt={32}>
                    <Grid>
                        <Grid.Col span={4}>
                            <Text>Archive Team</Text>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <Paper
                                bg={'#F6F7F8'}
                                p={'sm'}
                                withBorder
                            >
                                <Box mt={16}>
                                    <Group position={'right'}>
                                        <ButtonArchive
                                            modalTitle={'This action is PERMANENT! Are you sure you want to remove this team from the account?'}
                                            onConfirm={archiveAccount}
                                        >
                                            Remove Team
                                        </ButtonArchive>
                                    </Group>
                                </Box>
                            </Paper>
                        </Grid.Col>
                    </Grid>
                </Box>
            </Can>
        </>
    )
}

export default EditAccount

/* eslint-disable */
import { Box,Checkbox,Grid,Group,Input,MultiSelect,NumberInput,Paper,Select,Switch,Text,TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { isNotEmpty,useForm } from '@mantine/form'
import { IconCalendar,IconRepeat,IconUsers } from '@tabler/icons-react'
import { isEmpty,upperFirst } from 'lodash'
import moment from 'moment/moment'
import React,{ useEffect,useState } from 'react'
import { useNavigate } from 'react-router'
import TimePicker from 'react-time-picker'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import ButtonEdit from '../../Components/Buttons/ButtonEdit'
import useAccountDirectory from '../../Helpers/AccountDirectory'
import AccountsWithAvatars from '../../Helpers/AccountsWithAvatars'
import StrideHeader from '../../Helpers/StrideHeader'
import TorbuEditor from '../../Helpers/TorbuEditor'
import useEventMutation from '../../Hooks/Models/useEventMutation'
import useUserDirectory from '../../Hooks/Models/useUserDirectory'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import useTorbuEditor from '../../Hooks/useTorbuEditor'
import EventTypeForm from '../EventTypes/EventTypeForm'

export const EventForm = ({ event }) => {
    const navigate = useNavigate()
    const torbuEditor = useTorbuEditor({})
    const notify = useNotify()

    const form = useForm({
        initialValues: {
            title: '',
            eventTypeId: '',
            accountIds: [],
            userIds: [],
            startDate: null,
            endDate: null,
            startTime: '',
            endTime: '',
            eventRepeat: false,
            frequencyType: '',
            frequency: '',
            daysOfWeek: '',
            attendanceTaken: false,
            attendanceKeeperId: null,
            location: '',
            recurrenceEndDate: null
        },
        validate: {
            title: isNotEmpty('Title is required'),
            eventTypeId: isNotEmpty('Category is required'),
            accountIds: (value) => {
                if (form.values.accountIds.length === 0 && form.values.userIds.length === 0) {
                    return 'You must select at least 1 attendee'
                }
            },
            startDate: isNotEmpty('Start Date is required'),
            endDate: isNotEmpty('End Date is required'),
            startTime: isNotEmpty('Start time is required'),
            endTime: isNotEmpty('End time is required')
        }
    })

    const [isSaving, setIsSaving] = useState(false)
    const accountDirectory = useAccountDirectory('event:create:v2')
    const userDirectory = useUserDirectory()
    const combinedDirectory = [...accountDirectory, ...userDirectory.renderOptions()]

    const [editingRepeatingEvent, setEditingRepeatingEvent] = useState(false)

    useEffect(() => {
        if (!isEmpty(event)) {
            if (event.description) torbuEditor.setContents(event.description)

            if (event.startTime && event.endTime) {
                const start = moment(event.startTime)
                const end = moment(event.endTime)

                form.setFieldValue('startDate', start.toDate())
                form.setFieldValue('endDate', end.toDate())
                form.setFieldValue('startTime', start.format('HH:mm'))
                form.setFieldValue('endTime', end.format('HH:mm'))
            }

            if (event.title) form.setFieldValue('title', event.title)
            if (event.location) form.setFieldValue('location', event.location)
            if (event.accounts?.length || event.user?.length) {
                form.setFieldValue(
                    'accountIds',
                    event.accounts?.map(account => account.id),
                    'userIds',
                    event.users?.map(user => user.id)
                )
            }

            if (event.attendanceKeeper) form.setFieldValue('attendanceKeeperId', event.attendanceKeeper?.id)
            if (event.attendanceKeeper) form.setFieldValue('attendanceTaken', !isEmpty(event.attendanceKeeper))
            if (event.eventType) form.setFieldValue('eventTypeId', event.eventType?.id)

            // eventRecurrenceInfo
            if (event.eventRecurrenceInfo) {
                const isRepeatingEvent = !isEmpty(event.eventRecurrenceInfo)
                form.setFieldValue('eventRepeat', isRepeatingEvent)
                if (event.eventRecurrenceInfo.frequency) form.setFieldValue('frequency', event.eventRecurrenceInfo.frequency)
                if (event.eventRecurrenceInfo.frequencyType) form.setFieldValue('frequencyType', event.eventRecurrenceInfo.frequencyType)
                if (event.eventRecurrenceInfo.daysOfWeek) form.setFieldValue('daysOfWeek', event.eventRecurrenceInfo.daysOfWeek)
                if (event.eventRecurrenceInfo.recurrenceEndDate) form.setFieldValue('recurrenceEndDate', event.eventRecurrenceInfo.recurrenceEndDate)
                if (isRepeatingEvent) {
                    setEditingRepeatingEvent(true)
                    form.setFieldValue('updateRecurrences', true)
                }
            }
        }
    }, [event, torbuEditor.setContents])

    const createEventGQL = useGQL('createEvent')

    const createEvent = useEventMutation({
        onSuccess: data => {
            notify.created('Event')
            navigate(`/events/edit/${data.createEvent.id}`)
        },
        onError: error => {
            console.error(error)
            notify.error()
            setIsSaving(false)
        },
        type: 'create'
    })

    const updateEvent = useEventMutation({
        onSuccess: () => {
            notify.updated('Event')
            setIsSaving(false)
        },
        onError: error => {
            console.error(error)
            notify.error()
            setIsSaving(false)
        }
    })

    const save = data => {
        setIsSaving(true)

        if (data) {
            const startDate = moment(data.startDate)
            const startTime = moment(data.startTime, 'HH:mm')
            startDate.set('hour', startTime.hours())
            startDate.set('minutes', startTime.minutes())

            const endDate = moment(data.endDate)
            const endTime = moment(data.endTime, 'HH:mm')
            endDate.set('hour', endTime.hours())
            endDate.set('minutes', endTime.minutes())

            let eventData = {
                title: data.title,
                location: data.location,
                startTime: startDate.toDate(),
                endTime: endDate.toDate(),
                eventTypeId: data.eventTypeId,
                description: torbuEditor.contents,
                accountIds: data.accountIds,
                userIds: data.userIds,
                attendanceKeeperId: data.attendanceKeeperId
            }

            if (data.eventRepeat) {
                eventData.eventRecurrenceInfoInput = {
                    startDate: startDate.toDate(),
                    daysOfWeek: data.daysOfWeek,
                    frequency: data.frequency,
                    frequencyType: data.frequencyType,
                    endDate: data.recurrenceEndDate
                }
            }

                if (!event.id) {
                    createEvent.mutate(eventData)
                } else {
                    eventData.id = event.id
                    if (editingRepeatingEvent) eventData.updateRecurrences = data.updateRecurrences
                    else eventData.updateRecurrences = false
                    updateEvent.mutate(eventData)
                }
        }
    }

    const handleRepeatingEvent = isRepeatingEvent => {
        form.setFieldValue('eventRepeat', isRepeatingEvent)
        form.setFieldValue('frequency', '')
        form.setFieldValue('frequencyType', '')
        form.setFieldValue('daysOfWeek', '')
        if (editingRepeatingEvent) form.setFieldValue('updateRecurrences', isRepeatingEvent)
    }

    return (
        <>
            <StrideHeader
                header={'Event Manager'}
                crumbs={[
                    {
                        title: isEmpty(event) ? 'New Event' : 'Edit Event Details'
                    }
                ]}
                icon={'Calendar'}
                withBorder
            />

            <Paper
                withBorder
                shadow
                bg={'#F6F7F8'}
                p={'sm'}
                mb={'md'}
            >
                <form
                    onSubmit={form.onSubmit(save, error => {
                    })}
                >
                    <Grid>
                        <Grid.Col span={12}>
                            <MultiSelect
                                icon={<IconUsers size={14} />}
                                label="Teams and Individuals"
                                placeholder="Select the Teams and/or Individuals that you want to be assigned to this event"
                                data={combinedDirectory}
                                // itemComponent={AccountsWithAvatars}
                                required
                                searchable
                                withinPortal
                                onChange={value => {
                                    const userIds = []
                                    const accountIds = []
                                    for (const attendee of value) {
                                        const isUser = /^user-.*$/.test(attendee)
                                        if (isUser) {                                            
                                            userIds.push(attendee)
                                        } else {
                                            accountIds.push(attendee)
                                        }
                                    }
                                    form.setFieldValue('userIds', userIds)
                                    form.setFieldValue('accountIds', accountIds)
                                }}
                            />
                        </Grid.Col>
                    </Grid>

                    <Grid mt={8}>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Event"
                                placeholder="What would you like to name this event?"
                                withAsterisk
                                {...form.getInputProps('title')}
                            />
                        </Grid.Col>
                    </Grid>

                    <Grid mt={8}>
                        <Grid.Col span={12}>
                            <EventTypeForm
                                form={form}
                                accountIds={form.values?.accountIds}
                            />
                        </Grid.Col>
                    </Grid>

                    <Grid mt={8}>
                        <Grid.Col
                            md={6}
                            lg={4}
                        >
                            <DatePickerInput
                                label="Start Date"
                                placeholder="Start Date"
                                icon={<IconCalendar size={16} />}
                                popoverProps={{ withinPortal: true }}
                                valueFormat="MM/DD/YYYY"
                                firstDayOfWeek="sunday"
                                required
                                clearable
                                {...form.getInputProps('startDate')}
                                onChange={value => {
                                    form.setFieldValue('startDate', value)
                                    if (isEmpty(form.values?.endDate)) {
                                        form.setFieldValue('endDate', value)
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col
                            md={6}
                            lg={4}
                        >
                            <Input.Wrapper
                                label="Start Time"
                                required
                                error={form.errors?.startTime}
                            >
                                <Input
                                    component={TimePicker}
                                    value={form.values?.startTime}
                                    disableClock={true}
                                    hourPlaceholder={'hh'}
                                    minutePlaceholder={'mm'}
                                    clear
                                    {...form.getInputProps('startTime')}
                                    onChange={value => {
                                        form.setFieldValue('startTime', value)

                                        if (!isEmpty(value)) {
                                            const startTime = moment(value, 'HH:mm')
                                            const startTimePlusHour = startTime.add(1, 'hour').format('HH:mm')

                                            if (isEmpty(form.values?.endTime)) {
                                                if (startTime) {
                                                    form.setFieldValue('endTime', startTimePlusHour)
                                                }
                                            }

                                            if (!isEmpty(form.values?.endTime)) {
                                                const endTime = moment(form.values.endTime, 'HH:mm')
                                                if (endTime.isBefore(startTime)) {
                                                    form.setFieldValue('endTime', startTimePlusHour)
                                                }
                                            }
                                        }
                                    }}
                                />
                            </Input.Wrapper>
                        </Grid.Col>
                    </Grid>

                    <Grid mt={8}>
                        <Grid.Col
                            md={6}
                            lg={4}
                        >
                            <DatePickerInput
                                label="End Date"
                                placeholder="End Date"
                                icon={<IconCalendar size={16} />}
                                popoverProps={{ withinPortal: true }}
                                valueFormat="MM/DD/YYYY"
                                firstDayOfWeek="sunday"
                                required
                                clearable
                                {...form.getInputProps('endDate')}
                            />
                        </Grid.Col>
                        <Grid.Col
                            md={6}
                            lg={4}
                        >
                            <Input.Wrapper
                                label="End Time"
                                required
                                error={form.errors?.endTime}
                            >
                                <Input
                                    component={TimePicker}
                                    value={form.values?.endTime}
                                    hourPlaceholder={'hh'}
                                    minutePlaceholder={'mm'}
                                    disableClock
                                    clear
                                    {...form.getInputProps('endTime')}
                                    onChange={value => form.setFieldValue('endTime', value)}
                                />
                            </Input.Wrapper>
                        </Grid.Col>
                    </Grid>

                    <Box mt={24}>
                        <Paper
                            withBorder
                            p={'sm'}
                        >
                            <Switch
                                label={editingRepeatingEvent ? 'Update Recurrences' : 'Repeating Event'}
                                onLabel={
                                    <Text
                                        color={'black'}
                                        size={'xs'}
                                    >
                                        Yes
                                    </Text>
                                }
                                offLabel={
                                    <Text
                                        color={'black'}
                                        size={'xs'}
                                    >
                                        No
                                    </Text>
                                }
                                {...form.getInputProps('eventRepeat', {
                                    type: 'checkbox'
                                })}
                                onChange={e => handleRepeatingEvent(e.currentTarget.checked)}
                            />

                            {form.values?.eventRepeat && (
                                <>
                                    <Grid mt={8}>
                                        <Grid.Col
                                            md={6}
                                            lg={3}
                                        >
                                            <Select
                                                icon={<IconRepeat size={16} />}
                                                label="Repeat Frequency"
                                                placeholder="Select frequency"
                                                data={[
                                                    { value: 'day', label: 'Every Day' },
                                                    { value: 'week', label: 'Every Week' },
                                                    { value: 'month', label: 'Every Month' },
                                                    { value: 'year', label: 'Every Year' }
                                                ]}
                                                {...form.getInputProps('frequencyType')}
                                                required
                                                onChange={value => {
                                                    form.setFieldValue('frequencyType', value)
                                                    if (form.values?.frequency === '') {
                                                        form.setFieldValue('frequency', 1)
                                                    }
                                                }}
                                            />
                                        </Grid.Col>
                                        <Grid.Col
                                            md={6}
                                            lg={2}
                                        >
                                            <Grid>
                                                <Grid.Col span={8}>
                                                    <NumberInput
                                                        label="Every"
                                                        placeholder="Every"
                                                        required
                                                        max={120}
                                                        min={1}
                                                        {...form.getInputProps('frequency')}
                                                        disabled={form.values?.frequencyType === ''}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <Text
                                                        mt={30}
                                                        ml={-10}
                                                    >
                                                        {form.values?.frequencyType && upperFirst(form.values?.frequencyType) + 's'}
                                                    </Text>
                                                </Grid.Col>
                                            </Grid>
                                        </Grid.Col>
                                    </Grid>

                                    {form.values?.frequencyType === 'week' && (
                                        <Checkbox.Group
                                            {...form.getInputProps('daysOfWeek')}
                                            required
                                        >
                                            <Group
                                                mt="md"
                                                py={'sm'}
                                            >
                                                <Checkbox
                                                    value="SUNDAY"
                                                    label="S"
                                                />
                                                <Checkbox
                                                    value="MONDAY"
                                                    label="M"
                                                />
                                                <Checkbox
                                                    value="TUESDAY"
                                                    label="T"
                                                />
                                                <Checkbox
                                                    value="WEDNESDAY"
                                                    label="W"
                                                />
                                                <Checkbox
                                                    value="THURSDAY"
                                                    label="T"
                                                />
                                                <Checkbox
                                                    value="FRIDAY"
                                                    label="F"
                                                />
                                                <Checkbox
                                                    value="SATURDAY"
                                                    label="S"
                                                />
                                            </Group>
                                        </Checkbox.Group>
                                    )}

                                    <DatePickerInput
                                        label="Last Date"
                                        placeholder="Last Date"
                                        icon={<IconCalendar size={16} />}
                                        popoverProps={{ withinPortal: true }}
                                        valueFormat="MM/DD/YYYY"
                                        firstDayOfWeek="sunday"
                                        required
                                        clearable
                                        {...form.getInputProps('recurrenceEndDate')}
                                    />
                                </>
                            )}
                        </Paper>
                    </Box>

                    {/*<Box mt={24}>*/}
                    {/*    <Paper mt={16} withBorder p={'sm'}>*/}
                    {/*        <Switch*/}
                    {/*            label="Do you want to take attendance for this event?"*/}
                    {/*            onLabel={<Text color={"black"} size={"xs"}>Yes</Text>}*/}
                    {/*            offLabel={<Text color={"black"} size={"xs"}>No</Text>}*/}
                    {/*            {...form.getInputProps('attendanceTaken')}*/}
                    {/*            disabled={isEmpty(form.values?.accountIds)}*/}
                    {/*        />*/}

                    {/*        {form.values?.attendanceTaken && (*/}
                    {/*            <Grid mt={8}>*/}
                    {/*                <Grid.Col span={12}>*/}
                    {/*                    <Select*/}
                    {/*                        label={"Who should take attendance?"}*/}
                    {/*                        data={userDirectory}*/}
                    {/*                        itemComponent={UsersWithAvatars}*/}
                    {/*                        clearable*/}
                    {/*                        searchable*/}
                    {/*                        required*/}
                    {/*                        {...form.getInputProps('attendanceKeeperId')}*/}
                    {/*                    />*/}
                    {/*                </Grid.Col>*/}
                    {/*            </Grid>*/}
                    {/*        )}*/}
                    {/*    </Paper>*/}
                    {/*</Box>*/}

                    <Grid mt={8}>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Location"
                                placeholder="Add event location"
                                {...form.getInputProps('location')}
                            />
                        </Grid.Col>
                    </Grid>

                    <Box mt={'lg'}>
                        <TorbuEditor editor={torbuEditor} />
                    </Box>

                    <Group
                        position={'right'}
                        mt={'lg'}
                    >
                        {!event.id ? (
                            <ButtonCreate
                                type="submit"
                                saving={isSaving}
                            >
                                Create Event
                            </ButtonCreate>
                        ) : (
                            <ButtonEdit
                                type="submit"
                                saving={isSaving}
                            >
                                Update Event
                            </ButtonEdit>
                        )}
                    </Group>
                </form>
            </Paper>
        </>
    )
}

export default EventForm

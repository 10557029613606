import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon'

export const ButtonLink = ({ to, icon, saving, children }) => {
    if (!to) {
        throw new Error('ButtonLink requires a to attribute')
    }

    return (
        <Link
            className="inline-flex items-center gap-x-1.5 rounded border text-sm border-torbu-peach bg-torbu-white px-2 py-1.5 font-torbu-medium text-torbu-green shadow-sm hover:bg-torbu-peach hover:text-torbu-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-torbu-peach"
            to={to}
        >
            {icon && (
                <Icon
                    icon={icon}
                    size={5}
                />
            )}
            {children}
        </Link>
    )
}
export default ButtonLink

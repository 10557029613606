import { isEmpty } from 'lodash'
import Pusher from 'pusher-js'
import React, { useEffect, useMemo, createContext, useState, useRef, useContext } from 'react'

const PusherContext = createContext()
const usePusherContext = () => useContext(PusherContext)

class SubscriptionMap extends Map {
    constructor(...args) {
        super(...args)
    }
    addChannelSub(channelName, sub) {
        let channelSubs = this.get(channelName)
        if (channelSubs === undefined) {
            channelSubs = new Set()
            this.set(channelName, channelSubs)
        }
        channelSubs.add(sub)
        return channelSubs.size
    }
    deleteChannelSub(channelName, sub) {
        const channelSubs = this.get(channelName)
        if (channelSubs === undefined) throw new Error('No such channel subscription')
        if (!channelSubs.has(sub)) throw new Error('No such handler on channel subscription')
        if (channelSubs.size === 1) this.delete(channelName)
        else channelSubs.delete(sub)
    }
    getChannelSize(channelName) {
        const channelSubs = this.get(channelName)
        if (channelSubs === undefined) throw new Error('No such channel subscription')
        return channelSubs.size
    }
    [Symbol.iterator]() {
        if (!this.size) return {
            next() {
                return {
                    done: true
                }
            }
        }
        const channels = this.keys()
        const channelsIterator = channels[Symbol.iterator]()
        let currentChannel = channelsIterator.next().value
        let subsIterator = this.get(currentChannel)[Symbol.iterator]()
        return {
            next: () => {
                let subsResult = subsIterator.next()
                if (subsResult.done) {
                    let channelsResult = channelsIterator.next()
                    if (channelsResult.done) {
                        return {
                            done: true
                        }
                    }
                    currentChannel = channelsResult.value
                    subsIterator = this.get(currentChannel)[Symbol.iterator]()
                    subsResult = subsIterator.next()
                }
                const sub = subsResult.value
                return {
                    value: [currentChannel, sub]
                }
            }
        }
    }
}

export const PusherProvider = ({ children, pusherKey, pusherConfig }) => {
    const pusher = useMemo(() => new Pusher(pusherKey, pusherConfig), [])
    const subscriptions = useMemo(() => new SubscriptionMap(), [])
    return (
        <PusherContext.Provider value={{
            pusher,
            subscriptions
        }}>
            {children}
        </PusherContext.Provider>
    )
}

const usePusher = (subscribe, dependencies) => {
    const { pusher, subscriptions } = usePusherContext()
    const { current: localSubscriptions } = useRef(new SubscriptionMap())

    function subscribePusherEvent(channelName, eventName, handler) {
        const channel = pusher.subscribe(channelName)
        channel.bind(eventName, handler)
        const sub = {
            handler,
            eventName
        }
        localSubscriptions.addChannelSub(channelName, sub)
        subscriptions.addChannelSub(channelName, sub)
    }

    function unsubscribePusherEvent(channelName, sub) {
        const channelSize = subscriptions.getChannelSize(channelName)
        if (channelSize === 1) {
            pusher.unsubscribe(channelName)
        } else {
            const channel = pusher.channel(channelName)
            channel.unbind(sub.eventName, sub.handler)
        }
        localSubscriptions.deleteChannelSub(channelName, sub)
        subscriptions.deleteChannelSub(channelName, sub)
    }

    useEffect(() => {
        subscribe(subscribePusherEvent)
        return () => {
            for (const [channelName, sub] of localSubscriptions) {
                unsubscribePusherEvent(channelName, sub)
            }
        }
    }, dependencies)
}

export default usePusher

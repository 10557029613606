import React from "react";

export const RegisterSelector = ({ page, setPage, errorMessage, setErrorMessage, sharedData, setSharedData }) => {
    return (
        <>
            <h1 className="line font-torbu-heading text-2xl leading-6 tracking-tight text-torbu-green dark:text-white sm:text-3xl sm:leading-3">Let&apos;s Get You Registered!</h1>
            <div className="pb-4 text-sm font-light text-gray-500 dark:text-gray-300">Who are we registering today?</div>

            <div
                className="cursor-pointer rounded-lg border border-torbu-peach bg-torbu-white p-4 text-center hover:bg-torbu-peach hover:text-torbu-white"
                onClick={() => {
                    setPage('signup')
                }}
            >
                I want to sign up my Team
            </div>

            <div className="flex items-center">
                <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="px-5 text-center text-gray-500 dark:text-gray-400">or</div>
                <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700"></div>
            </div>

            <div
                className="mb-8 cursor-pointer rounded-lg border border-torbu-peach bg-torbu-white p-4 text-center hover:bg-torbu-peach hover:text-torbu-white"
                onClick={() => {
                    setPage('register')
                }}
            >
                Register as a user of a Team
            </div>

            <div className="mt-12">
                <a
                    href="#"
                    onClick={() => {
                        setErrorMessage({})
                        setPage('login')
                    }}
                    className="text-primary-600 dark:text-primary-500 text-sm hover:underline"
                >
                    Back to Login
                </a>
            </div>
        </>
    )
}

export default RegisterSelector


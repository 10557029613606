/* eslint-disable */
import { Badge,Box,Grid,Text,Tooltip } from '@mantine/core'
import { filter,find,sortBy } from 'lodash'
import React from 'react'
import { useRecoilState } from 'recoil'
import Can from '../../Helpers/Can'
import Icon from '../../Helpers/Icon'
import useCurrentAccount from '../../Hooks/Models/useCurrentAccount'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import state from '../../Utils/State'

export const ChatChannels = ({ channels, setDrawer, setLastMessageRead }) => {
    const [selectedChannel, setSelectedChannel] = useRecoilState(state.activeChatChannel)
    const { currentAccount } = useCurrentAccount()
    const { currentUser } = useCurrentUser()

    const handleChannelClick = channel => {
        setLastMessageRead({})
        setSelectedChannel(channel)
    }

    const renderChannelName = (channel, unreadCount) => {
        let channelName = channel.title

        if (channel.directMessage) {
            let channelUsers = sortBy(channel.channelUsers, ['user.firstName', 'user.lastName'])
            channelUsers = filter(channelUsers, channelUser => channelUser.user.id !== currentUser.id)
            channelUsers = channelUsers.map(channelUer => channelUer.user.firstName + ' ' + channelUer.user.lastName)
            channelName = channelUsers.join(', ')
        }

        return (
            <Tooltip
                label={channelName}
                position="bottom"
                withArrow
                multiline
                withinPortal
                openDelay={300}
                className={'pointer'}
            >
                {unreadCount > 0 ? (
                    <Text
                        fw={'bold'}
                        size={14}
                        lineClamp={1}
                    >
                        {channelName}
                    </Text>
                ) : (
                    <Text
                        fw={300}
                        size={14}
                        lineClamp={1}
                    >
                        {channelName}
                    </Text>
                )}
            </Tooltip>
        )
    }

    const renderChannel = channel => {
        const myChannels = find(channel.channelUsers, channelUser => channelUser.user.id === currentUser.id)
        let unreadCount = myChannels.lastReadCount

        return (
            <Box
                key={channel.id}
                p={5}
                onClick={() => handleChannelClick(channel)}
                w={'95%'}
                className={selectedChannel && channel.id === selectedChannel.id ? 'chat-channel-box active' : 'chat-channel-box'}
            >
                <Grid gutter={5}>
                    <Grid.Col span="content">
                        <Box mt={1}>
                            {channel.directMessage ? (
                                <Icon
                                    icon={'LayoutGrid'}
                                    size={14}
                                    color={'#464646'}
                                />
                            ) : (
                                <Icon
                                    icon={'Hash'}
                                    size={14}
                                    color={'#464646'}
                                />
                            )}
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={9}>{renderChannelName(channel, unreadCount)}</Grid.Col>
                    <Grid.Col
                        span="content"
                        ml={0}
                    >
                        {unreadCount > 0 && (
                            <Tooltip
                                label={`${unreadCount} new messages`}
                                position="bottom"
                                withArrow
                                withinPortal
                                className={'pointer'}
                            >
                                <Badge
                                    color="red"
                                    size="xs"
                                    radius="xs"
                                    variant="filled"
                                >
                                    <Text
                                        fw={'bold'}
                                        size={'xs'}
                                    >
                                        {unreadCount > 99 ? <>99+</> : <>{unreadCount}</>}
                                    </Text>
                                </Badge>
                            </Tooltip>
                        )}
                    </Grid.Col>
                </Grid>
            </Box>
        )
    }

    return (
        <>
            <Box>
                <Grid data-intercom-target="Channel List Header">
                    <Grid.Col span="content">
                        <Box>
                            <Icon
                                icon={'BuildingCommunity'}
                                size={22}
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <Tooltip
                            label={currentAccount.name}
                            position="bottom"
                            withArrow
                            withinPortal
                            openDelay={300}
                            className={'pointer'}
                        >
                            <Text
                                fw={'500'}
                                size={'sm'}
                                lineClamp={1}
                                data-intercom-target="Channel Team Name Text"
                            >
                                {currentAccount.name}
                            </Text>
                        </Tooltip>
                    </Grid.Col>
                    <Grid.Col
                        span="content"
                        ml={-22}
                    >
                        <Box mt={2}>
                            <Can create={'channel'}>
                                <Tooltip
                                    label={'Start new chat'}
                                    position="bottom"
                                    withArrow
                                    withinPortal
                                    className={'pointer'}
                                >
                                    <Box
                                        onClick={() => {
                                            setDrawer({ type: 'channelAdd', props: {channelType: 'groupChannel'} })
                                        }}
                                        className="pointer"
                                    >
                                        <Icon
                                            icon={'Plus'}
                                            color={'black'}
                                            size={16}
                                        />
                                    </Box>
                                </Tooltip>
                            </Can>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <Box ml={23}>
                {channels
                    .filter(channel => channel.directMessage === false)
                    .map(channel => (
                        <div key={channel.id}>{renderChannel(channel)}</div>
                    ))}
                {!channels.filter(channel => channel.directMessage === false).length && (
                    <>
                        <Text
                            size={'sm'}
                            ml={25}
                        >
                            No channel groups found.
                        </Text>
                    </>
                )}
            </Box>

            <Box mt={15}>
                <Grid data-intercom-target="Direct Message List Header">
                    <Grid.Col span="content">
                        <Box>
                            <Icon
                                icon={'Send'}
                                size={22}
                            />
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <Text
                            fw={'500'}
                            size={'sm'}
                            lineClamp={1}
                            data-intercom-target="Direct Message Text"
                        >
                            Direct Messages
                        </Text>
                    </Grid.Col>
                    <Grid.Col
                        span="content"
                        ml={-22}
                    >
                        <Box mt={2}>
                            <Can create={'channel'}>
                                <Tooltip
                                    label={'Start new direct message chat'}
                                    position="bottom"
                                    withArrow
                                    withinPortal
                                    className={'pointer'}
                                >
                                    <Box
                                        className="pointer"
                                        onClick={() => {
                                            setDrawer({ type: 'channelAdd', props: { channelType: 'directMessage' } })
                                        }}
                                    >
                                        <Icon
                                            icon={'Plus'}
                                            color={'black'}
                                            size={16}
                                        />
                                    </Box>
                                </Tooltip>
                            </Can>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <Box ml={23}>
                {channels
                    .filter(channel => channel.directMessage === true)
                    .map(channel => (
                        <div key={channel.id}>{renderChannel(channel)}</div>
                    ))}
                {!channels.filter(channel => channel.directMessage === true).length && (
                    <>
                        <Text
                            size={'sm'}
                            ml={25}
                        >
                            No direct messages found.
                        </Text>
                    </>
                )}
            </Box>
        </>
    )
}
export default ChatChannels

import React, {useState} from 'react'
import { useQuery } from "react-query"
import {Group, Paper, Text} from '@mantine/core'
import StrideLoader from "../../../Helpers/StrideLoader"
import StrideHeader from '../../../Helpers/StrideHeader'
import StrideTable from "../../../Helpers/StrideTable"
import StrideAvatar from "../../../Helpers/StrideAvatar"

const columns = [
    {
        id: 'code',
        name: 'Code',
        sortable: true,
        cell: row => row.code,
        selector: row => row.code,
        width: '10%'
    },
    {
        id: 'account',
        name: 'Account',
        sortable: true,
        selector: row => row.account.name,
        cell: row => (
            <Group>
                <StrideAvatar account={row.account} />
                <Text>
                    {row.account.name}
                </Text>
            </Group>
        ),
    },
    {
        id: 'role',
        name: 'Role',
        sortable: true,
        cell: row => row.role.name,
        selector: row => row.role.name,
    },
]

export const AdminInviteCodes = () => {
    const [inviteCodes, setInviteCodes] = useState([])

    const inviteCodesQuery = useQuery(['getAllInviteCodes'], {
        onSettled: (data) => {
            if(data.getAllInviteCodes?.edges?.length){
                setInviteCodes(data.getAllInviteCodes.edges.map(edge => edge.node))
            }else{
                setInviteCodes([])
            }
        }
    })

    return (
        <>
            <StrideHeader
                header={'Administration'}
                crumbs={[{
                    title: 'Invite Codes',
                }]}
                icon={'Lock'}
                withBorder
            />

            <Paper withBorder>
                <StrideLoader loading={inviteCodesQuery.isLoading}>
                    <StrideTable
                        columns={columns}
                        data={inviteCodes}
                        pagination
                        searchFilter={(data, filter) => {
                            return data.filter(inviteCode => {
                                return inviteCode.code.toLowerCase().includes(filter) ||
                                    inviteCode.account.name.toLowerCase().includes(filter) ||
                                    inviteCode.role.name.toLowerCase().includes(filter)
                            })
                        }}
                    />
                </StrideLoader>
            </Paper>
        </>
    )
}
export default AdminInviteCodes

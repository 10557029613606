import React from "react";
import { twMerge } from "tailwind-merge";
import Icon from '../Icon';

export const ButtonPrimary = ({ icon, saving, classNames='', children, fullWidth, ...other }) => {
    if (fullWidth) {
        classNames += 'w-full'
    }

    return (
        <button
            className={twMerge(
                'inline-flex items-center gap-x-1.5 rounded border border-torbu-peach bg-torbu-white px-3 py-1.5 font-torbu-medium text-sm text-torbu-green shadow-sm hover:bg-torbu-peach hover:text-torbu-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                classNames
            )}
            {...other}
        >
            {saving ? (
                <>
                    <Icon
                        icon={'Loader'}
                        size={6}
                        className="animate-spin-fast"
                    />
                    Saving
                </>
            ) : (
                <>
                    {icon && (
                        <Icon
                            icon={icon}
                            size={5}
                        />
                    )}

                    {children}
                </>
            )}
        </button>
    )
}
export default ButtonPrimary

import { Drawer, Group, Input, MultiSelect, Tabs, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { useId } from '@mantine/hooks'
import { isEmpty, sortBy } from 'lodash'
import React, { useState } from 'react'
import { IMaskInput } from 'react-imask'
import { useMutation, useQuery } from 'react-query'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import Can from '../../Helpers/Can'
import Icon from '../../Helpers/Icon'
import StrideLoader from '../../Helpers/StrideLoader'
import UsersWithAvatars from '../../Helpers/UsersWithAvatars'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import graphqlClient from '../../Utils/graphqlClient'
import CSVManager from './CSVManager'

export const UserDrawer = ({ userDrawer, setUserDrawer, account, userDirectoryModel, accountUsersModel }) => {
    const id = useId()
    const notify = useNotify()

    const formCreate = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            roleIds: []
        },
        validate: {
            firstName: isNotEmpty('First name is required'),
            lastName: isNotEmpty('Last name is required'),
            email: isNotEmpty('Email is required'),
            roleIds: isNotEmpty('You must select at least one role')
        }
    })

    const formDirectory = useForm({
        initialValues: {
            userIds: [],
            roleIds: []
        },
        validate: {
            userIds: isNotEmpty('You must select at least one user'),
            roleIds: isNotEmpty('You must select at least one role')
        }
    })

    const [roles, setRoles] = useState([])

    const rolesQuery = useQuery(['getAllRoles', { accountId: account.id }], {
        onSettled: data => {
            if (data.getAllRoles?.length) {
                let rolesData = data.getAllRoles

                let rolesOptions = rolesData.map(role => {
                    return {
                        value: role.id,
                        label: role.name,
                        inviteCode: !isEmpty(role.inviteCodes) ? role.inviteCodes[0].code : null
                    }
                })
                setRoles(sortBy(rolesOptions, 'label'))
            }
        }
    })

    const associateUsersGQL = useGQL('associateUsers')
    const associateUsersMutation = useMutation(data => graphqlClient.request(associateUsersGQL, data), {
        onSuccess: () => {
            accountUsersModel.invalidate().then(() => {
                notify.updated('User')
                // reset()
                setUserDrawer({})
                userDirectoryModel.invalidate()
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const preloadUserGQL = useGQL('preloadUser')
    const preloadUserMutation = useMutation(data => graphqlClient.request(preloadUserGQL, data), {
        onSuccess: () => {
            accountUsersModel.invalidate().then(() => {
                notify.created('created')
                // reset()
                setUserDrawer({})
                userDirectoryModel.invalidate()
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const saveDirectoryUser = data => {
        data.accountId = account.id
        associateUsersMutation.mutate(data)
    }

    const saveNewUser = data => {
        data.accountId = account.id
        preloadUserMutation.mutate(data)
    }

    return (
        <>
            <StrideLoader loading={rolesQuery.isLoading}>
                <Drawer
                    opened={!isEmpty(userDrawer)}
                    onClose={() => setUserDrawer({})}
                    title={'User Management'}
                    padding="md"
                    position="right"
                    size={'50%'}
                >
                    <Tabs defaultValue="directory">
                        <Tabs.List>
                            <Tabs.Tab
                                value="directory"
                                icon={
                                    <Icon
                                        icon={'Users'}
                                        size={14}
                                    />
                                }
                            >
                                User Directory
                            </Tabs.Tab>
                            <Tabs.Tab
                                value="newuser"
                                icon={
                                    <Icon
                                        icon={'Plus'}
                                        size={14}
                                    />
                                }
                            >
                                Create User
                            </Tabs.Tab>
                            <Tabs.Tab
                                value="csv"
                                icon={
                                    <Icon
                                        icon={'FileText'}
                                        size={14}
                                    />
                                }
                            >
                                Upload CSV
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel
                            value="directory"
                            pt="xs"
                        >
                            <form onSubmit={formDirectory.onSubmit(saveDirectoryUser)}>
                                <MultiSelect
                                    mt={15}
                                    label="Role(s) to assign to users"
                                    icon={
                                        <Icon
                                            icon={'Lock'}
                                            size={16}
                                        />
                                    }
                                    data={roles}
                                    required
                                    withinPortal
                                    searchable
                                    clearable
                                    {...formDirectory.getInputProps('roleIds')}
                                />

                                <MultiSelect
                                    label="Select User(s)"
                                    data={userDirectoryModel.renderOptions()}
                                    itemComponent={UsersWithAvatars}
                                    icon={
                                        <Icon
                                            icon={'Users'}
                                            size={16}
                                        />
                                    }
                                    limit={100}
                                    required
                                    withinPortal
                                    searchable
                                    clearable
                                    {...formDirectory.getInputProps('userIds')}
                                />

                                <Can manage={'team'}>
                                    <Group position={'right'}>
                                        <ButtonCreate type="submit">Add User(s)</ButtonCreate>
                                    </Group>
                                </Can>
                            </form>
                        </Tabs.Panel>

                        <Tabs.Panel
                            value="newuser"
                            pt="xs"
                        >
                            <form onSubmit={formCreate.onSubmit(saveNewUser)}>
                                <MultiSelect
                                    mt={15}
                                    icon={
                                        <Icon
                                            icon={'Lock'}
                                            size={16}
                                        />
                                    }
                                    label="Role(s) to assign to user"
                                    data={roles}
                                    withAsterisk
                                    withinPortal
                                    searchable
                                    clearable
                                    {...formCreate.getInputProps('roleIds')}
                                />

                                <Group grow>
                                    <TextInput
                                        mt={15}
                                        icon={
                                            <Icon
                                                icon={'User'}
                                                size={16}
                                            />
                                        }
                                        label="First Name"
                                        withAsterisk
                                        {...formCreate.getInputProps('firstName')}
                                    />
                                    <TextInput
                                        mt={15}
                                        icon={
                                            <Icon
                                                icon={'User'}
                                                size={16}
                                            />
                                        }
                                        label="Last Name"
                                        name="lastName"
                                        withAsterisk
                                        {...formCreate.getInputProps('lastName')}
                                    />
                                </Group>

                                <Group grow>
                                    <TextInput
                                        mt={15}
                                        icon={
                                            <Icon
                                                icon={'At'}
                                                size={16}
                                            />
                                        }
                                        label="Email"
                                        withAsterisk
                                        {...formCreate.getInputProps('email')}
                                    />

                                    <Input.Wrapper
                                        id={id}
                                        label="Phone"
                                        mt={15}
                                    >
                                        <Input
                                            component={IMaskInput}
                                            mask="000-000-0000"
                                            id={id}
                                            placeholder="555-555-5555"
                                            icon={
                                                <Icon
                                                    icon={'Phone'}
                                                    size={16}
                                                />
                                            }
                                            {...formCreate.getInputProps('phone')}
                                        />
                                    </Input.Wrapper>
                                </Group>

                                <Can manage={'team'}>
                                    <Group position={'right'}>
                                        <ButtonCreate type="submit">Add User(s)</ButtonCreate>
                                    </Group>
                                </Can>
                            </form>
                        </Tabs.Panel>

                        <Tabs.Panel
                            value="csv"
                            pt="xs"
                        >
                            <CSVManager
                                userDrawer={userDrawer}
                                setUserDrawer={setUserDrawer}
                                account={account}
                                roles={roles}
                            />
                        </Tabs.Panel>
                    </Tabs>
                </Drawer>
            </StrideLoader>
        </>
    )
}

export default UserDrawer

import { useState } from 'react'
import queryClient from '../../Utils/queryClient'
import {useQuery} from "react-query";
import usePermission from "../../Hooks/usePermission"

export const useEvents = (variables = {}) => {
    const [queryFilter, setFilter] = useState(variables)
    const { hasPermissionAnywhere } = usePermission()

    const key = 'getAllEvents'

    const query = useQuery(
        [key, queryFilter],
        { enabled: Object.keys(queryFilter).length > 0 }
    )

    // For now we only render team events if they have the permission
    // Personal events will be shown by default
    const events = hasPermissionAnywhere('event:view:v2') ? query.data?.getAllEvents.edges.map((element) => element.node) : [];

    const invalidate = () => {
        return queryClient.invalidateQueries({ queryKey: [key, queryFilter] })
    }

    return {
        data: events || [],
        setFilter: setFilter,
        invalidate: invalidate,
        isLoading: query.isLoading,
        isSuccess: query.isSuccess
    }
}

export default useEvents
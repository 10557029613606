import React from 'react';
import useCurrentUser from '../../Hooks/Models/useCurrentUser';
import ThemeHeader from './ThemeHeader';
import ExpiredAccountAlert from './ExpiredAccountAlert';

const isDev = process.env.REACT_APP_ENV !== 'production'

const SiteWrapper = ({ children, showHeader }) => {

    return (
        <>
            <div className={`${isDev === true ? 'debug-screens' : '' } relative h-screen w-screen bg-torbu-silver font-torbu-content text-torbu-green flex flex-col`}>
                {<ThemeHeader showHeader={showHeader} />}
                <div className="overflow-auto flex-1">{children}</div>
                {/* <ExpiredAccountAlert /> */}
            </div>
        </>
    )
}

export default SiteWrapper

import { Box, Divider, Drawer, Group, MultiSelect, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconAt, IconLock, IconPhone, IconTrash, IconUser } from '@tabler/icons-react'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import ButtonArchive from '../../Components/Buttons/ButtonArchive'
import ButtonEdit from '../../Components/Buttons/ButtonEdit'
import Can from '../../Helpers/Can'
import StrideLoader from '../../Helpers/StrideLoader'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import graphqlClient from '../../Utils/graphqlClient'
import queryClient from '../../Utils/queryClient'

export const UserEditDrawer = ({ drawerOpen, setDrawerOpen, user, account, setUsers }) => {
    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            roleIds: []
        },
        validate: {
            roleIds: isNotEmpty('Roles are required')
        }
    })

    useEffect(() => {
        form.setFieldValue('firstName', user.user.firstName)
        form.setFieldValue('lastName', user.user.lastName)
        form.setFieldValue('phone', user.user.phone)
        form.setFieldValue('email', user.user.email)
        form.setFieldValue(
            'roleIds',
            user.roles?.map(role => role.id)
        )
    }, [user])

    const [roles, setRoles] = useState([])
    const notify = useNotify()

    const getAllRolesGQL = useGQL('getAllRoles')
    const updateAccountUserGQL = useGQL('updateAccountUser')
    const getAllAccountUsersGQL = useGQL('getAllAccountUsers')
    const archiveAccountUserGQL = useGQL('archiveAccountUser')

    const rolesQuery = useQuery([getAllRolesGQL, { accountId: account.id }], {
        onSettled: data => {
            if (data.getAllRoles?.length) {
                let rolesData = data.getAllRoles

                let rolesOptions = rolesData.map(role => {
                    return {
                        value: role.id,
                        label: role.name
                    }
                })
                setRoles(sortBy(rolesOptions, 'label'))
            }
        }
    })
    const updateAccountUserMutation = useMutation(data => graphqlClient.request(updateAccountUserGQL, data), {
        onSuccess: () => {
            setUsers([])
            queryClient.invalidateQueries({ queryKey: [getAllAccountUsersGQL] }).then(() => {
                notify.updated('User')
            })
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const archiveUserMutation = useMutation(data => graphqlClient.request(archiveAccountUserGQL, data), {
        onSuccess: () => {
            notify.archived('User')
            window.location.reload(false)
        },
        onError: error => {
            console.error(error)
            notify.error()
        }
    })

    const updateUser = data => {
        updateAccountUserMutation.mutate({
            userId: user.user.id,
            accountId: account.id,
            roleIds: data.roleIds
        })
    }

    return (
        <>
            <Drawer
                opened={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                title={'User Management'}
                padding="md"
                position="right"
                size={'30%'}
            >
                <StrideLoader loading={rolesQuery.isLoading}>
                    <form onSubmit={form.onSubmit(updateUser)}>
                        <Group grow>
                            <TextInput
                                mt={15}
                                icon={<IconUser size={16} />}
                                label="First Name"
                                required
                                disabled
                                {...form.getInputProps('firstName')}
                            />
                            <TextInput
                                mt={15}
                                icon={<IconUser size={16} />}
                                label="Last Name"
                                name="lastName"
                                required
                                disabled
                                {...form.getInputProps('lastName')}
                            />
                        </Group>
                        <Group grow>
                            <TextInput
                                mt={15}
                                icon={<IconAt size={16} />}
                                label="Email"
                                required
                                disabled
                                {...form.getInputProps('email')}
                            />
                            <TextInput
                                mt={15}
                                icon={<IconPhone size={16} />}
                                label="Phone"
                                disabled
                                {...form.getInputProps('phone')}
                            />
                        </Group>

                        <MultiSelect
                            {...form.getInputProps('roleIds')}
                            mt={15}
                            icon={<IconLock size={16} />}
                            label="Role(s) to assign to user"
                            data={roles}
                            withinPortal
                            required
                            searchable
                        />
                        <Can manage={'team'}>
                            <Group position={'right'}>
                                <ButtonEdit type="submit">Update User</ButtonEdit>
                            </Group>
                        </Can>
                    </form>

                    <Can manage={'team'}>
                        <Divider my={16} />

                        <Box>
                            <Text
                                color={'#3b3b3b'}
                                fw={'bold'}
                                size={'md'}
                            >
                                Remove this user
                            </Text>
                            <Text
                                color={'#777777'}
                                fw={300}
                                size={'sm'}
                            >
                                This will only archive the user from this account. The user will no longer be able to access any data on this account, unless they are on an account directly related to this account.
                            </Text>
                        </Box>
                        <Group
                            mt="xl"
                            position={'right'}
                        >
                            <ButtonArchive
                                modalTitle={'Are you sure you want to remove this user from the account?'}
                                onConfirm={() => {
                                    archiveUserMutation.mutate({
                                        userId: user.user.id,
                                        accountId: account.id
                                    })
                                }}
                                fullWidth
                                leftIcon={<IconTrash size={'1.0rem'} />}
                            >
                                Remove from account
                            </ButtonArchive>
                        </Group>

                        <Divider my={16} />
                    </Can>
                </StrideLoader>
            </Drawer>
        </>
    )
}

export default UserEditDrawer

/* eslint-disable */
import { Box, Divider, Grid, Group, Menu, Modal, Paper, Rating, ScrollArea, Select, Table, Text, TextInput, Textarea, Tooltip, useMantineTheme } from '@mantine/core'
import { IconDots, IconDotsVertical, IconEdit, IconFolder, IconMoodCrazyHappy, IconMoodCry, IconMoodEmpty, IconMoodHappy, IconMoodSad, IconMoodSmile, IconPlus, IconTrash } from '@tabler/icons-react'
import { cloneDeep, filter } from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Folder from './Folder'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'

const SelectItem = React.forwardRef(({ label, description, ...others }, ref) => (
    <div
        ref={ref}
        {...others}
    >
        <Group noWrap>
            <Box>
                {label === 'Add New Journal' ? (
                    <>
                        <IconPlus />
                    </>
                ) : (
                    <>
                        <IconFolder />
                    </>
                )}
            </Box>
            <Box>
                <Text size="sm">{label}</Text>
            </Box>
        </Group>
    </div>
))

export const List = () => {
    const { control } = useForm()
    const [journal, setJournal] = useState('')
    const [selectedJournal, setSelectedJournal] = useState('')
    const [view, setView] = useState('journalEntryNew')
    const [journals, setJournals] = useState([])
    const [journalEntries, setJournalEntries] = useState([])
    const [journalEntry, setJournalEntry] = useState('')
    const [modalOpen, setModalOpen] = useState(false)

    const getEmptyIcon = value => {
        const defaultProps = {
            size: 30,
            color: 'gray'
        }
        switch (value) {
            case 1:
                return <IconMoodCry {...defaultProps} />
            case 2:
                return <IconMoodSad {...defaultProps} />
            case 3:
                return <IconMoodSmile {...defaultProps} />
            case 4:
                return <IconMoodHappy {...defaultProps} />
            case 5:
                return <IconMoodCrazyHappy {...defaultProps} />
            default:
                return <IconMoodEmpty {...defaultProps} />
        }
    }

    const getFullIcon = value => {
        const defaultProps = {
            size: 30
        }
        const theme = useMantineTheme()

        switch (value) {
            case 1:
                return (
                    <IconMoodCry
                        {...defaultProps}
                        color={theme.colors.red[7]}
                    />
                )
            case 2:
                return (
                    <IconMoodSad
                        {...defaultProps}
                        color={theme.colors.orange[7]}
                    />
                )
            case 3:
                return (
                    <IconMoodSmile
                        {...defaultProps}
                        color={theme.colors.yellow[7]}
                    />
                )
            case 4:
                return (
                    <IconMoodHappy
                        {...defaultProps}
                        color={theme.colors.lime[7]}
                    />
                )
            case 5:
                return (
                    <IconMoodCrazyHappy
                        {...defaultProps}
                        color={theme.colors.green[7]}
                    />
                )
            default:
                return <IconMoodEmpty {...defaultProps} />
        }
    }

    const handleJournalChange = journalSelected => {
        if (journalSelected === 'Add New Journal') {
            setModalOpen(true)
        } else {
            buildJournalEntry('journalId', journalSelected)
        }
    }

    const saveJournal = () => {
        setJournals(old => [
            ...old,
            {
                title: journal
            }
        ])

        setSelectedJournal(journal)
        setJournal('')
        setModalOpen(false)
    }

    const saveJournalEntry = () => {
        let entry = cloneDeep(journalEntry)
        entry.journalId = selectedJournal
        setJournalEntries(old => [...old, entry])

        setSelectedJournal('')
        setJournalEntry('')
        setJournal('')
    }

    const buildJournalEntry = (key, value) => {
        setJournalEntry(value)
    }

    return (
        <>
            <Grid
                gutter={0}
                grow
                m={0}
            >
                <Grid.Col span={3}>
                    <div className="site-column grey">
                        <Box
                            pt={15}
                            spacing={0}
                            mb={50}
                        >
                            <Group
                                align
                                ml={12}
                            >
                                <Box>
                                    <IconFolder
                                        size={'30'}
                                        color={'#999'}
                                    />
                                </Box>
                                <Box>
                                    <Text
                                        fw={'400'}
                                        size={'lg'}
                                        color={'dark'}
                                    >
                                        My Journals
                                    </Text>
                                </Box>
                            </Group>
                            <ScrollArea.Autosize
                                type={'never'}
                                h={'calc(90vh)'}
                            >
                                {journals.map(journal => (
                                    <Folder>
                                        <Box
                                            onClick={() => {
                                                setView('journalEntries')
                                                setSelectedJournal(journal.title)
                                            }}
                                        >
                                            <Box>
                                                <Tooltip
                                                    label={journal.title}
                                                    position={'bottom'}
                                                    withArrow
                                                >
                                                    <Text
                                                        lineClamp={1}
                                                        fw={400}
                                                        size={'md'}
                                                    >
                                                        {journal.title}
                                                    </Text>
                                                </Tooltip>
                                            </Box>
                                            <Box mt={80}>
                                                <Group position={'apart'}>
                                                    <Box>
                                                        <Text
                                                            color={'dimmed'}
                                                            fw={300}
                                                            size={'sm'}
                                                        >
                                                            27th April, 2021
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <Menu>
                                                            <Menu.Target>
                                                                <IconDots />
                                                            </Menu.Target>

                                                            <Menu.Dropdown>
                                                                <Menu.Item icon={<IconEdit size={14} />}>Edit Journal Name</Menu.Item>
                                                                <Menu.Item icon={<IconTrash size={14} />}>Archive Journal</Menu.Item>
                                                            </Menu.Dropdown>
                                                        </Menu>
                                                    </Box>
                                                </Group>
                                            </Box>
                                        </Box>
                                    </Folder>
                                ))}
                                <Folder>
                                    <Box
                                        onClick={() => setModalOpen(true)}
                                        ta={'center'}
                                        py={15}
                                    >
                                        <Box>
                                            <IconPlus
                                                size={48}
                                                color={'#999'}
                                            />
                                        </Box>
                                        <Box>
                                            <Text
                                                lineClamp={1}
                                                fw={300}
                                                size={'md'}
                                            >
                                                Add New Journal
                                            </Text>
                                        </Box>
                                    </Box>
                                </Folder>
                            </ScrollArea.Autosize>
                        </Box>
                    </div>
                </Grid.Col>
                <Grid.Col span={9}>
                    <div className="site-column">
                        {view === 'journalEntryNew' && (
                            <>
                                <Box m={'sm'}>
                                    <Group
                                        position={'apart'}
                                        ml={12}
                                    >
                                        <Group align>
                                            <Box>
                                                <IconPlus
                                                    size={'30'}
                                                    color={'#999'}
                                                />
                                            </Box>
                                            <Box>
                                                <Text
                                                    fw={'400'}
                                                    size={'lg'}
                                                    color={'dark'}
                                                >
                                                    Journal Entry
                                                </Text>
                                            </Box>
                                        </Group>
                                        <Box>
                                            <ButtonCreate
                                                type="submit"
                                                onClick={() => {
                                                    setView('journalEntryNew')
                                                    setJournalEntry({})
                                                }}
                                            >
                                                Add Journal Entry
                                            </ButtonCreate>
                                        </Box>
                                    </Group>

                                    <Divider
                                        mt={15}
                                        mb={15}
                                        color={'#E0E0E0'}
                                    />

                                    <Box mb={15}>
                                        <TextInput
                                            size="xl"
                                            placeholder="What do you want to write about today?"
                                            value={journalEntry?.title}
                                            withAsterisk
                                            onChange={e => buildJournalEntry('title', e.currentTarget.value)}
                                        />
                                    </Box>

                                    <Box mb={15}>
                                        <Grid>
                                            <Grid.Col span={6}>
                                                <Select
                                                    size="xl"
                                                    placeholder="Add to Journal"
                                                    data={journals.map(j => j.title).concat('Add New Journal')}
                                                    itemComponent={SelectItem}
                                                    value={selectedJournal || journalEntry?.journalId}
                                                    icon={<IconFolder />}
                                                    onChange={e => handleJournalChange(e)}
                                                    withAsterisk
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                                <Paper
                                                    withBorder
                                                    p={13}
                                                >
                                                    <Group position={'apart'}>
                                                        <Text
                                                            fw={'300'}
                                                            size={'xl'}
                                                            color={'dimmed'}
                                                        >
                                                            How are you feeling today?
                                                        </Text>
                                                        <Rating
                                                            emptySymbol={getEmptyIcon}
                                                            fullSymbol={getFullIcon}
                                                            highlightSelectedOnly
                                                            value={journalEntry?.emotionLevel}
                                                            onChange={value => buildJournalEntry('emotionLevel', value)}
                                                        />
                                                    </Group>
                                                </Paper>
                                            </Grid.Col>
                                        </Grid>
                                    </Box>

                                    <Textarea
                                        size="xl"
                                        name="eventDescription"
                                        value={journalEntry?.note}
                                        placeholder="Write about anything"
                                        onChange={value => buildJournalEntry('note', value)}
                                        {...{ control }}
                                    />
                                    <Divider my={'lg'} />

                                    <Group position={'right'}>
                                        <ButtonCreate
                                            type="submit"
                                            onClick={saveJournalEntry}
                                        >
                                            Save Journal Entry
                                        </ButtonCreate>
                                    </Group>
                                </Box>
                            </>
                        )}

                        {view === 'journalEntries' && (
                            <>
                                <Box m={'sm'}>
                                    <Group
                                        position={'apart'}
                                        ml={12}
                                    >
                                        <Group align>
                                            <Box>
                                                <IconPlus
                                                    size={'30'}
                                                    color={'#999'}
                                                />
                                            </Box>
                                            <Box>
                                                <Text
                                                    fw={'400'}
                                                    size={'lg'}
                                                    color={'dark'}
                                                >
                                                    Entries for {selectedJournal}
                                                </Text>
                                            </Box>
                                        </Group>
                                        <Box>
                                            <ButtonCreate
                                                type="submit"
                                                onClick={() => {
                                                    setView('journalEntryNew')
                                                    setJournalEntry({})
                                                }}
                                            >
                                                Add Journal Entry
                                            </ButtonCreate>
                                        </Box>
                                    </Group>

                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th width={1000}>Title</th>
                                                <th width={25}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filter(journalEntries, entry => entry.journalId === selectedJournal).map(journalEntry => (
                                                <tr
                                                    className="pointer"
                                                    onClick={() => {
                                                        setJournalEntry(journalEntry)
                                                        setView('journalEntryNew')
                                                    }}
                                                >
                                                    <td>{journalEntry.title}</td>
                                                    <td>
                                                        <Menu
                                                            shadow="md"
                                                            width={200}
                                                        >
                                                            <Menu.Target>
                                                                <Box
                                                                    variant={'none'}
                                                                    className="pointer"
                                                                >
                                                                    <IconDotsVertical />
                                                                </Box>
                                                            </Menu.Target>

                                                            <Menu.Dropdown>
                                                                <Menu.Item
                                                                    color="red"
                                                                    icon={<IconTrash size={14} />}
                                                                >
                                                                    Archive Journal Entry
                                                                </Menu.Item>
                                                            </Menu.Dropdown>
                                                        </Menu>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Box>
                            </>
                        )}
                    </div>
                </Grid.Col>
            </Grid>

            <Modal
                opened={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Journal Management"
                size="md"
            >
                <Box mb={15}>
                    <Text fw={400}>Journals allow for you to organize your entries into similar topics for easy searching, managing, and organization.</Text>
                </Box>
                <Box mb={15}>
                    <TextInput
                        label="Journal Name:"
                        onChange={e => setJournal(e.currentTarget.value)}
                        withAsterisk
                    />
                </Box>
                <Divider my={'lg'} />

                <Group position={'right'}>
                    <ButtonCreate
                        onClick={saveJournal}
                    >
                        Save Journal Entry
                    </ButtonCreate>
                </Group>
            </Modal>
        </>
    )
}
export default List

import {sortBy} from "lodash";
import queryClient from "../../Utils/queryClient"
import useGetQuery from "../useGetQuery"

const useEventTypes = (variables={}) => {
    const key = 'getAllEventTypes'

    const query = useGetQuery(
        key,
        variables,
        'id',
        (data) => {
            return sortBy(data, eventType => eventType.title.toLowerCase(), ['title'])
        },
        {}
    )

    const invalidate = () => {
        return queryClient.invalidateQueries({ queryKey: [key, variables]})
    }

    return {
        data: query.data || [],
        invalidate: invalidate,
        isLoading: query.isLoading,
        isSuccess: query.isSuccess
    }
}
export default useEventTypes
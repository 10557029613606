import React, { Fragment, createContext, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ButtonPrimary from './Buttons/ButtonPrimary'
import Icon from './Icon'
import { twMerge } from 'tailwind-merge'

const TorbuModalContext = createContext()
const useTorbuModalContext = () => {
    return useContext(TorbuModalContext)
}

const TorbuModal = ({
    open,
    type = 'confirm',
    title,
    content,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    onCancel,
    onConfirm,
    onClose,
    modalClassName = '',
    modalStyle
}) => {
    const { closeModal } = useTorbuModal()
    if (!onClose) onClose = closeModal

    const modalTypeContent = {
        blank: null,
        alert: (
            <>
                <ButtonPrimary onClick={() => {
                    closeModal()
                }}>Okay</ButtonPrimary>
            </>
        ),
        confirm: (
            <>
                <ButtonPrimary onClick={() => {
                    closeModal()
                    onCancel?.()
                }} classNames='border-torbu-gray hover:bg-torbu-gray'>Cancel</ButtonPrimary>
                <ButtonPrimary onClick={() => {
                    closeModal()
                    onConfirm?.()
                }} classNames='bg-red-400 border-red-600 hover:bg-red-600'>Confirm</ButtonPrimary>
            </>
        ),
        archive: (
            <>
                <ButtonPrimary onClick={() => {
                    closeModal()
                    onCancel?.()
                }} classNames='border-torbu-gray hover:bg-torbu-gray'>Cancel</ButtonPrimary>
                <ButtonPrimary onClick={() => {
                    closeModal()
                    onConfirm?.()
                }} classNames='bg-red-400 border-red-600 hover:bg-red-600'>Archive</ButtonPrimary>
            </>
        )
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-modal" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={twMerge('relative transform overflow-hidden rounded-lg bg-white p-4\
                             text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm', modalClassName)} style={modalStyle}>
                                <div>
                                    {type === 'archive' && (
                                        <div className='w-10 h-10 mx-auto mb-5'>
                                            <Icon icon={'Archive'} size={10} />
                                        </div>
                                    )}
                                    {title && (
                                        <Dialog.Title as="h3" className="font-torbu-heading mb-4 text-center">
                                            {title}
                                        </Dialog.Title>
                                    )}
                                    <div className='font-torbu-content'>
                                        {content}
                                    </div>
                                </div>
                                {modalTypeContent[type] && (
                                    <div className="mt-5 sm:mt-6 flex justify-end gap-2">
                                        {modalTypeContent[type]}
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export const TorbuModalProvider = ({ children }) => {
    const [options, setOptions] = useState({
        open: false
    })
    // for the closeModal function to prevent it from closing the modal with outdated
    // options if it was passed as a callback somewhere
    const currentOptions = useRef(options)
    useEffect(() => {
        currentOptions.current = options
    }, [options])
    return (
        <TorbuModalContext.Provider value={{
            setOptions,
            currentOptions
        }}>
            {children}
            <TorbuModal {...options} />
        </TorbuModalContext.Provider>
    )
}

export const useTorbuModal = () => {
    const { setOptions, currentOptions } = useTorbuModalContext()

    return {
        openBlankModal(options) {
            setOptions({ ...options, open: true, type: 'blank' })
        },
        openConfirmModal(options) {
            setOptions({ ...options, open: true, type: 'confirm' })
        },
        openArchiveModal(options) {
            setOptions({ ...options, open: true, type: 'archive' })
        },
        openAlertModal(options) {
            setOptions({ ...options, open: true, type: 'alert' })
        },
        closeModal() {
            const options = currentOptions.current
            setOptions({ ...options, open: false })
            options.onClose?.()
        }
    }
}

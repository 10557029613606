import { Box, Group, Loader, Modal, Text } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import TorbuAvatar from '../../Components/TorbuAvatar'
import Icon from '../../Helpers/Icon'
import StrideAvatar from '../../Helpers/StrideAvatar'
import useCurrentAccount from '../../Hooks/Models/useCurrentAccount'
import useCurrentUser from '../../Hooks/Models/useCurrentUser'
import useGQL from '../../Hooks/useGQL'
import { useNotify } from '../../Hooks/useNotify'
import graphqlClientUpload from '../../Utils/graphqlClientUpload'

export const AvatarUploader = ({ user, account, size }) => {
    const { currentUser, setCurrentUser, resetCurrentUser } = useCurrentUser()
    const { currentAccount, setCurrentAccount, resetCurrentAccount } = useCurrentAccount()

    const [isUploading, setIsUploading] = useState(false)
    const [opened, setOpened] = useState(false)
    const [entity, setEntity] = useState('')
    const [entityType, setEntityType] = useState('')

    const notify = useNotify()

    useEffect(() => {
        if (!isUndefined(user)) {
            setEntityType('user')
            setEntity(user)
        }
    }, [user])

    useEffect(() => {
        if (!isUndefined(account)) {
            setEntityType('account')
            setEntity(account)
        }
    }, [account])

    const uploadFilesGQL = useGQL('uploadFiles')
    const uploadFilesMutation = useMutation(data => graphqlClientUpload.request(uploadFilesGQL, data), {
        onSuccess: data => {
            notify.updated('Avatar')
            if (entityType === 'user' && entity.id === currentUser.id) {
                resetCurrentUser()
            }

            if (entityType === 'account' && entity.id === currentAccount.id) {
                resetCurrentAccount()
            }

            setIsUploading(false)
            handleModalClose()
            window.location.reload(true)
        },
        onError: () => {
            notify.error()
            setIsUploading(false)
        }
    })

    const handleModalClose = () => {
        setIsUploading(false)
        setOpened(false)
    }

    const handleRejectionFileUpload = () => {
        setIsUploading(false)
    }

    const handleFileUpload = file => {
        setIsUploading(true)
        const uploads = {
            entityId: entity.id,
            files: file
        }
        uploadFilesMutation.mutate(uploads)
    }

    const strideAvatarProps = {}
    if (entityType.length) strideAvatarProps[entityType] = entity

    return (
        <>
            <div className="flex gap-4">
                <div>
                    <TorbuAvatar
                        size={'xl'}
                        rounded={false}
                        className={'pointer'}
                        onClick={() => setOpened(true)}
                        {...{ [entityType]: entity }}
                    />
                </div>
                <div>Click image to upload a new avatar</div>
            </div>

            <Modal
                opened={opened}
                onClose={handleModalClose}
                title="Upload a new avatar"
                size={'xl'}
            >
                <Box>
                    {isUploading ? (
                        <>
                            <div className="flex w-full flex-col p-8 text-center">
                                <div className="mx-auto mb-2">
                                    <Loader
                                        variant="bars"
                                        size={30}
                                    />
                                </div>

                                <div>
                                    <Text size={'xl'}>Uploading your new avatar...</Text>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Dropzone
                                onDrop={handleFileUpload}
                                onReject={handleRejectionFileUpload}
                                maxSize={30 * 1024 ** 2}
                                multiple={false}
                                maxFiles={1}
                                autoFocus={true}
                                radius={'lg'}
                                accept={IMAGE_MIME_TYPE}
                            >
                                <Dropzone.Accept>
                                    <div className="flex w-full flex-col p-8 text-center">
                                        <div className="mx-auto mb-2">
                                            <Icon
                                                icon={'Photo'}
                                                size={120}
                                            />
                                        </div>

                                        <div>
                                            <Text size="md">Image has been accepted.</Text>
                                        </div>
                                    </div>
                                </Dropzone.Accept>

                                <Dropzone.Reject>
                                    <div className="flex w-full flex-col p-8 text-center">
                                        <div className="mx-auto mb-2">
                                            <Icon
                                                icon={'Photo'}
                                                size={120}
                                                color={'red'}
                                            />
                                        </div>

                                        <div>
                                            <Text size="md">Images must be a .png, .gif, or .jpeg and be under 30MB</Text>
                                        </div>
                                    </div>
                                </Dropzone.Reject>

                                <Dropzone.Idle>
                                    <div className="flex w-full flex-col p-8 text-center">
                                        <div className="mx-auto mb-2">
                                            <Icon
                                                icon={'Photo'}
                                                size={120}
                                            />
                                        </div>

                                        <div>
                                            <Text size="md">Drag your image here or click to select an image</Text>
                                        </div>
                                        <div>
                                            <Text
                                                size="sm"
                                                color="dimmed"
                                                mt={7}
                                            >
                                                Images must be a .png, .gif, or .jpeg and be under 5MB
                                            </Text>
                                        </div>
                                    </div>
                                </Dropzone.Idle>
                            </Dropzone>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    )
}
export default AvatarUploader

/* eslint-disable */
import { flatten, isEmpty, uniq } from 'lodash'
import useCurrentUser from './Models/useCurrentUser'
const usePermission = () => {
    const { permissions, currentUser } = useCurrentUser()

    const hasTorbuAdmin = () => {
        return currentUser.torbuAdmin
    }

    /**
     * Returns true is the current user is a supervisor in the given accountId
     */
    const isAccountSupervisor = accountId => {
        const idList = currentUser?.accountSupervisors.map(item => item.account.id)

        if (idList) {
            return idList.includes(accountId)
        }

        return false
    }

    /**
     * Return true if user has permission for given team accountId
     */
    const hasPermissionInAccount = (permission, accountId) => {
        if (hasTorbuAdmin() || isAccountSupervisor(accountId)) {
            return true
        }

        if (!isEmpty(permissions)) {
            const set = permissions.find(set => set.accountId === accountId)
            if (set) {
                return set.permissions.includes(permission)
            }
            return false
        }
        return false
    }

    /**
     * Returns true if user has the permission in at least one of their assoicated teams or
     * if the user has an account supervisor on any team
     * Given "torbuAdmin" returns true only if user torbuAdmin flag is true
     */
    const hasPermissionAnywhere = permission => {
        if (permission === 'torbuAdmin') {
            return hasTorbuAdmin()
        }

        if (!permission) {
            return true
        }

        if (!isEmpty(permissions)) {
            const rolledUpPermissions = uniq(flatten(permissions.filter(account => account.permissions.length).map(account => account.permissions)))

            return rolledUpPermissions.includes(permission) || rolledUpPermissions.includes('supervisor:v2') || hasTorbuAdmin()
        }
        return false
    }

    /**
     * Returns true if the user created the given event or has the event:manage permission in
     * at least one of the teams associated with the event
     * Returns true if user is supervisor for this team
     */
    const hasEventEdit = event => {
        if (hasTorbuAdmin()) {
            return true
        }

        if (event && event.accounts && Array.isArray(event.accounts)) {
            var hasEventManage = event.accounts.some(account => hasPermissionInAccount('event:manage', account.id))
            var hasSupervisor = event.accounts.some(account => isAccountSupervisor(account.id))
            var isEventCreator = event.createdBy.id === currentUser.id

            return hasEventManage || isEventCreator || hasSupervisor
        }
        return false
    }

    /**
     * Returns true if the user created the channel or has the channel:manage permission in
     * at least one of the teams associated with the channel
     * Returns true if user is supervisor for this team
     */
    const hasChannelEdit = channel => {
        if (hasTorbuAdmin()) {
            return true
        }

        if (channel) {
            var isChannelCreator = channel.createdBy.id === currentUser.id && hasPermissionAnywhere('channel:create:v2')
            var hasSupervisor = channel.accountIds.some(id => isAccountSupervisor(id))
            var hasChannelManage = channel.accountIds.some(id => hasPermissionInAccount('channel:manage', id))
            return isChannelCreator || hasChannelManage || hasSupervisor
        }
        return false
    }

    /**
     * Returns true if the user created the message or has the channel:manageMessage permission
     * in at least one of the teams associated with the channel
     * Returns true if user is supervisor for this team
     */
    const hasMessageDelete = message => {
        if (hasTorbuAdmin()) {
            return true
        }

        if (message && message.channel && message.channel.accountIds) {
            var isMessageCreator = currentUser.id === message.createdBy.id && hasPermissionAnywhere('channel:create:v2')
            var hasManageMessage = message.channel.accountIds.some(id => hasPermissionInAccount('message:manage', id))
            var hasSupervisor = message.channel.accountIds.some(id => isAccountSupervisor(id))

            return isMessageCreator || hasManageMessage || hasSupervisor
        }
        return false
    }

    return { hasPermissionAnywhere, hasPermissionInAccount, hasEventEdit, hasChannelEdit, hasMessageDelete }
}
export default usePermission

import { Box, Container, Grid, Image } from '@mantine/core'
import React from 'react'
import GreenMountainBackground from '../../Theme/images/green-mtn-background.svg'
import TorbuGreenIconLogo from '../../Theme/images/icon_logo_green.svg'
import Line from '../../Theme/images/line.svg'
import TorbuGreenFullLogo from '../../Theme/images/logo-full-green.svg'

const isDev = process.env.REACT_APP_ENV !== 'production'

const ExternalSiteWrapper = ({ marketing, children }) => {
    return (
        <>
            <div
                style={{ backgroundImage: `url(${Line})`, backgroundSize: '95%' }}
                className={`${isDev === true ? 'debug-screens' : ''} relative mb-10 h-full w-screen overflow-scroll bg-torbu-peach bg-cover bg-clip-content bg-[left_calc(0%)_top_calc(15%)] bg-no-repeat font-torbu-demi-bold sm:h-screen`}
            >
                <div
                    style={{ backgroundImage: `url(${GreenMountainBackground})`, backgroundSize: 'calc(100vh / 2)', backgroundPosition: 'left bottom' }}
                    className="h-screen w-screen bg-cover bg-clip-content bg-no-repeat"
                >
                    <div className="absolute w-full sm:top-8">
                        <div className="mx-auto sm:mx-10 sm:flex md:mx-4 lg:mx-14">
                            <div className="torbu-logo p-4 sm:w-24 sm:p-0 md:w-auto">
                                <a href="/">
                                    <Image
                                        src={TorbuGreenFullLogo}
                                        alt="Torbu"
                                    />
                                </a>
                            </div>
                            <div className="hidden w-full flex-1 sm:block">
                                <div className="text-md flex h-full w-full flex-row-reverse items-center gap-4 font-torbu-bold font-bold text-torbu-white sm:text-xl md:text-lg lg:gap-16 lg:text-xl">
                                    <div>
                                        <a
                                            href="mailto:support@torbu.com"
                                            className="transition duration-500 hover:text-torbu-green"
                                            title="Contact Us Today"
                                        >
                                            Contact Us
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            href="https://www.torbu.com/enterprise"
                                            className="transition duration-500 hover:text-torbu-green"
                                            target="_blank"
                                            rel="noreferrer"
                                            title="Checkout out how Torbu can help your enterprise."
                                        >
                                            Enterprise
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            href="https://www.torbu.com/#Support-Section"
                                            className="transition duration-500 hover:text-torbu-green"
                                            target="_blank"
                                            rel="noreferrer"
                                            title="See how Torbu can help support you today."
                                        >
                                            How We Support You
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="sign-up-window pt-28 sm:pt-40 lg:pt-0">
                        <div className="grid gap-0 lg:h-screen lg:grid-cols-2">
                            {/* Left Side */}
                            <div className="items-center justify-center sm:flex ">
                                <div className="m-4 max-w-[355px] space-y-2 rounded-md border border-torbu-green bg-torbu-white p-4 sm:m-0 sm:max-w-[468px] sm:space-y-4 sm:p-8">{children}</div>
                            </div>
                            {/* End Left Side */}

                            {/* Right Side */}
                            <div className="hidden items-center justify-center sm:flex">
                                <div className="mt-8 p-4 md:mt-0 md:p-8">{marketing}</div>
                            </div>
                            {/* End Right Side */}
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default ExternalSiteWrapper

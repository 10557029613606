import { useState } from "react"
import useCurrentAccount from "./useCurrentAccount"
import { useQuery } from "react-query"

const useStripeUrl = () => {
    const { currentAccount } = useCurrentAccount()
    if (!currentAccount) return undefined
    const query = useQuery(['getStripeUrl', {
        accountId: currentAccount.id
    }])
    return query.data?.getStripeUrl
}

export default useStripeUrl

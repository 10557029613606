import { Box,Container,Grid,Group,Image,Paper,PasswordInput,Popover,Progress,SimpleGrid,Space,Text,TextInput,Title } from '@mantine/core'
import { isNotEmpty,useForm } from '@mantine/form'
import React,{ useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'
import Icon from '../../../Helpers/Icon'
import IconText from '../../../Helpers/IconText'
import useCurrentAccount from '../../../Hooks/Models/useCurrentAccount'
import useCurrentUser from '../../../Hooks/Models/useCurrentUser'
import '../../../Theme/css/external.css'
import state from '../../../Utils/State'
import graphqlClient from '../../../Utils/graphqlClient'
import PasswordRequirement from '../../Authentication/PasswordRequirement'

const communityTypeOptions = [
    {
        title: 'Education',
        value: 'education',
        icon: 'School',
        iconColor: '#FFC457',
        bg: '#FFF9ED'
    },
    {
        title: 'Sports',
        value: 'sports',
        icon: 'BallAmericanFootball',
        iconColor: '#56BFB2',
        bg: '#F2FDFC'
    },
    {
        title: 'Corporate',
        value: 'corporate',
        icon: 'Building',
        iconColor: '#3CABFF',
        bg: '#F4FAFF'
    },

    {
        title: 'Construction',
        value: 'construction',
        icon: 'Gavel',
        iconColor: '#917EDA',
        bg: '#F2FDFC'
    },
    {
        title: 'Engineering',
        value: 'engineering',
        icon: 'Code',
        iconColor: '#C47ECE',
        bg: '#FFF9FF'
    },
    {
        title: 'Other',
        value: 'other',
        icon: 'Code',
        iconColor: '#757575',
        bg: '#FCFCFC'
    }
]

const passwordRequirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' }
]

const getStrength = password => {
    let multiplier = password.length > 5 ? 0 : 1
    passwordRequirements.forEach(requirement => {
        if (!requirement.re.test(password)) {
            multiplier += 1
        }
    })
    return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10)
}

const SignUp = ({ page, setPage, errorMessage, setErrorMessage, sharedData, setSharedData }) => {
    const [sessionId, setSessionId] = useRecoilState(state.sessionId)
    const { setCurrentUser } = useCurrentUser()
    const { setCurrentAccount } = useCurrentAccount()
    const [loading, setLoading] = useState(false)

    useQuery(['getCurrentUser'], {
        onSuccess: data => {
            if (data?.getCurrentUser) {
                setCurrentUser(data.getCurrentUser)
                if (data.getCurrentUser.accounts?.length === 1) {
                    setCurrentAccount(data.getCurrentUser.accounts[0])
                } else {
                    setCurrentAccount({})
                }
            }
            window.location.reload(false)
        },
        enabled: !!sessionId
    })

    
    const form = useForm({
        initialValues: {
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            communityType: ''
        },
        validate: {
            name: isNotEmpty('Primary account name is required'),
            firstName: isNotEmpty('First name is required'),
            lastName: isNotEmpty('Last name is required'),
            email: value => (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ? null : 'Email is invalid'),
            password: isNotEmpty('Password is required'),
            communityType: isNotEmpty('Must select 1 type of community')
        }
    })

    const [communityType, setCommunityType] = useState('')

    const [popoverOpened, setPopoverOpened] = useState(false)
    const passwordChecks = passwordRequirements.map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(form?.values?.password)}
        />
    ))

    const passwordStrength = getStrength(form?.values?.password)
    const color = passwordStrength === 100 ? 'teal' : passwordStrength > 50 ? 'yellow' : 'red'

    const handleCommunityTypeChange = type => {
        if (communityType && communityType === type.value) {
            setCommunityType('')
            form.setFieldValue('communityType', '')
        } else {
            setCommunityType(type.value)
            form.setFieldValue('communityType', type.value)
        }
    }

    const signupUser = data => {
        
        if (passwordStrength !== 100) {
            setLoading(false)
            form.setFieldError('password', 'Does not meet minimal password requirements.')
        } else {
            setLoading(true)
            const expiration = new Date()
            expiration.setDate(expiration.getDate() + 30)
            const reqData = {
                jsiNewUser: {
                    email: data.email,
                    password: data.password,
                    firstName: data.firstName,
                    lastName: data.lastName
                },
                accountName: data.name,
                communityType: data.communityType,
                trialExpiresAt: expiration.toISOString()
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Stride-Auth': 'web'
                },
                body: JSON.stringify(reqData)
            }
            fetch(`${process.env.REACT_APP_STRIDE_API_ENDPOINT}/register-account`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.error) {
                        setLoading(false)
                        setErrorMessage({ errors: [response.error.message] })
                    } else {
                        setErrorMessage({})
                        if (response && response.session && response.session.id) {
                            graphqlClient.setHeader('Stride-Web-Token', response.session.id)
                            setSessionId(response.session.id)
                        }
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.error('error', error)
                })
        }
    }

    return (
        <>
            <div>
                {loading ? (
                    <>
                        <div className="flex flex-col w-full p-8 text-center border rounded-lg h-15 border-torbu-gray bg-torbu-white">
                            <div className="mx-auto mb-4">
                                <Icon
                                    icon={'Loader'}
                                    size={40}
                                    className="animate-spin-slow"
                                />
                            </div>
                            <div>Creating your Team and User account...</div>
                        </div>
                    </>
                ) : (
                    <form onSubmit={form.onSubmit(signupUser)}>
                        <div className="text-lg font-torbu-heading">What would you like to name your account?</div>

                        <TextInput
                            mt={'xl'}
                            placeholder={'Ex: company, team, group, event name.'}
                            size={'md'}
                            mb={'xl'}
                            icon={<Icon icon={'User'} />}
                            {...form.getInputProps('name')}
                        />

                        <div className="text-lg font-torbu-heading">What is your community type?</div>

                        <SimpleGrid
                            spacing={'xs'}
                            cols={2}
                            mb={form?.errors?.communityType ? 'md' : 'xl'}
                            style={form?.errors?.communityType ? { border: '1px solid red', padding: '8px', borderRadius: '8px' } : {}}
                        >
                            {communityTypeOptions.map((community, index) => (
                                <React.Fragment key={index}>
                                    <Paper
                                        withBorder
                                        radius={'md'}
                                        shadow={'md'}
                                        p={'sm'}
                                        className={`community-type ${communityType === community.value ? 'selected' : ''}`}
                                        onClick={() => handleCommunityTypeChange(community)}
                                    >
                                        <Group>
                                            <Paper
                                                shadow={'md'}
                                                withBorder
                                                radius={'md'}
                                                p={'xs'}
                                                bg={community.bg}
                                                style={{ border: `1px solid ${community.iconColor}` }}
                                            >
                                                <Icon
                                                    icon={community.icon}
                                                    size={28}
                                                    color={community.iconColor}
                                                />
                                            </Paper>
                                            <Text fw={'bold'}>{community.title}</Text>
                                        </Group>
                                    </Paper>
                                </React.Fragment>
                            ))}
                        </SimpleGrid>

                        {form?.errors?.communityType && (
                            <Text
                                color={'red'}
                                mb={'xl'}
                            >
                                {form.errors.communityType}
                            </Text>
                        )}

                        <div className="text-lg font-torbu-heading">What is your information?</div>

                        <Grid mb={'sm'}>
                            <Grid.Col span={6}>
                                <TextInput
                                    placeholder={'First Name'}
                                    size={'md'}
                                    icon={<Icon icon={'Signature'} />}
                                    {...form.getInputProps('firstName')}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <TextInput
                                    placeholder={'Last Name'}
                                    size={'md'}
                                    icon={<Icon icon={'Signature'} />}
                                    {...form.getInputProps('lastName')}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mb={'lg'}>
                            <Grid.Col span={6}>
                                <TextInput
                                    placeholder={'Email Address'}
                                    size={'md'}
                                    icon={<Icon icon={'At'} />}
                                    {...form.getInputProps('email')}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Popover
                                    opened={popoverOpened}
                                    position="bottom"
                                    width="target"
                                    transitionProps={{ transition: 'pop' }}
                                >
                                    <Popover.Target>
                                        <Box
                                            onFocusCapture={() => setPopoverOpened(true)}
                                            onBlurCapture={() => setPopoverOpened(false)}
                                        >
                                            <PasswordInput
                                                placeholder={'Password'}
                                                size={'md'}
                                                icon={<Icon icon={'Lock'} />}
                                                {...form.getInputProps('password')}
                                            />
                                        </Box>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <Progress
                                            color={color}
                                            value={passwordStrength}
                                            size={5}
                                            mb="xs"
                                        />
                                        <PasswordRequirement
                                            label="Includes at least 6 characters"
                                            meets={form?.values?.password.length > 5}
                                        />
                                        {passwordChecks}
                                    </Popover.Dropdown>
                                </Popover>
                            </Grid.Col>
                        </Grid>

                        <button
                            type="submit"
                            className="dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg border-2 border-torbu-peach bg-torbu-white px-5 py-2.5 text-center font-torbu-demi-bold text-sm text-torbu-green hover:bg-torbu-peach hover:text-torbu-white focus:outline-none focus:ring-4 focus:ring-torbu-peach dark:bg-torbu-peach"
                        >
                            Start for free now!
                        </button>

                        <div className="mt-12">
                            <a
                                href="#"
                                onClick={() => {
                                    setErrorMessage({})
                                    setPage('login')
                                }}
                                className="text-sm text-primary-600 dark:text-primary-500 hover:underline"
                            >
                                Back to Login
                            </a>
                        </div>
                    </form>
                )}
            </div>
        </>
    )
}

export default SignUp

import React from "react";

export const LoginMarketing = () => {
    return (
        <div className="text-center lg:text-left">
            <h1 className="mb-4 leading-none tracking-tight text-white font-torbu-bold text-7xl sm:text-8xl">We Power Teamwork.</h1>
            <p className="mb-4 text-xl font-bold text-primary-200 lg:mb-8">One platform to organize and manage people, communicate securely, share feedback, plus so much more.</p>
            <div className="flex items-center divide-x divide-primary-500">
                <div className="flex pr-3 -space-x-4 sm:pr-5">
                    <img
                        className="w-10 h-10 border-2 border-white rounded-full"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                        alt="bonnie avatar"
                    />
                    <img
                        className="w-10 h-10 border-2 border-white rounded-full"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                        alt="jese avatar"
                    />
                    <img
                        className="w-10 h-10 border-2 border-white rounded-full"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                        alt="roberta avatar"
                    />
                    <img
                        className="w-10 h-10 border-2 border-white rounded-full"
                        src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/thomas-lean.png"
                        alt="thomas avatar"
                    />
                </div>
                <a
                    href="#"
                    className="pl-3 text-white dark:text-white sm:pl-5"
                >
                    <span className="text-sm text-primary-200">
                        Over <span className="font-medium text-white">15.7k</span> Happy Customers
                    </span>
                </a>
            </div>
        </div>
    )
};

import { Box,Group,Paper,Text } from '@mantine/core'
import { isEmpty } from 'lodash'
import React,{ useState } from 'react'
import ButtonCreate from '../../Components/Buttons/ButtonCreate'
import Can from '../../Helpers/Can'
import StrideLoader from '../../Helpers/StrideLoader'
import useAccountUsers from '../../Hooks/Models/useAccountUsers'
import useUserDirectory from '../../Hooks/Models/useUserDirectory'
import UserDrawer from './UserDrawer'
import UsersTable from './UsersTable'

export const UserManager = ({ account }) => {
    const [userDrawer, setUserDrawer] = useState(false)
    const accountUsersModel = useAccountUsers({ first: 100, filter: { accountId: account?.id } })
    const userDirectoryModel = useUserDirectory()

    return (
        <>
            <StrideLoader
                loading={accountUsersModel.isLoading}
                label={'Loading users...'}
            >
                <Box>
                    <Group
                        position="apart"
                        mb={25}
                    >
                        <Box>
                            <Text
                                color={'#3b3b3b'}
                                fw={'bold'}
                            >
                                Users
                            </Text>
                            <Text
                                color={'#777777'}
                                fw={300}
                                size={'xs'}
                            >
                                Manage your team users.
                            </Text>
                        </Box>
                        <Can manage={'team'}>
                            <ButtonCreate data-intercom-target="Add User Button" onClick={() => setUserDrawer({ type: 'new' })}>Add User</ButtonCreate>
                        </Can>
                    </Group>

                    <Paper withBorder>
                        <UsersTable
                            account={account}
                            accountUsersModel={accountUsersModel}
                        />
                    </Paper>
                </Box>

                {!isEmpty(userDrawer) && (
                    <UserDrawer
                        account={account}
                        userDirectoryModel={userDirectoryModel}
                        accountUsersModel={accountUsersModel}
                        userDrawer={userDrawer}
                        setUserDrawer={setUserDrawer}
                    />
                )}
            </StrideLoader>
        </>
    )
}

export default UserManager

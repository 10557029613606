import { Box, Grid, ScrollArea } from '@mantine/core'
import React from 'react'

export const ApplicationTwoColumns = ({ leftColumn, rightColumn, noRightPadding = false }) => {
    const rightPadding = noRightPadding ? '' : { p: 'md' }
    return (
        <>
            <div className="h-full lg:flex">
                <div className="overflow-scroll w-[290px] "><Box p={'md'}>{leftColumn}</Box></div>
                <div className="flex-1 w-full bg-white"><Box {...rightPadding}>{rightColumn}</Box></div>
            </div>
        </>
    )
}

export default ApplicationTwoColumns

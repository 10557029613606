import React, {useEffect, useState} from 'react'
import {Box, Grid, Select, Text, Tooltip} from '@mantine/core'
import Icon from "../../Helpers/Icon"
import {isNull, filter} from 'lodash'

const columnOptions = [
    {
        label: 'First Name',
        value: 'firstName'
    },
    {
        label: 'Last Name',
        value: 'lastName'
    },
    {
        label: 'Email Address',
        value: 'email'
    },
    {
        label: 'Phone Number',
        value: 'phone'
    },
    {
        label: 'Ignore Column',
        value: 'ignore'
    },
]

export const CSVColumn = ({column, index, columns, setColumns}) => {
    const [columnValue, setColumnValue] = useState('')

    useEffect(() => {
        setColumnValue(columns[index].columnMapped)
    }, [columns])

    const handleColumnMatch = (value) => {
        setColumnValue(value)

        if(value !== 'ignore'){
            const isMappedColumnUsed = filter(columns, col => col.columnMapped === value)
            if(isMappedColumnUsed.length){
                setColumns(oldColumns => ({
                    ...oldColumns,
                    [isMappedColumnUsed[0].columnIndex]: {
                        columnIndex: isMappedColumnUsed[0].columnIndex,
                        columnMapped: 'ignore'
                    }
                }))
            }
        }

        setColumns(oldColumns => ({
            ...oldColumns,
            [index]: {
                columnIndex: index,
                columnMapped: value
            }
        }))
    }

    return (
        <>
            <Box p={'md'} my={8} style={{borderBottom: '1px solid #b2b2b2'}} >
                <Grid>
                    <Grid.Col span={1}>
                        <Text size={'sm'} ml={6}>
                            {isNull(column.matchedOnDetector) ? <Icon icon={'X'} color={'red'}/> : <Icon icon={'Check'} color={'green'}/> }
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <Tooltip label={column.headerColumnValue} withinPortal position={'bottom'} withArrow>
                            <Text size={'sm'} lineClamp={1}>
                                {column.headerColumnValue}
                            </Text>
                        </Tooltip>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Tooltip label={column.columnPreview} withinPortal position={'bottom'} withArrow>
                            <Text size={'sm'} lineClamp={1} color={'dimmed'}>
                                {column.columnPreview}
                            </Text>
                        </Tooltip>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Select
                            value={columnValue}
                            dropdownPosition={'bottom'}
                            onChange={(value) => handleColumnMatch(value) }
                            data={columnOptions}
                            withinPortal
                        />
                    </Grid.Col>
                </Grid>
            </Box>
        </>
    )
}

export default CSVColumn

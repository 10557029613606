import {useEffect, useState} from "react"
import {isUndefined} from "lodash"
import usePermission from "../Hooks/usePermission"

const Can = ({create, view, manage, i, not, children, accountId}) => {
    const {hasPermissionAnywhere, hasPermissionInAccount} = usePermission()
    const [allowed, setAllowed] = useState(false)

    const validatePermission = (permission) => {
        if (accountId != null) {
            setAllowed(hasPermissionInAccount(permission, accountId))
        } else {
            setAllowed(hasPermissionAnywhere(permission))
        }
    }

    useEffect(() => {
        if(!isUndefined(i)){
            validatePermission(i)
        }
    }, [i])

    useEffect(() => {
        if(!isUndefined(create)){
            validatePermission(`${create}:create:v2`)
        }
    }, [create])

    useEffect(() => {
        if (!isUndefined(view)) {
            validatePermission(`${view}:view${view === "event" ? ":v2" : ""}`);
        }
    }, [view]);

    useEffect(() => {
        if (!isUndefined(manage)) {
            validatePermission(`${manage}:manage`)
        }
    }, [manage])

    if(
        (isUndefined(not) && allowed === true) ||
        (!isUndefined(not) && allowed === false)
    ){
        return children
    }

    return null
}
export default Can

import React, {forwardRef} from 'react'
import {
    Group,
    Text,
} from '@mantine/core'
import StrideAvatar from "./StrideAvatar"

export const AccountsWithAvatars = forwardRef(
    ({ account, label, ...others }, ref) => {

        return (
            <div ref={ref} {...others}>
                <Group noWrap >
                    <StrideAvatar account={account} />

                    <div >
                        <Text size="md" fw={300} >{label}</Text >
                    </div >
                </Group >
            </div >
        )
    }
)

export default AccountsWithAvatars
import React from "react"
import {Paper} from '@mantine/core'
import Can from "../../Helpers/Can"
import StrideHeader from '../../Helpers/StrideHeader'
import AgendaViewer from './AgendaViewer'

export const AgendaApplication = ({event}) => {

    return (
        <>
            <StrideHeader
                header={'Event Manager'}
                crumbs={[{
                    title: 'Agenda Planning',
                }]}
                icon={'Calendar'}
                withBorder
            />

            <Can view={'event'}>
                <Paper>
                    {event && (
                        <Paper withBorder shadow p={'sm'} bg={'#F6F7F8'}>
                            <AgendaViewer
                                event={event}
                                editable
                            />
                        </Paper>
                    )}
                </Paper>
            </Can>
        </>
    )
}

export default AgendaApplication
import { useEffect } from 'react'

const useScript = (src, onLoad, deps = []) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.onload = onLoad
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, deps)
}

export default useScript

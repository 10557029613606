import { Switch } from "@headlessui/react"
import React from "react"
import { twMerge } from "tailwind-merge"

const TorbuSwitch = ({
    checked,
    onChange,
    label
}) => {
    return (
        <Switch.Group as={'div'} className={'flex items-center'}>
            <Switch
                checked={checked}
                onChange={checked => {
                    onChange?.(checked)
                }}
                className={twMerge(
                    checked ? 'bg-torbu-green' : 'bg-torbu-peach',
                    'relative inline-flex h-6 w-11 items-center rounded-full'
                )}
            >
                <span
                    className={twMerge(
                        checked ? 'translate-x-6' : 'translate-x-1',
                        'inline-block h-4 w-4 transform rounded-full bg-torbu-white transition'
                    )}
                />
            </Switch>
            {label && (
                <Switch.Label as="span" className="ml-3 text-sm font-torbu-medium">
                    <span>{label}</span>
                </Switch.Label>
            )}
        </Switch.Group>
    )
}

export default TorbuSwitch

import { find } from 'lodash'
import React,{ useEffect,useState } from 'react'
import StrideDrawer from '../..//Helpers/StrideDrawer'
import ChannelAdd from './Drawers/ChannelAdd'
import ChannelEdit from './Drawers/ChannelEdit'

export const ChatDrawerManager = ({drawer, setDrawer}) => {
    const [renderedDrawer, setRenderedDrawer] = useState({})

    const drawersAvailable = [
        {
            type: 'channelAdd',
            title: 'Create a channel',
            size: '500px',
            content: (
                <ChannelAdd
                    setDrawer={setDrawer}
                    drawer={drawer}
                    {...drawer.props}
                />
            )
        },
        {
            type: 'channelEdit',
            title: 'Edit a channel',
            size: '500px',
            content: (
                <ChannelEdit
                    setDrawer={setDrawer}
                    drawer={drawer}
                    {...drawer.props}
                />
            )
        }
    ]

    useEffect(() => {
        const drawerFound = find(drawersAvailable, ['type', drawer.type])
        if(drawerFound){
            setRenderedDrawer(drawerFound)
        }else{
            setRenderedDrawer({})
        }
    }, [drawer])

    const toggleDrawer = () => {
        setDrawer(!Object.keys(drawer).length)
    }

    return (
        <>
            {(renderedDrawer && renderedDrawer.content) && (
                <StrideDrawer
                    opened={Object.keys(drawer).length}
                    onClose={toggleDrawer}
                    title={renderedDrawer.title}
                    padding="md"
                    position="right"
                    size={renderedDrawer.size}
                >
                    {renderedDrawer.content}
                </StrideDrawer>
            )}
        </>
    )
}
export default ChatDrawerManager
